import { flow, getParent, Instance, types } from 'mobx-state-tree'
import { CatchmentsResourceManagerInstance } from 'store/resource-managers/catchments'
import { InterventionBudgetCSVData } from 'types/csv'
import { CatchmentFromApi } from 'types/db/catchment'
import { CIDSSFileDocument } from 'types/db/file'
import {
  PartialScenarioFromApi,
  ScenarioFromApi,
  ScenarioSettingsDocument,
} from 'types/db/scenario'
import { LoadState } from 'types/loadstate'
import { endpoints, get, newJob, patch, post, stopJob } from 'utils/api'
import {
  CreateResultsInterventions,
  CreateResultsThreatRisks,
  CreateCatchmentFileModels,
  CreateCatchmentResultModels,
  CreateFileModels,
} from 'utils/funcs'
import { ScenarioStatus } from 'utils/status'
import { v4 } from 'uuid'
import {
  DefaultMaxSteps,
  DefaultRunMode,
  DefaultSMaxSteps,
  DefaultTMax,
  DefaultTMin,
  ScenarioRunMode,
} from 'values/defaults'
import { CatchmentModel, CatchmentModelInstance } from './catchment'
import { FileModel, FileModelInstance } from './file'
import { InterventionModel } from './intervention'
import { ResultsModel } from './results'
import { ScenarioAttenuatedThreatsModel } from './scenario-attenuated-threats'
import { ScenarioRiskWeightsModel } from './scenario-risk-weights'
import { SpatialModel } from './spatial'
import { SpatialFeatureModel } from './spatial-feature'
import { SpatialFeatureGeometryModel } from './spatial-feature-geometry'
import { ScenarioFlatTableProps } from 'types/table'
import {
  CIDSSCatchmentFileType,
  CIDSSScenarioFileTypeExtended,
} from 'values/files'
import { env } from 'env'
import { PascalToSnakeCase } from 'types/common'
import { Job } from 'types/api/computation/job'
import { UsersResourceManagerInstance } from 'store/resource-managers/users'
import * as Sentry from '@sentry/react'

const ScenarioModel = types
  .model('ScenarioModel', {
    id: types.identifier,
    createdAt: types.string,
    updatedAt: types.string,
    isDeleted: types.boolean,
    name: types.string,
    description: types.string,
    budget: types.number,
    catchments: types.array(types.reference(CatchmentModel)),
    interventions: types.array(InterventionModel),
    attenuationProps: types.maybeNull(FileModel),
    efficacy: types.maybeNull(FileModel),
    interventionBudget: types.maybeNull(FileModel),
    progress: types.number,
    progressIssues: types.maybeNull(types.string),
    spatial: types.maybeNull(FileModel),
    attempts: types.array(types.string),
    // puInterventionResults: types.null,
    // puInterventionCosts: types.null,
    beforePuAttenuatedThreats: types.maybeNull(FileModel),
    afterPuAttenuatedThreats: types.maybeNull(FileModel),
    // beforePuThreatRisks: types.null,
    // afterPuThreatRisks: types.null,
    scenarioResults: types.maybeNull(ResultsModel),
    status: types.string,
    job: types.array(types.string),
    isFeatured: types.boolean,
    maxSteps: types.maybeNull(types.number),
    sMaxSteps: types.maybeNull(types.number),
    tMax: types.maybeNull(types.number),
    tMin: types.maybeNull(types.number),
    costMatrix: types.maybeNull(FileModel),
    interventionMatrix: types.maybeNull(FileModel),
    cost: types.maybeNull(types.number),
    timeLeft: types.maybeNull(types.number),
    allowedCatchments: types.number,
    interventionChanges: types.array(types.null),
    isEdited: types.maybeNull(types.boolean),
    visibility: types.string,
    runMode: types.maybeNull(types.number),
    riskWeights: types.maybeNull(types.string),
    riskWeightsRaw: types.maybeNull(ScenarioRiskWeightsModel), // don't use this...
    riskWeightsResult: types.maybeNull(ScenarioRiskWeightsModel),
    attenuatedThreats: types.map(ScenarioAttenuatedThreatsModel),
    annotations: types.maybeNull(types.string),
    annotationReason: types.maybeNull(types.string),
    // spatial data
    catchmentSpatial: types.map(SpatialModel),
  })
  .volatile(() => ({
    loadStateVolatile: LoadState.init,
    spatialLoadStateVolatile: LoadState.init,
  }))
  .views((self) => ({
    get scenarioRunStatus(): ScenarioStatus {
      switch (self.status.toLowerCase()) {
        case 'refined':
          return ScenarioStatus.REFINED

        case 'completed':
          return ScenarioStatus.COMPLETED

        case 'finalising':
          return ScenarioStatus.FINALISING

        case 'running':
          return ScenarioStatus.RUNNING

        case 'ready':
          return ScenarioStatus.READY

        case 'stopped':
          return ScenarioStatus.STOPPED

        case 'error':
          return ScenarioStatus.ERROR

        case 'stopping':
          return ScenarioStatus.STOPPING

        default:
          return ScenarioStatus.INIT
      }
    },
    get isFilesValid(): boolean {
      // check all the files we have on the scenario
      const files = [
        self.attenuationProps,
        self.efficacy,
        self.interventionBudget,
      ]
      const areScenarioFilesInValid = files
        .map((file) => file?.isValid ?? false)
        .some((isValid) => isValid === false) // looking for invalid files

      // and the catchments
      // if the catchments aren't available, then we default to false
      // because we cannot infer the scenario is ready to run with this
      // property to ensure files are infact valid
      // we also want to exclude catchments that aren't enabled either
      const catchmentFilesValidity = self.catchments
        .filter((catchment) => catchment.enabled ?? false)
        .map((catchment) => catchment.isFilesValid)
        .some((isValid) => isValid === false) // looking for invalid files

      const areCatchmentFilesInvalid =
        self.catchments.length === 0 ? true : catchmentFilesValidity
      return !areScenarioFilesInValid || !areCatchmentFilesInvalid
    },
    get serialisedFlat(): ScenarioFromApi {
      const attenuatedThreats = Array.from(
        self.attenuatedThreats.values()
      ).reduce((obj, threat) => ({ ...obj, ...threat.serialisedFlat }), {})

      const allowed_catchments = self.catchments
        .filter(({ enabled }) => enabled)
        .reduce((total) => total + 1, 0)

      return {
        _id: self.id,
        createdAt: self.createdAt,
        updatedAt: self.updatedAt,
        deleted: self.isDeleted,
        name: self.name,
        description: self.description,
        budget: self.budget,
        users: [],
        catchments: self.catchments.map(
          (catchment) => catchment.serialisedFlat._id
        ),
        interventions: self.interventions.map(
          (int) => int.serialisedFlatForScenario
        ),
        attenuation_props: self.attenuationProps?.serialisedFlat ?? null,
        efficacy: self.efficacy?.serialisedFlat ?? null,
        intervention_budget: self.interventionBudget?.serialisedFlat ?? null,
        progress: self.progress,
        progress_issues:
          self.progressIssues !== null
            ? {
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                // deleted: false,
                code: '',
                message: self.progressIssues,
              }
            : null,
        spatial: self.spatial?.serialisedFlat ?? null,
        attempts: self.attempts.map((attempt) => attempt),
        before_pu_attenuated_threats:
          self.beforePuAttenuatedThreats?.serialisedFlat ?? null,
        after_pu_attenuated_threats:
          self.afterPuAttenuatedThreats?.serialisedFlat ?? null,
        scenario_results:
          self.scenarioResults !== null
            ? self.scenarioResults.serialisedFlat
            : null,
        status: self.status,
        featured: self.isFeatured,
        max_steps: self.maxSteps ?? 0,
        s_max_steps: self.sMaxSteps ?? 0,
        tmax: self.tMax ?? 0,
        tmin: self.tMin ?? 0,
        cost_matrix: self.costMatrix?.serialisedFlat ?? null,
        intervention_matrix: self.interventionMatrix?.serialisedFlat ?? null,
        cost: self.cost ?? 0,
        time_left: self.timeLeft ?? 0,
        files_valid: true,
        allowed_catchments,
        intervention_changes: [],
        edited: self.isEdited ?? false,
        visibility: self.visibility,
        run_mode: self.runMode ?? 0,
        risk_weights: self.riskWeights,
        risk_weights_raw: self.riskWeightsRaw?.serialisedFlat ?? null,
        risk_weights_result: self.riskWeightsResult?.serialisedFlat ?? null,
        attenuated_threats: attenuatedThreats,
        annotations: self.annotations ?? '',
        annotation_reason: self.annotationReason ?? '',
      }
    },
    get runIterationCount() {
      if (self.progress === 0) {
        return 0
      }

      // the progress is between 0 and 1
      // but uses 3 decimal places
      // and each iteration it sends a webhook to update the timeLeft/progress
      // so that means the progress is directly related to the current iteration
      // count of the scenario run, so we can multiply that number by 1000
      // and get the current iteration count
      return self.progress * 1000
    },
  }))
  .views((self) => ({
    get hasResults(): boolean {
      const statuses = [
        ScenarioStatus.COMPLETED,
        ScenarioStatus.REFINED,
        ScenarioStatus.STOPPED,
      ]
      return (
        statuses.includes(self.scenarioRunStatus) &&
        self.scenarioResults !== null
      )
    },
  }))
  .views((self) => ({
    get serialisedFlatForTable(): ScenarioFlatTableProps {
      return {
        id: self.id,
        name: self.name,
        description: self.description,
        updatedAt: self.updatedAt,
        budget: self.budget,
        allowedCatchments: self.allowedCatchments,
        resultBeforeOverallRisk: self.scenarioResults?.beforeOverallRisk,
        resultAfterOverallRisk: self.scenarioResults?.afterOverallRisk,
        scenarioRunStatus: self.scenarioRunStatus,
        isFeatured: self.isFeatured,
        visibility: self.visibility,
        hasResults: self.hasResults,
      }
    },
  }))
  .actions((self) => ({
    draftNewScenario(payload: ScenarioFromApi): ScenarioFromApi {
      return {
        ...self.serialisedFlat,
        updatedAt: new Date().toISOString(),
        deleted: payload.deleted,
        name: payload.name,
        description: payload.description,
        budget: payload.budget,
        interventions: payload.interventions,
        attenuation_props: payload.attenuation_props,
        efficacy: payload.efficacy,
        intervention_budget: payload.intervention_budget,
        featured: payload.featured,
        max_steps: payload.max_steps,
        s_max_steps: payload.s_max_steps,
        tmax: payload.tmax,
        tmin: payload.tmin,
        edited: payload.edited,
        visibility: payload.visibility,
        run_mode: payload.run_mode,
        risk_weights: payload.risk_weights,
        risk_weights_raw: payload.risk_weights_raw,
        risk_weights_result: payload.risk_weights_result,
        annotations: payload.annotations,
        annotation_reason: payload.annotation_reason,
      }
    },
  }))
  .actions((self) => ({
    saveNewRiskWeightsFile(file: string) {
      self.riskWeights = file
    },
    setSettings(settings: ScenarioSettingsDocument) {
      self.tMax = settings.tmax
      self.tMin = settings.tmin
      self.sMaxSteps = settings.s_max_steps
      self.maxSteps = settings.max_steps
      self.runMode = settings.run_mode ?? ScenarioRunMode.Risk
    },
    toggleFeatured(state?: boolean) {
      self.isFeatured = state ?? !self.isFeatured
    },
    setStatus(status: ScenarioStatus) {
      self.status = status
    },
    setDeleted(state: boolean) {
      self.isDeleted = state
    },
    setFile(
      file: FileModelInstance | null,
      type?: CIDSSScenarioFileTypeExtended
    ) {
      switch ((file?.type as CIDSSScenarioFileTypeExtended) ?? type) {
        case 'AttenuationProps':
          self.attenuationProps = file
          break

        case 'Efficacy':
          self.efficacy = file
          break

        case 'InterventionBudget':
          self.interventionBudget = file
          break

        case 'Spatial':
          self.spatial = file
          break

        case 'BeforePuAttenuatedThreats':
          self.beforePuAttenuatedThreats = file
          break

        case 'AfterPuAttenuatedThreats':
          self.afterPuAttenuatedThreats = file
          break

        case 'CostMatrix':
          self.costMatrix = file
          break

        case 'InterventionMatrix':
          self.interventionMatrix = file
          break
      }
    },
    clearProgressIssues() {
      self.progressIssues = null
    },
    resetScenarioRunProgress() {
      self.progress = 0
    },
    setAnnotations(annotation: string, reason: string) {
      self.annotations = annotation
      self.annotationReason = reason
    },
  }))
  .actions((self) => ({
    setNewInterventions: (ints: InterventionBudgetCSVData[]) => {
      self.interventions.clear()

      // iterate over our ints arg and create new models
      ints
        .filter((int) => int.Valid === 1)
        .forEach((int) => {
          const programs = int.programs
            .split(',')
            .map((program) => program.trim())

          self.interventions.push(
            InterventionModel.create({
              id: int.fieldName,
              name: int.name.trim(),
              description: int.description.trim(),
              group: int.group,
              allow: int.Valid === 1,
              programs,
              unit: int.unit.trim(),
              cost: int.capitalCostPerTotalPackage$,
              fieldName: int.fieldName,
            })
          )
        })
    },
    reconcilePartialSelf: (scenario: PartialScenarioFromApi) => {
      // check if the scenario passed matches this model
      if (scenario._id !== self.id) {
        // no match, exit
        const err = new Error(
          `tried to reconcile partial scenario but ids did not match ${scenario._id} !== ${self.id}`
        )
        console.error(err)
        Sentry.captureException(err)
        return
      }

      if (
        scenario.scenario_results &&
        scenario.scenario_results.createdAt !== self.scenarioResults?.createdAt
      ) {
        self.scenarioResults = ResultsModel.create({
          id: scenario.scenario_results._id,
          createdAt: scenario.scenario_results.createdAt,
          beforeOverallRisk: scenario.scenario_results.before_overall_risk,
          afterOverallRisk: scenario.scenario_results.after_overall_risk,
          minRisk: null,
          maxRisk: null,
          beforeThreatRisks: [],
          afterThreatRisks: [],
          interventions: [],
        })
      }

      self.createdAt = scenario.createdAt
      self.updatedAt = scenario.updatedAt
      self.name = scenario.name
      self.description = scenario.description
      self.budget = scenario.budget
      self.status = scenario.status
      self.isFeatured = scenario.featured
      self.allowedCatchments = scenario.allowed_catchments
      self.visibility = scenario.visibility
    },
    reconcileSelf: (scenario: ScenarioFromApi) => {
      // check if the scenario passed matches this model
      if (scenario._id !== self.id) {
        // no match, exit
        const err = new Error(
          `tried to reconcile scenario but ids did not match ${scenario._id} !== ${self.id}`
        )
        console.error(err)
        Sentry.captureException(err)
        return
      }

      // update scenario results if applicable
      if (
        scenario.scenario_results &&
        (scenario.scenario_results.createdAt !==
          self.scenarioResults?.createdAt ||
          self.scenarioResults === null)
      ) {
        // go and reconcile the results object if we have one
        if (self.scenarioResults !== null) {
          self.scenarioResults.reconcileSelf(scenario.scenario_results)
        } else {
          // otherwise create a new one
          const results = ResultsModel.create({
            id: scenario.scenario_results._id,
            createdAt: scenario.scenario_results.createdAt,
            beforeOverallRisk: scenario.scenario_results.before_overall_risk,
            afterOverallRisk: scenario.scenario_results.after_overall_risk,
            maxRisk: scenario.scenario_results.max_risk,
            minRisk: scenario.scenario_results.min_risk,
            beforeThreatRisks: CreateResultsThreatRisks(
              scenario.scenario_results.before_threat_risks
            ),
            afterThreatRisks: CreateResultsThreatRisks(
              scenario.scenario_results.after_threat_risks
            ),
            interventions: CreateResultsInterventions(
              scenario.scenario_results.interventions
            ),
          })
          self.scenarioResults = results
        }
      }

      // replace interventions if applicable
      if (scenario.interventions.length > 0) {
        self.interventions.clear()
        scenario.interventions.forEach((int) => {
          self.interventions.push(
            InterventionModel.create({
              id: int.int_id,
              name: int.int_name,
              description: int.int_description,
              group: int.int_group,
              allow: int.allow,
              programs: int.int_programs,
              unit: int.int_unit,
              cost: Number(int.int_cost),
              fieldName: int.int_field_name,
            })
          )
        })
      }

      // reconcile catchment(s)
      const newCatchmentsIfAny = scenario.catchments
        .map((catchment) => {
          if (typeof catchment === 'string') {
            return null
          }

          // find the matching catchment by id
          const sourceCatchment = self.catchments.find(
            ({ id }) => id === catchment._id
          )
          if (sourceCatchment === undefined) {
            // this such case will happen for a scenario that has been reset
            // new catchments are made rather than modifying existing ones
            // with our new catchment, we need to insert it into the store
            const files = CreateCatchmentFileModels(catchment)
            const { pu: puResults, catchment: catchmentResults } =
              CreateCatchmentResultModels(catchment)

            return CatchmentModel.create({
              id: catchment._id,
              createdAt: catchment.createdAt,
              updatedAt: catchment.updatedAt,
              name: catchment.catchment_name,
              description: catchment.catchment_description,
              spatial: files.spatial,
              pu: files.pu,
              stormWater: files.stormWater,
              agriculture: files.agriculture,
              industry: files.industry,
              stp: files.stp,
              risk: files.risk,
              interventionLimits: files.interventionLimits,
              interventionGroupLimits: files.interventionGroupLimits,
              onSite: files.onSite,
              rawThreat: files.rawThreat,
              puProperties: files.puProperties,
              recreation: files.recreation,
              puNumbers: catchment.pu_numbers,
              puResults,
              enabled: catchment.allow,
              catchmentResults,
            })
          }

          sourceCatchment.reconcileSelf(catchment)
          return null
        })
        .filter((i) => i !== null)

      // if we have new catchments from our reconcile, we need to create them
      // in the store and establish a link between this scenario and the catchments
      if (newCatchmentsIfAny.length > 0) {
        const catchmentIds = (scenario.catchments as CatchmentFromApi[]).map(
          (catchment) => catchment._id
        )

        // get the catchments resource manager from self
        const CatchmentsResourceManager = getParent(
          self,
          2
        ) as unknown as CatchmentsResourceManagerInstance
        CatchmentsResourceManager.addCatchments(
          newCatchmentsIfAny as CatchmentModelInstance[]
        )
        self.catchments.clear()
        self.catchments.push(...catchmentIds)
      }

      // update basic typed fields
      self.updatedAt = scenario.updatedAt
      self.isDeleted = scenario.deleted
      self.name = scenario.name
      self.description = scenario.description
      self.budget = scenario.budget
      self.progress = scenario.progress
      self.status = scenario.status
      self.isFeatured = scenario.featured
      self.allowedCatchments = scenario.allowed_catchments
      self.visibility = scenario.visibility
      self.progress = scenario.progress
      self.progressIssues = scenario.progress_issues?.message ?? null
      self.timeLeft = scenario.time_left
      self.annotations = scenario.annotations ?? null
      self.annotationReason = scenario.annotation_reason ?? null

      self.job.clear()
      self.job.push(...(scenario.job ?? []))

      // clear and re-add the attenuated threats
      self.attenuatedThreats.clear()
      Object.entries(scenario.attenuated_threats ?? {}).forEach(
        ([catchmentId, fileIds]: [string, { before: string; after: string }]) =>
          self.attenuatedThreats.put({ id: catchmentId, ...fileIds })
      )

      // reconcile the files
      // we can't just directly reconcile the object
      // because each file has a unique id
      // and new files have a different id
      // and we can't directly change identifiers...
      const files = [
        [scenario.attenuation_props, self.attenuationProps],
        [scenario.efficacy, self.efficacy],
        [scenario.intervention_budget, self.interventionBudget],
        [scenario.spatial, self.spatial],
        [scenario.before_pu_attenuated_threats, self.beforePuAttenuatedThreats],
        [scenario.after_pu_attenuated_threats, self.afterPuAttenuatedThreats],
        [scenario.cost_matrix, self.costMatrix],
        [scenario.intervention_matrix, self.interventionMatrix],
      ]
      files.forEach(([_file, _model]) => {
        const file = _file as CIDSSFileDocument | null
        const model = _model as FileModelInstance | null

        if (file && model) {
          const incFileCreatedAtUnixEpoch = new Date(file.created_at).getTime()
          const existFileCreatedAtUnixEpoch = new Date(
            model.createdAt
          ).getTime()

          if (incFileCreatedAtUnixEpoch < existFileCreatedAtUnixEpoch) {
            return
          }

          if (file.truii_file_id !== model.truiiFileId) {
            // different files, we need to recreate it
            self.setFile(
              FileModel.create({
                truiiFileId: file.truii_file_id,
                fileName: file.file_name,
                fileType: file.file_type,
                type: file.type,
                isValid: file.valid,
                isProcessing: file.processing,
                issue: file.issue,
                createdAt: file.created_at,
              })
            )
            return
          }

          model.reconcileSelf(file)
          return
        }

        // create a new model for the file
        if (file && !model) {
          self.setFile(
            FileModel.create({
              truiiFileId: file.truii_file_id,
              fileName: file.file_name,
              fileType: file.file_type,
              type: file.type,
              isValid: file.valid,
              isProcessing: file.processing,
              issue: file.issue,
              createdAt: file.created_at,
            })
          )
          return
        }

        // remove the model if there's no file
        if (!file && model) {
          self.setFile(null, model.type as CIDSSScenarioFileTypeExtended)
          return
        }
      })

      // if (
      //   scenario.intervention_budget !== null &&
      //   scenario.intervention_budget.truii_file_id !==
      //     self.interventionBudget?.truiiFileId
      // ) {
      //   self.interventionBudget = FileModel.create({
      //     truiiFileId: scenario.intervention_budget.truii_file_id,
      //     fileName: scenario.intervention_budget.file_name,
      //     fileType: scenario.intervention_budget.file_type,
      //     type: scenario.intervention_budget.type,
      //     isValid: scenario.intervention_budget.valid,
      //     isProcessing: scenario.intervention_budget.processing,
      //     issue: scenario.intervention_budget.issue,
      //     createdAt: scenario.intervention_budget.created_at,
      //   })
      // }

      // if (
      //   scenario.efficacy !== null &&
      //   scenario.efficacy.truii_file_id !== self.efficacy?.truiiFileId
      // ) {
      //   self.efficacy = FileModel.create({
      //     truiiFileId: scenario.efficacy.truii_file_id,
      //     fileName: scenario.efficacy.file_name,
      //     fileType: scenario.efficacy.file_type,
      //     type: scenario.efficacy.type,
      //     isValid: scenario.efficacy.valid,
      //     isProcessing: scenario.efficacy.processing,
      //     issue: scenario.efficacy.issue,
      //     createdAt: scenario.efficacy.created_at,
      //   })
      // }

      // if (
      //   scenario.attenuation_props !== null &&
      //   scenario.attenuation_props.truii_file_id !==
      //     self.attenuationProps?.truiiFileId
      // ) {
      //   self.efficacy = FileModel.create({
      //     truiiFileId: scenario.attenuation_props.truii_file_id,
      //     fileName: scenario.attenuation_props.file_name,
      //     fileType: scenario.attenuation_props.file_type,
      //     type: scenario.attenuation_props.type,
      //     isValid: scenario.attenuation_props.valid,
      //     isProcessing: scenario.attenuation_props.processing,
      //     issue: scenario.attenuation_props.issue,
      //     createdAt: scenario.attenuation_props.created_at,
      //   })
      // }
    },
    loadFull: flow(function* () {
      if ([LoadState.init, LoadState.error].includes(self.loadStateVolatile)) {
        self.loadStateVolatile = LoadState.inProgress

        // get the token for our authenticated user
        const UserManagerInstance = getParent(
          self,
          2
        ) as unknown as UsersResourceManagerInstance
        const headers: { authorization?: string } = {}
        if (UserManagerInstance.authenticatedUserIdToken) {
          headers.authorization = UserManagerInstance.authenticatedUserIdToken
        }

        try {
          const endpoint = endpoints.scenarioOne(self.id)
          const docs: ScenarioFromApi[] = yield get<ScenarioFromApi[]>(
            endpoint,
            headers
          )
          const doc = docs?.[0]

          // reconcile existing catchments
          // and create those that don't exist
          const catchmentsToCreate = (
            doc.catchments as CatchmentFromApi[]
          ).filter((catchment) => {
            // try reconciling existing catchments
            const exists = self.catchments.find(
              ({ id }) => id === catchment._id
            )
            if (exists) {
              exists.reconcileSelf(catchment)
              return false
            }

            return true
          })

          // create the catchments from this data
          const catchments = catchmentsToCreate.map((catchment) => {
            const files = CreateCatchmentFileModels(catchment)
            const { pu: puResults, catchment: catchmentResults } =
              CreateCatchmentResultModels(catchment)

            return CatchmentModel.create({
              id: catchment._id,
              createdAt: catchment.createdAt,
              updatedAt: catchment.updatedAt,
              name: catchment.catchment_name,
              description: catchment.catchment_description,
              spatial: files.spatial,
              pu: files.pu,
              stormWater: files.stormWater,
              agriculture: files.agriculture,
              industry: files.industry,
              stp: files.stp,
              risk: files.risk,
              interventionLimits: files.interventionLimits,
              interventionGroupLimits: files.interventionGroupLimits,
              onSite: files.onSite,
              rawThreat: files.rawThreat,
              puProperties: files.puProperties,
              recreation: files.recreation,
              puNumbers: catchment.pu_numbers,
              puResults,
              enabled: catchment.allow,
              catchmentResults,
            })
          })
          const catchmentIds = catchmentsToCreate.map(
            (catchment) => catchment._id
          )

          // get the catchments resource manager from self
          const CatchmentsResourceManager = getParent(
            self,
            2
          ) as unknown as CatchmentsResourceManagerInstance
          CatchmentsResourceManager.addCatchments(catchments)
          self.catchments.push(...catchmentIds)

          // create the interventions from this data
          doc.interventions.forEach((int) =>
            self.interventions.push(
              InterventionModel.create({
                id: int.int_id,
                name: int.int_name,
                description: int.int_description,
                group: int.int_group,
                allow: int.allow,
                programs: int.int_programs,
                unit: int.int_unit,
                cost: Number(int.int_cost),
                fieldName: int.int_field_name,
              })
            )
          )

          // create the files from this data
          const files = CreateFileModels([
            ['attenuationProps', doc.attenuation_props],
            ['efficacy', doc.efficacy],
            ['interventionBudget', doc.intervention_budget],
            ['spatial', doc.spatial],
            ['beforePuAttenuatedThreats', doc.before_pu_attenuated_threats],
            ['afterPuAttenuatedThreats', doc.after_pu_attenuated_threats],
            ['costMatrix', doc.cost_matrix],
            ['interventionMatrix', doc.intervention_matrix],
          ] as [string, CIDSSFileDocument | null][])

          Object.entries(doc.attenuated_threats ?? {}).forEach(
            ([catchmentId, fileIds]: [
              string,
              { before: string; after: string }
            ]) => self.attenuatedThreats.put({ id: catchmentId, ...fileIds })
          )

          self.attenuationProps = files.attenuationProps
          self.efficacy = files.efficacy
          self.interventionBudget = files.interventionBudget
          self.progress = doc.progress ?? 0
          self.progressIssues = doc.progress_issues?.message ?? null
          self.spatial = files.spatial
          self.beforePuAttenuatedThreats = files.beforePuAttenuatedThreats
          self.afterPuAttenuatedThreats = files.afterPuAttenuatedThreats
          self.maxSteps = doc.max_steps
          self.sMaxSteps = doc.s_max_steps
          self.tMax = doc.tmax
          self.tMin = doc.tmin
          self.costMatrix = files.costMatrix
          self.interventionMatrix = files.interventionMatrix
          self.cost = doc.cost
          self.timeLeft = doc.time_left
          self.isEdited = doc.edited
          self.runMode = doc.run_mode ?? null
          self.riskWeights = doc.risk_weights
          self.riskWeightsRaw =
            doc.risk_weights_raw !== null
              ? ScenarioRiskWeightsModel.create(doc.risk_weights_raw)
              : null
          self.riskWeightsResult =
            doc.risk_weights_result !== null
              ? ScenarioRiskWeightsModel.create(doc.risk_weights_result)
              : null
          self.annotations = doc.annotations ?? null
          self.annotationReason = doc.annotation_reason ?? null
          self.job.push(...(doc.job ?? []))

          // update the results since we have some null fields
          if (self.scenarioResults !== null) {
            self.scenarioResults.maxRisk =
              doc.scenario_results?.max_risk ?? null
            self.scenarioResults.minRisk =
              doc.scenario_results?.min_risk ?? null

            self.scenarioResults.beforeThreatRisks.push(
              ...CreateResultsThreatRisks(
                doc.scenario_results?.before_threat_risks ?? []
              )
            )

            self.scenarioResults.afterThreatRisks.push(
              ...CreateResultsThreatRisks(
                doc.scenario_results?.after_threat_risks ?? []
              )
            )

            self.scenarioResults?.interventions.push(
              ...CreateResultsInterventions(
                doc.scenario_results?.interventions ?? []
              )
            )
          }

          self.loadStateVolatile = LoadState.done
        } catch (err) {
          console.error('error loading full scenario', err)
          Sentry.captureException(err, {
            user: UserManagerInstance.meAsSentryUserContext,
          })

          self.loadStateVolatile = LoadState.error
        }
      }
    }),
  }))
  .actions((self) => ({
    loadFullWithSpatial: flow(function* () {
      self.spatialLoadStateVolatile = LoadState.inProgress

      try {
        // load the scenario full if able
        yield self.loadFull()

        // fetch the catchment spatial
        // the catchment must have results
        const catchmentsWithResults = self.catchments.filter(
          (catchment) => catchment.catchmentResults !== null
        )

        // for each catchment with results, fetch the spatial file
        const spatials: {
          id: string
          geojson: GeoJSON.FeatureCollection
          beforeOverallRisk: number
          afterOverallRisk: number
        }[] = yield Promise.all(
          catchmentsWithResults.map(async (catchment) => {
            if (catchment.spatial === null) {
              // throw because a catchment has results but no spatial data?
              throw new Error(
                'catchment has results but no spatial file, ' + catchment.id
              )
            }

            const text = (await catchment.spatial.download()) ?? '{}'
            return {
              geojson: JSON.parse(text) as GeoJSON.FeatureCollection,
              id: catchment.id,
              beforeOverallRisk: Math.floor(
                catchment.catchmentResults?.beforeOverallRisk ?? 0
              ),
              afterOverallRisk: Math.floor(
                catchment.catchmentResults?.afterOverallRisk ?? 0
              ),
            }
          })
        )

        spatials.forEach(({ id, geojson, ...risk }) => {
          const features = geojson.features.map((feature) => {
            return SpatialFeatureModel.create({
              id: (feature.id as string) ?? v4(),
              properties: {
                ...feature.properties,
                id,
                beforeOverallRisk: risk.beforeOverallRisk,
                afterOverallRisk: risk.afterOverallRisk,
              },
              type: feature.type,
              geometry: SpatialFeatureGeometryModel.create({
                type: feature.geometry.type,
                coordinates: JSON.stringify(
                  (feature.geometry as GeoJSON.Polygon).coordinates
                ),
              }),
            })
          })

          self.catchmentSpatial.set(
            id,
            SpatialModel.create({
              id,
              type: geojson.type,
              features,
            })
          )
        })

        self.spatialLoadStateVolatile = LoadState.done
      } catch (err) {
        console.error('error loading full scenario with spatial', err)
        Sentry.captureException(err)

        self.spatialLoadStateVolatile = LoadState.error
      }
    }),
  }))
  .actions((self) => {
    let loadPromise: Promise<ScenarioFromApi[] | undefined>

    return {
      refresh: flow(function* () {
        if (loadPromise) {
          yield loadPromise
        }

        if (self.loadStateVolatile === LoadState.done) {
          // get the token for our authenticated user
          const UserManagerInstance = getParent(
            self,
            2
          ) as unknown as UsersResourceManagerInstance
          const headers: { authorization?: string } = {}
          if (UserManagerInstance.authenticatedUserIdToken) {
            headers.authorization = UserManagerInstance.authenticatedUserIdToken
          }

          const endpoint = endpoints.scenarioOne(self.id)
          loadPromise = get<ScenarioFromApi[]>(endpoint, headers)
          const docs: ScenarioFromApi[] = yield loadPromise
          const doc = docs?.[0]

          self.reconcileSelf(doc)
        }
      }),
    }
  })
  .actions((self) => ({
    save: flow(function* (payload?: ScenarioFromApi) {
      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        2
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        // check if the scenario has been loaded yet
        // we can't save this scenario if it hasn't been loaded
        // otherwise our schema check will fail
        // as we're missing data...
        if (self.loadStateVolatile !== LoadState.done) {
          yield self.loadFull()
        }

        const requestBody = self.draftNewScenario(
          payload ?? self.serialisedFlat
        )
        const endpoint = endpoints.scenarioOne(self.id)
        yield patch(endpoint, requestBody, headers)

        // try reconciling self with our request body if nothing went wrong
        self.reconcileSelf(requestBody)
      } catch (err) {
        console.error('error saving scenario', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })
      }
    }),
    reset: flow(function* () {
      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        2
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        const endpoint = endpoints.scenarioOneReset(self.id)
        const scenarios: ScenarioFromApi[] = yield post<ScenarioFromApi[]>(
          endpoint,
          null,
          headers
        )

        // now we reconcile this scenario
        self.reconcileSelf(scenarios?.[0])
      } catch (err) {
        console.error('error resetting scenario', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })
      }
    }),
  }))
  .actions((self) => ({
    deleteResults() {
      self.scenarioResults = null
      self.status = ScenarioStatus.READY
      self.save()
    },
  }))
  .actions((self) => ({
    run: flow(function* () {
      // check if the files are valid
      if (
        !self.efficacy ||
        !self.efficacy.isValid ||
        !self.attenuationProps ||
        !self.attenuationProps.isValid ||
        !self.interventionBudget ||
        !self.interventionBudget.isValid ||
        !self.riskWeights
      ) {
        const err = new Error(
          'efficacy, attenuation props, intervention budget or risk weights does not exist or is invalid'
        )
        Sentry.captureException(err)

        throw err
      }

      const fileIdMapping: Record<string, string> = {
        [self.efficacy.truiiFileId]: self.efficacy.truiiFileId,
        [self.attenuationProps.truiiFileId]: self.attenuationProps.truiiFileId,
        [self.interventionBudget.truiiFileId]:
          self.interventionBudget.truiiFileId,
        [self.riskWeights]: self.riskWeights,
      }

      const catchments = self.catchments
        .filter(({ enabled }) => enabled)
        .map((catchment) => {
          const files = [
            catchment.spatial,
            catchment.agriculture,
            catchment.industry,
            catchment.interventionLimits,
            catchment.interventionGroupLimits,
            catchment.onSite,
            catchment.risk,
            catchment.rawThreat,
            catchment.stp,
            catchment.stormWater,
            catchment.pu,
            catchment.puProperties,
          ].filter((file) => file !== null) as FileModelInstance[] // filter out null files

          const fileTypesToIds = files.reduce((obj, file) => {
            fileIdMapping[file.truiiFileId] = file.truiiFileId
            return {
              ...obj,
              [file.type
                .replace(/(?<!^)([A-Z])/g, '_$1')
                .toLowerCase() as PascalToSnakeCase<CIDSSCatchmentFileType>]:
                file.truiiFileId,
            }
          }, {} as Record<PascalToSnakeCase<CIDSSCatchmentFileType>, string>)

          if (catchment.recreation) {
            // throw if the catchment recreation file is defined, but not valid
            if (!catchment.recreation.isValid) {
              throw new Error('catchment recreation file is not valid')
            }

            fileIdMapping[catchment.recreation.truiiFileId] =
              catchment.recreation.truiiFileId
            fileTypesToIds.recreation = catchment.recreation.truiiFileId
          } else {
            // use the default recreation file
            const fileName = env.REACT_APP_DEFAULT_RECREATION_FILE
            fileIdMapping[fileName] = fileName
            fileTypesToIds.recreation = fileName
          }

          const obj = {
            _id: catchment.id,
            // pu_numbers: catchment.puNumbers ?? 0,
            ...fileTypesToIds,
            on_site: undefined,
            onsite: catchment.onSite?.truiiFileId as string,
          }

          delete obj.on_site
          return obj
        })

      // create the post body needed for to run this scenario
      const id = v4()
      const payload: Job = {
        tasks: [
          {
            id,
            script: 'scenario_run',
            params: {
              webHook: {
                url:
                  (env.REACT_APP_API_HOST_OVERRIDE_FOR_DNS ??
                    env.REACT_APP_API_HOST +
                      env.REACT_APP_API_ENDPOINT_PREFIX) +
                  endpoints.scenarioOneWebhook(self.id),
                method: 'POST',
              },
              efficacy: self.efficacy.truiiFileId,
              attenuation_props: self.attenuationProps.truiiFileId,
              intervention_budget: self.interventionBudget.truiiFileId,
              _id: self.id,
              budget: self.budget,
              max_steps: self.maxSteps ?? DefaultMaxSteps,
              s_max_steps: self.sMaxSteps ?? DefaultSMaxSteps,
              tmax: self.tMax ?? DefaultTMax,
              tmin: self.tMin ?? DefaultTMin,
              run_mode: self.runMode ?? DefaultRunMode,
              risk_weights: self.riskWeights,
              interventions: self.interventions.map(
                ({ serialisedFlatForScenario }) => serialisedFlatForScenario
              ),
              catchments,
            },
            reply: false,
            fileParams: fileIdMapping,
          },
        ],
        deps: [
          {
            source: 'root',
            target: id,
          },
        ],
      }

      // submit the job to the api
      yield newJob(payload)

      // update the status of the scenario to init
      self.setStatus(ScenarioStatus.INIT)
      self.clearProgressIssues()
      self.progress = 0

      yield self.save()
    }),
    stop: flow(function* () {
      // do nothing if the job array isn't exactly 2 elements
      // the scenario isn't running if that's the case
      if (self.job.length !== 2) {
        return
      }

      // only allow this method to continue if the scenario status
      // is running as well
      if (self.scenarioRunStatus !== ScenarioStatus.RUNNING) {
        return
      }

      self.setStatus(ScenarioStatus.STOPPING)

      const [taskId, jobId] = self.job
      yield stopJob(jobId, taskId)
      yield self.save()
    }),
  }))

interface ScenarioModelInstance extends Instance<typeof ScenarioModel> {}

export { ScenarioModel }
export type { ScenarioModelInstance }
