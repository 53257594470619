import * as Sentry from '@sentry/react'
import { flow, types } from 'mobx-state-tree'
import { CatchmentModel } from 'store/models/catchment'
import { DefaultsModel } from 'store/models/defaults'
import { FileModel, FileModelInstance } from 'store/models/file'
import { InterventionModel } from 'store/models/intervention'
import { CatchmentFromApi } from 'types/db/catchment'
import { DefaultsFromApi } from 'types/db/defaults'
import { CIDSSFileDocument } from 'types/db/file'
import { LoadState } from 'types/loadstate'
import { endpoints, get } from 'utils/api'
import { CatchmentsResourceManagerInstance } from './catchments'
import { UsersResourceManagerInstance } from './users'

const DefaultsResourceManager = types
  .model('DefaultsResourceManager', {
    defaults: types.maybe(DefaultsModel),
  })
  .volatile(() => ({
    loadStateVolatile: LoadState.init,
  }))
  .actions((self) => ({
    loadDefaults: flow(function* () {
      if ([LoadState.init, LoadState.error].includes(self.loadStateVolatile)) {
        self.loadStateVolatile = LoadState.inProgress

        // get the token for our authenticated user
        const UserManagerInstance =
          self as unknown as UsersResourceManagerInstance
        const headers: { authorization?: string } = {}
        if (UserManagerInstance.authenticatedUserIdToken) {
          headers.authorization = UserManagerInstance.authenticatedUserIdToken
        }

        try {
          const docs: DefaultsFromApi[] = yield get<DefaultsFromApi[]>(
            endpoints.defaults(),
            headers
          )
          const doc = docs?.[0]

          // create catchments from this data
          const catchments = (doc.catchments as CatchmentFromApi[]).map(
            (catchment) => {
              const files: Record<string, FileModelInstance> = (
                [
                  ['spatial', catchment.spatial],
                  ['pu', catchment.pu],
                  ['stormWater', catchment.storm_water],
                  ['agriculture', catchment.agriculture],
                  ['industry', catchment.industry],
                  ['stp', catchment.stp],
                  ['risk', catchment.risk],
                  ['interventionLimits', catchment.intervention_limits],
                  [
                    'interventionGroupLimits',
                    catchment.intervention_group_limits,
                  ],
                  ['onSite', catchment.onsite],
                  // NOTE: the db has this field stored as "raw_theat"
                  // i don't know why, it just is...
                  ['rawThreat', catchment.raw_threat ?? catchment.raw_theat],
                  ['puProperties', catchment.pu_properties],
                  ['recreation', catchment.recreation],
                ] as [string, CIDSSFileDocument | null][]
              ).reduce(
                (obj, [key, fileDoc]) => ({
                  ...obj,
                  [key]:
                    fileDoc === null || fileDoc === undefined
                      ? null
                      : FileModel.create({
                          truiiFileId: fileDoc.truii_file_id,
                          fileName: fileDoc.file_name,
                          fileType: fileDoc.file_type,
                          // attributeFileId: fileDoc.attribute_file_id,
                          type: fileDoc.type,
                          isValid: fileDoc.valid,
                          isProcessing: fileDoc.processing,
                          issue: fileDoc.issue,
                          createdAt: fileDoc.created_at,
                        }),
                }),
                {}
              )

              // there's no results for these catchments
              // so we aren't going to bother doing anything with
              // pu_results or catchment_results

              return CatchmentModel.create({
                id: catchment._id,
                createdAt: catchment.createdAt,
                updatedAt: catchment.updatedAt,
                name: catchment.catchment_name,
                description: catchment.catchment_description,
                spatial: files.spatial,
                pu: files.pu,
                stormWater: files.stormWater,
                agriculture: files.agriculture,
                industry: files.industry,
                stp: files.stp,
                risk: files.risk,
                interventionLimits: files.interventionLimits,
                interventionGroupLimits: files.interventionGroupLimits,
                onSite: files.onSite,
                rawThreat: files.rawThreat,
                puProperties: files.puProperties,
                recreation: files.recreation,
                puNumbers: catchment.pu_numbers,
                puResults: [],
                enabled: catchment.allow,
                catchmentResults: null,
              })
            }
          )
          const catchmentIds = (doc.catchments as CatchmentFromApi[]).map(
            (catchment) => catchment._id
          )

          // get the catchments resource manager from self
          const CatchmentsResourceManager =
            self as unknown as CatchmentsResourceManagerInstance
          CatchmentsResourceManager.addCatchments(catchments)

          // create the files from this data
          const files: Record<string, FileModelInstance> = (
            [
              ['attenuationProps', doc.attenuation_props],
              ['efficacy', doc.efficacy],
              ['interventionBudget', doc.intervention_budget],
            ] as [string, CIDSSFileDocument | null][]
          ).reduce(
            (obj, [key, fileDoc]) => ({
              ...obj,
              [key]:
                fileDoc === null
                  ? null
                  : FileModel.create({
                      truiiFileId: fileDoc.truii_file_id,
                      fileName: fileDoc.file_name,
                      fileType: fileDoc.file_type,
                      // attributeFileId: fileDoc.attribute_file_id,
                      type: fileDoc.type,
                      isValid: fileDoc.valid,
                      isProcessing: fileDoc.processing,
                      issue: fileDoc.issue,
                      createdAt: fileDoc.created_at,
                    }),
            }),
            {}
          )

          self.defaults = DefaultsModel.create({
            id: doc._id,
            createdAt: doc.createdAt,
            updatedAt: doc.updatedAt,
            interventions: doc.interventions.map((int) =>
              InterventionModel.create({
                id: int.int_id,
                name: int.int_name,
                description: int.int_description,
                group: int.int_group,
                allow: int.allow,
                programs: int.int_programs,
                unit: int.int_unit,
                cost: Number(int.int_cost),
                fieldName: int.int_field_name,
              })
            ),
            catchments: catchmentIds,
            attenuationProps: files.attenuationProps,
            efficacy: files.efficacy,
            interventionBudget: files.interventionBudget,
          })

          self.loadStateVolatile = LoadState.done
        } catch (err) {
          console.error(
            'error fetching defaults from api/ingesting into the local state',
            err
          )
          Sentry.captureException(err, {
            user: UserManagerInstance.meAsSentryUserContext,
          })

          self.loadStateVolatile = LoadState.error
        }
      }
    }),
  }))

export { DefaultsResourceManager }
