import { Instance, types } from 'mobx-state-tree'

const SpatialFeatureGeometryModel = types.model('SpatialFeatureGeometryModel', {
  // coordinates: types.array(types.array(types.array(types.number))), // 3d array of number (number[][][])
  coordinates: types.string,
  type: types.string,
})

interface SpatialFeatureGeometryModelInstance
  extends Instance<typeof SpatialFeatureGeometryModel> {}

export { SpatialFeatureGeometryModel }
export type { SpatialFeatureGeometryModelInstance }
