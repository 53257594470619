import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import UsersTable from 'components/tables/users'
import { NewLineBreakStringToJSX } from 'components/text/line-breaks'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FEATURED_ROUTE, SCENARIO_MANAGER_ROUTE } from 'routes'
import { routes } from 'scenes/routes'
import { useStore } from 'store/store'
import styled from 'styled-components'
import { LoadState } from 'types/loadstate'
import {
  PermissionAction,
  PermissionResource,
  Permissions,
  Role,
  RoleDescriptions,
  Roles,
} from 'types/permissions'
import { upperCaseFirstLetter } from 'utils/format'
import { NewUserPayloadSchema } from 'utils/schema'
import { AquaPrimary, DarkSecondaryGrey, RiskHigh } from 'values/colours'

const CloseIcon = styled(FontAwesomeIcon)`
  margin: 1rem;
  position: absolute;
  right: 0;
  color: ${DarkSecondaryGrey};
  cursor: pointer;
`

const Banner = styled(Box)`
  background-color: ${AquaPrimary};
  text-align: center;
  padding: 0.25rem 0;
`

const HighRiskButton = styled(Button)`
  background-color: ${RiskHigh} !important;
`

// const TextFieldWithCenteredText = styled(TextField)`
//   & input {
//     text-align: center;
//   }

//   > div:hover fieldset {
//     border-color: rgba(0, 0, 0, 0.26) !important;
//   }
// `

const UserManager: React.FC = observer(function UserManagerScene() {
  const {
    me,
    usersAsMap,
    usersFlatForTable,
    loadUsers,
    deleteUser,
    createUser,
    authenticatedUserIdTokenLoadStateVolatile,
  } = useStore()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()

  const [promptToInviteUser, setPromptToInviteUser] = useState(false)
  const [isDoingAction, setDoingAction] = useState(false)
  const [snackbarMessage, setSnackbarState] = useState<string | null>(null)
  const shouldOpenSnackbar = snackbarMessage !== null

  // const {
  //   register: registerPasswordChangeForm,
  //   handleSubmit: handlePasswordChangeFormSubmit,
  //   formState: { errors: passwordChangeFormErrors },
  // } = useForm({
  //   mode: 'onChange',
  //   resolver: yupResolver(UserPasswordChangeSchema),
  // })

  const {
    register: registerInviteUserForm,
    handleSubmit: handleInviteUserFormSubmit,
    formState: { errors: inviteUserFormErrors },
    reset,
    watch,
  } = useForm({ mode: 'onSubmit', resolver: yupResolver(NewUserPayloadSchema) })

  const navigateBackToScenarioManager = () => {
    // check if the user (us) can go to the manager route
    // if we're a plain user, we cannot
    // and have to go back to the featured page
    if (!me) {
      // not authenticated?
      // just go home
      navigate(FEATURED_ROUTE)
      return
    }

    if (me.can(routes.scenariosManager.permission)) {
      navigate(SCENARIO_MANAGER_ROUTE())
      return
    }

    // if we aren't authorised, go to the featured page
    navigate(FEATURED_ROUTE)
  }

  const resetUserPassword = useCallback(
    async (userId: string) => {
      // get user's model
      const user = usersAsMap[userId]
      if (!user) {
        // user does not exist, why are they in the table...
        setSnackbarState("Got error trying to reset user's password")
        return
      }

      setDoingAction(true)
      await user.reset()
      setDoingAction(false)
      setSnackbarState(`Successfully reset ${user.name}'s password`)
    },
    [usersAsMap]
  )

  const resetAuthenticatedUsersPassword = async () => {
    if (!me) {
      // user does not exist...
      setSnackbarState('Got error trying to reset password')
      return
    }

    await resetUserPassword(me.id)
  }

  const removeUser = async (userId: string) => {
    setDoingAction(true)
    await deleteUser(userId)
    setDoingAction(false)
    setSnackbarState('Removed user from the app')
  }

  const editUser = useCallback(
    async (userId: string, role: string) => {
      const user = usersAsMap[userId]
      if (!user) {
        // user does not exist, why are they in the table...
        setSnackbarState('Got error trying to update user')
        return
      }

      setDoingAction(true)
      await user.edit(role as Role)
      setDoingAction(false)
      setSnackbarState(`Successfully changed ${user.name}'s role`)
    },
    [usersAsMap]
  )

  const toggleInviteUserDialogByArg = (state: boolean) => () => {
    setPromptToInviteUser(state)
  }

  const inviteUser = async (data: any) => {
    setDoingAction(true)
    await createUser(data.email, data.name, data.role)

    // reset form values back to defaults
    reset({ email: '', name: '', role: 'user' })
    setDoingAction(false)
    setPromptToInviteUser(false)
    setSnackbarState(`Invited ${data.name} to the app`)
  }

  const handleSnackbarClose = (
    $event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarState(null)
  }

  // only load users when authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    if (authenticatedUserIdTokenLoadStateVolatile !== LoadState.done) {
      return
    }

    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, authenticatedUserIdTokenLoadStateVolatile])

  const roleSelectOptions = Roles.map((role) => (
    <MenuItem key={role} value={role}>
      {upperCaseFirstLetter(role)}
    </MenuItem>
  ))

  // const shouldDisablePasswordFormChangeBtn =
  //   Object.keys(passwordChangeFormErrors).length > 0

  const selectedRoleToInviteUser = watch('role') as string

  return (
    <Box sx={{ margin: '1rem' }}>
      <Snackbar
        open={shouldOpenSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />

      <Dialog
        open={promptToInviteUser}
        onClose={toggleInviteUserDialogByArg(false)}
      >
        <DialogTitle>Invite a new user to the app</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            placeholder="User email"
            fullWidth
            error={inviteUserFormErrors.email !== undefined}
            label={inviteUserFormErrors.email?.message as string}
            sx={{ marginTop: '1rem' }}
            {...registerInviteUserForm('email')}
          />
          <TextField
            variant="outlined"
            placeholder="Name"
            fullWidth
            error={inviteUserFormErrors.name !== undefined}
            label={inviteUserFormErrors.name?.message as string}
            sx={{ marginTop: '1rem' }}
            {...registerInviteUserForm('name')}
          />
          <Select
            fullWidth
            sx={{ marginTop: '1rem' }}
            defaultValue={Roles[0]}
            {...registerInviteUserForm('role')}
          >
            {roleSelectOptions}
          </Select>

          <NewLineBreakStringToJSX
            text={
              RoleDescriptions[(selectedRoleToInviteUser as Role) ?? Roles[0]]
            }
          />
        </DialogContent>
        <DialogActions>
          <HighRiskButton
            variant="contained"
            onClick={handleInviteUserFormSubmit(inviteUser)}
            disabled={isDoingAction}
          >
            Invite
          </HighRiskButton>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleInviteUserDialogByArg(false)}
            disabled={isDoingAction}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <CloseIcon
        icon={['fal', 'circle-xmark']}
        size="2x"
        onClick={navigateBackToScenarioManager}
      />

      <Box textAlign="center" marginBottom="2rem" paddingTop="1rem">
        <Typography variant="h3" color="primary.main">
          My account
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="stretch" justifyContent="center">
        <Grid item xs={12}>
          <Banner>
            <Typography variant="h4" color="text.secondary">
              Change your password
            </Typography>
          </Banner>

          <Grid
            container
            spacing={4}
            alignItems="stretch"
            justifyContent="center"
            marginTop="1rem"
          >
            <Grid item xs={3}>
              {/* <TextFieldWithCenteredText
                variant="outlined"
                placeholder="Enter your password"
                fullWidth
                type="password"
                sx={{ textAlign: 'center' }}
                error={passwordChangeFormErrors.password !== undefined}
                label={
                  passwordChangeFormErrors.password?.message as
                    | string
                    | undefined
                }
                {...registerPasswordChangeForm('password')}
              /> */}
              <Typography>
                Click the button to reset your password. You will receive an
                email with a password reset link.
              </Typography>
            </Grid>
            {/* <Grid item xs={3}>
              <TextFieldWithCenteredText
                variant="outlined"
                placeholder="Confirm your new password"
                fullWidth
                type="password"
                sx={{ textAlign: 'center' }}
                error={passwordChangeFormErrors.confirmPassword !== undefined}
                label={
                  passwordChangeFormErrors.confirmPassword?.message as
                    | string
                    | undefined
                }
                {...registerPasswordChangeForm('confirmPassword')}
              />
            </Grid> */}
            <Grid item xs={2}>
              <HighRiskButton
                variant="contained"
                fullWidth
                onClick={resetAuthenticatedUsersPassword}
                // disabled={shouldDisablePasswordFormChangeBtn}
                sx={{ color: 'text.secondary', height: '100%' }}
              >
                <Typography>Reset password</Typography>
              </HighRiskButton>
            </Grid>
          </Grid>

          {/* {passwordChangeFormErrors[''] !== undefined && (
            <Box textAlign="center">
              <Typography color="error.main">Passwords do not match</Typography>
            </Box>
          )} */}
        </Grid>
      </Grid>

      {me?.can(
        Permissions[PermissionResource.UserManagement][PermissionAction.Write]
      ) && (
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          justifyContent="center"
          marginTop="2rem"
        >
          <Grid item xs={12}>
            <Banner sx={{ marginBottom: '1rem' }}>
              <Typography variant="h4" color="text.secondary">
                Manage CIDSS users
              </Typography>
            </Banner>

            <Grid container justifyContent="end">
              <Grid item xs="auto">
                <Button
                  variant="outlined"
                  onClick={toggleInviteUserDialogByArg(true)}
                  sx={{ color: 'primary.main' }}
                >
                  <Typography sx={{ fontWeight: 700 }}>
                    Invite user&nbsp;&nbsp;
                    <FontAwesomeIcon icon="user-plus" />
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <UsersTable
              data={usersFlatForTable}
              resetUserPassword={resetUserPassword}
              deleteUser={removeUser}
              editUser={editUser}
              shouldDisableInputs={isDoingAction}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
})

export default UserManager
