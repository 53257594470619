import * as Sentry from '@sentry/react'
import { Instance, flow, getParent, types } from 'mobx-state-tree'
import { ScenariosResourceManagerInstance } from 'store/resource-managers/scenarios'
import { UsersResourceManagerInstance } from 'store/resource-managers/users'
import { CatchmentFromApi } from 'types/db/catchment'
import { ScenarioFromApi } from 'types/db/scenario'
import { AttemptResults } from 'types/results'
import { endpoints, patch, post } from 'utils/api'
import {
  CreateResultsInterventions,
  CreateResultsThreatRisks,
} from 'utils/funcs'
import { asyncRun } from 'utils/py'
import { AttemptStatus, ScenarioStatus } from 'utils/status'
import { v4 } from 'uuid'
import { DefaultEmptyRecreationFile, FileExts } from 'values/files'
import { FileModel, FileModelInstance } from './file'
import { ResultsModel } from './results'
import { ScenarioModel } from './scenario'

const optimisationScriptPath = require('py/scenario_onetime.py?raw')

let optimisationScript: Promise<string | null> = Promise.resolve(null)

type IntChange = { intId: string; amount: number; puId?: string }

const IntChangesModel = types
  .model('IntChangesModel', {
    intId: types.string,
    amount: types.number,
    puId: types.maybe(types.string),
  })
  .views((self) => ({
    get serialisedFlat() {
      return {
        int_id: self.intId,
        allow: self.amount !== 0,
        _id: self.puId,
        value: self.amount,
        type: self.puId !== undefined ? 'pu' : 'scenario',
      }
    },
  }))
  .actions((self) => ({
    reconcileSelf: (change: IntChange) => {
      if (change.intId === self.intId && change.puId === self.puId) {
        self.amount = change.amount
      }
    },
  }))

const AttemptModel = types
  .model('AttemptModel', {
    id: types.optional(types.identifier, () => v4()),
    scenario: types.reference(ScenarioModel),
    interventionChanges: types.array(IntChangesModel),
    beforeThreat: types.maybe(FileModel),
    afterThreat: types.maybe(FileModel),
    interventionMatrix: types.maybe(FileModel),
    costMatrix: types.maybe(FileModel),
    results: types.maybe(ResultsModel),
    // catchments: types.array(CatchmentModel),
    runTimeInMs: types.maybe(types.number),
    createdAt: types.optional(types.string, () => new Date().toISOString()),
    updatedAt: types.optional(types.string, () => new Date().toISOString()),
  })
  .volatile(() => ({
    runStatus: AttemptStatus.INIT,
  }))
  .views((self) => ({
    get name() {
      return self.scenario?.name + ' Attempt'
    },
    get featured() {
      return false
    },
    get runParams() {
      const catchments = self.scenario.catchments
        .filter(({ enabled }) => enabled)
        .map((catchment) => ({
          id: catchment.id,
          spatial: catchment.spatial,
          agriculture: catchment.agriculture,
          industry: catchment.industry,
          interventionLimits: catchment.interventionLimits,
          interventionGroupLimits: catchment.interventionGroupLimits,
          onSite: catchment.onSite,
          risk: catchment.risk,
          rawThreat: catchment.rawThreat,
          stp: catchment.stp,
          stormWater: catchment.stormWater,
          pu: catchment.pu,
          puProperties: catchment.puProperties,
          recreation:
            catchment.recreation ??
            FileModel.create({
              truiiFileId: DefaultEmptyRecreationFile,
              fileName: '',
              fileType: 'csv',
              type: 'Recreation',
              isValid: true,
              isProcessing: false,
              issue: '',
              createdAt: new Date(0).toISOString(),
            }),
        }))

      return {
        efficacy: self.scenario.efficacy,
        attenuationProps: self.scenario.attenuationProps,
        interventionBudget: self.scenario.interventionBudget,
        budget: self.scenario.budget,
        interventionMatrix: self.scenario.interventionMatrix,
        intChanges: self.interventionChanges.map(
          ({ serialisedFlat }) => serialisedFlat
        ),
        runMode: self.scenario.runMode,
        riskWeights: self.scenario.riskWeights
          ? FileModel.create({
              truiiFileId: self.scenario.riskWeights,
              fileName: 'risk_weights',
              fileType: 'csv',
              type: 'RiskWeights',
              isValid: true,
              isProcessing: false,
              issue: '',
              createdAt: new Date().toISOString(),
            })
          : null,
        tMax: self.scenario.tMax,
        tMin: self.scenario.tMin,
        sMaxSteps: self.scenario.sMaxSteps,
        maxSteps: self.scenario.maxSteps,
        catchments,
      }
    },
    get runProgress() {
      switch (self.runStatus) {
        case AttemptStatus.INIT:
          return 20

        case AttemptStatus.DOWNLOADING:
          return 40

        case AttemptStatus.RUNNING:
          return 60

        case AttemptStatus.PROCESSING:
          return 80

        default:
          return 100
      }
    },
    get isRunning() {
      return ![
        AttemptStatus.INIT,
        AttemptStatus.ERROR,
        AttemptStatus.DONE,
      ].includes(self.runStatus)
    },
    get done() {
      return self.runStatus === AttemptStatus.DONE
    },
  }))
  .actions((self) => ({
    setIntChanges: (changes: IntChange[]) => {
      changes.forEach((change) => {
        // get existing change
        const existing = self.interventionChanges.find(
          ({ intId, puId }) => intId === change.intId && puId === change.puId
        )
        if (existing) {
          existing.reconcileSelf(change)
          return
        }

        self.interventionChanges.push(IntChangesModel.create(change))
      })
    },
    removeIntChange: (change: IntChange) => {
      const index = self.interventionChanges.findIndex(
        ({ intId, puId }) => intId === change.intId && puId === change.puId
      )
      if (index > -1) {
        self.interventionChanges.splice(index, 1)
      }
    },
  }))
  .actions((self) => ({
    run: flow(function* (onDone?: () => void) {
      // load the optimisation script if it hasn't been already
      let _optimisationScript = yield optimisationScript
      if (_optimisationScript === null) {
        const resp = yield fetch(optimisationScriptPath)
        _optimisationScript = yield resp.text()
        optimisationScript = Promise.resolve(_optimisationScript)
      }

      // do any and all prep work for the scenario files
      // in order to execute the python script
      const start = performance.now()
      const params = {
        budget: self.runParams.budget,
        intervention_changes: self.runParams.intChanges,
        run_mode: self.runParams.runMode,
        t_max: self.runParams.tMax,
        t_min: self.runParams.tMin,
        s_max_steps: self.runParams.sMaxSteps,
        max_steps: self.runParams.maxSteps,
      }

      self.runStatus = AttemptStatus.DOWNLOADING

      const files = {
        attenuation_props: yield self.runParams.attenuationProps?.download(),
        efficacy: yield self.runParams.efficacy?.download(),
        intervention_budget:
          yield self.runParams.interventionBudget?.download(),
        intervention_matrix:
          yield self.runParams.interventionMatrix?.download(),
        risk_weights: yield self.runParams.riskWeights?.download(),
        catchments: yield Promise.all(
          self.runParams.catchments.map(async (catchment) => ({
            _id: catchment.id,
            risk: await catchment.risk?.download(),
            spatial: await catchment.spatial?.download(),
            pu: await catchment.pu?.download(),
            storm_water: await catchment.stormWater?.download(),
            agriculture: await catchment.agriculture?.download(),
            industry: await catchment.industry?.download(),
            stp: await catchment.stp?.download(),
            onsite: await catchment.onSite?.download(),
            raw_threat: await catchment.rawThreat?.download(),
            intervention_limits: await catchment.interventionLimits?.download(),
            intervention_group_limits:
              await catchment.interventionGroupLimits?.download(),
            pu_properties: await catchment.puProperties?.download(),
            recreation: await catchment.recreation?.download(),
          }))
        ),
      }

      self.runStatus = AttemptStatus.RUNNING

      // execute said python script
      const resp: AttemptResults = yield asyncRun(_optimisationScript, {
        files,
        params,
      })
      const end = performance.now()
      self.runTimeInMs = end - start

      // console.log('resp=', resp)
      self.runStatus = AttemptStatus.PROCESSING

      const { data } = resp.results

      // save the results and file contents into the store
      // so we can render the data on the interface!
      const filesToCreateAndSave = [
        {
          setter: (file: FileModelInstance) => (self.afterThreat = file),
          fileType: FileExts.CSV,
          type: 'RawThreat',
          name: 'attempt_after_threat',
          contents: data.files.after_threat,
        },
        {
          setter: (file: FileModelInstance) => (self.beforeThreat = file),
          fileType: FileExts.CSV,
          type: 'RawThreat',
          name: 'attempt_before_threat',
          contents: data.files.before_threat,
        },
        {
          setter: (file: FileModelInstance) => (self.interventionMatrix = file),
          fileType: FileExts.CSV,
          type: 'InterventionMatrix',
          name: 'attempt_intervention_matrix',
          contents: data.files.intervention_matrix,
        },
        {
          setter: (file: FileModelInstance) => (self.costMatrix = file),
          fileType: FileExts.CSV,
          type: 'CostMatrix',
          name: 'attempt_cost_matrix',
          contents: data.files.cost_matrix,
        },
      ]

      filesToCreateAndSave.forEach(
        ({ setter, fileType, type, name, contents }) => {
          const file = FileModel.create({
            fileName: name,
            fileType,
            type,
            isValid: true,
            isProcessing: false,
            issue: '',
            contents,
          })
          setter(file)
        }
      )

      const {
        results: { scenario_results, catchments },
      } = data.files
      self.results = ResultsModel.create({
        id: scenario_results._id,
        beforeOverallRisk: scenario_results.before_overall_risk,
        afterOverallRisk: scenario_results.after_overall_risk,
        maxRisk: scenario_results.max_risk,
        minRisk: scenario_results.min_risk,
        beforeThreatRisks: CreateResultsThreatRisks(
          scenario_results.before_threat_risks
        ),
        afterThreatRisks: CreateResultsThreatRisks(
          scenario_results.after_threat_risks
        ),
        interventions: CreateResultsInterventions(
          scenario_results.interventions
        ),
      })

      catchments.forEach(({ _id, catchment_results, pu_results }) => {
        const catchment = self.scenario.catchments.find(({ id }) => id === _id)
        if (catchment === undefined) {
          // something went wrong, the catchment should exist
          // if the results have it...
          const err = new Error(
            'missing catchment in source data, but present in results data...'
          )
          Sentry.captureException(err)
          return
        }

        catchment.addAttemptResults(
          ResultsModel.create({
            id: _id,
            beforeOverallRisk: catchment_results.before_overall_risk,
            afterOverallRisk: catchment_results.after_overall_risk,
            maxRisk: catchment_results.max_risk,
            minRisk: catchment_results.min_risk,
            beforeThreatRisks: CreateResultsThreatRisks(
              catchment_results.before_threat_risks
            ),
            afterThreatRisks: CreateResultsThreatRisks(
              catchment_results.after_threat_risks
            ),
            interventions: CreateResultsInterventions(
              catchment_results.interventions
            ),
          })
        )

        catchment.addAttemptPuResults(
          pu_results.map((results) =>
            ResultsModel.create({
              id: results._id,
              beforeOverallRisk: results.before_overall_risk,
              afterOverallRisk: results.after_overall_risk,
              maxRisk: results.max_risk,
              minRisk: results.min_risk,
              beforeThreatRisks: CreateResultsThreatRisks(
                results.before_threat_risks
              ),
              afterThreatRisks: CreateResultsThreatRisks(
                results.after_threat_risks
              ),
              interventions: CreateResultsInterventions(results.interventions),
            })
          )
        )
      })

      self.runStatus = AttemptStatus.DONE
      onDone && onDone()
    }),
    overwrite: flow(function* () {
      if (!self.done) {
        throw new Error('attempt must have been ran before saving')
      }

      // create a payload for all the scenario changes
      const payload: ScenarioFromApi = {
        ...self.scenario.serialisedFlat,
        scenario_results: self.results?.serialisedFlat ?? null,
        intervention_matrix: self.interventionMatrix?.serialisedFlat ?? null,
        cost_matrix: self.costMatrix?.serialisedFlat ?? null,
        before_pu_attenuated_threats: self.beforeThreat?.serialisedFlat ?? null,
        after_pu_attenuated_threats: self.afterThreat?.serialisedFlat ?? null,
        status: ScenarioStatus.REFINED,
      }

      const catchmentsWithResults = self.scenario.catchments.filter(
        ({ attemptResults }) => attemptResults
      )

      // update the catchments that have attempt results
      yield Promise.all(
        catchmentsWithResults.map(async (catchment) => {
          const payload: CatchmentFromApi = {
            ...catchment.serialisedFlat,
            catchment_results: catchment.attemptResults
              ? {
                  ...catchment.attemptResults.serialisedFlat,
                  _id: catchment.id,
                }
              : null,
            pu_results: catchment.puResults.map(
              ({ serialisedFlat }) => serialisedFlat
            ),
          }
          await catchment.save(payload)
        })
      )

      // update the scenario now
      yield self.scenario.save(payload)
    }),
    save: flow(function* () {
      if (!self.done) {
        throw new Error('attempt must have been ran before saving')
      }

      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        2
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      // copy the scenario and replace any parts with attempt data
      let endpoint = endpoints.scenarioOneCopy(self.scenario.id)
      const scenarios: ScenarioFromApi[] = yield post<ScenarioFromApi[]>(
        endpoint,
        null,
        headers
      )

      const scenario = scenarios?.[0]

      // replace the copied scenario data with our attempt data
      scenario.name = scenario.name + ' Attempt'
      scenario.scenario_results = self.results?.serialisedFlat ?? null
      scenario.intervention_matrix =
        self.interventionMatrix?.serialisedFlat ?? null
      scenario.cost_matrix = self.costMatrix?.serialisedFlat ?? null
      scenario.before_pu_attenuated_threats =
        self.beforeThreat?.serialisedFlat ?? null
      scenario.after_pu_attenuated_threats =
        self.afterThreat?.serialisedFlat ?? null
      scenario.status = ScenarioStatus.REFINED

      // update the catchment data and save each one
      const catchmentsWithResults = self.scenario.catchments.filter(
        ({ attemptResults }) => attemptResults
      )
      yield Promise.all(
        catchmentsWithResults.map(
          async ({ id, attemptResults, attemptPuResults }) => {
            const catchment = (scenario.catchments as CatchmentFromApi[]).find(
              ({ _id }) => _id === id
            )
            if (!catchment || !attemptResults || !attemptPuResults) {
              return
            }

            // set the results for the catchment and its pu's
            catchment.catchment_results = attemptResults.serialisedFlat
            catchment.catchment_results._id = id // ensure the id's match
            catchment.pu_results = attemptPuResults.map(
              ({ serialisedFlat }) => serialisedFlat
            )

            // save the catchment
            // because the catchment isn't an MST model
            // yet!
            const endpoint = endpoints.catchmentOne(id)
            await patch(endpoint, catchment, headers)
          }
        )
      )

      // save the scenario
      // it also is not an MST model yet
      const catchments = scenario.catchments as CatchmentFromApi[]
      scenario.catchments = catchments.map(({ _id }) => _id)

      endpoint = endpoints.scenarioOne(scenario._id)
      yield patch(endpoint, scenario, headers)

      // re-assign the catchments to the scenario
      // and ingest the scenario into MST
      scenario.catchments = catchments

      const scenariosResourceManagerInstance =
        self as unknown as ScenariosResourceManagerInstance
      scenariosResourceManagerInstance.ingestNewScenarioFromApi(scenario)

      return scenario._id
    }),
  }))

interface AttemptModelInstance extends Instance<typeof AttemptModel> {}
interface IntChangesModelInstance extends Instance<typeof IntChangesModel> {}

export { AttemptModel, IntChangesModel }
export type { AttemptModelInstance, IntChange, IntChangesModelInstance }
