import { Instance, flow, types } from 'mobx-state-tree'
import { ScenarioModel, ScenarioModelInstance } from 'store/models/scenario'
import { PartialScenarioFromApi, ScenarioFromApi } from 'types/db/scenario'
import { LoadState } from 'types/loadstate'
import { endpoints, get, post } from 'utils/api'
import { ResultsModel } from 'store/models/results'
import { ScenarioFlatTableProps } from 'types/table'
import { CatchmentFromApi } from 'types/db/catchment'
import {
  CreateCatchmentFileModels,
  CreateCatchmentResultModels,
  CreateFileModels,
  CreateResultsInterventions,
  CreateResultsThreatRisks,
} from 'utils/funcs'
import { CatchmentModel } from 'store/models/catchment'
import { CatchmentsResourceManagerInstance } from './catchments'
import { InterventionModel } from 'store/models/intervention'
import { CIDSSFileDocument } from 'types/db/file'
import { ScenarioID } from 'types/db/common'
import { UsersResourceManagerInstance } from './users'
import * as Sentry from '@sentry/react'

const ScenariosResourceManager = types
  .model('ScenariosResourceManager', {
    scenarios: types.array(ScenarioModel),
    selectedScenarioId: types.maybeNull(types.string),
  })
  .volatile(() => ({
    scenarioLoadStateVolatile: LoadState.init,
  }))
  .views((self) => ({
    get notDeletedScenarios(): ScenarioModelInstance[] {
      return self.scenarios.filter(({ isDeleted }) => !isDeleted)
    },
  }))
  .views((self) => ({
    get selectedScenario() {
      if (self.selectedScenarioId === null) {
        return null
      }

      return self.scenarios.find(
        (scenario) => scenario.id === self.selectedScenarioId
      )
    },
    get scenariosAsMap(): Record<string, ScenarioModelInstance> {
      return self.scenarios.reduce(
        (obj, scenario) => ({ ...obj, [scenario.id]: scenario }),
        {}
      )
    },
    get scenariosFlatForTable(): ScenarioFlatTableProps[] {
      return self.notDeletedScenarios.map(
        ({ serialisedFlatForTable }) => serialisedFlatForTable
      )
    },
    get featuredScenario() {
      return self.scenarios.find(({ isFeatured }) => isFeatured)
    },
  }))
  .actions((self) => ({
    setSelectedScenarioById(scenarioId: string) {
      // check if the id exists in our list
      if (!Object.keys(self.scenariosAsMap).includes(scenarioId)) {
        // throw new Error(
        //   'specified scenario id not found in list of scenarios in store'
        // )
        return
      }

      self.selectedScenarioId = scenarioId
    },
  }))
  .actions((self) => ({
    ingestNewScenarioFromApi: (doc: ScenarioFromApi) => {
      // create the catchments from this data
      let catchmentIds: string[] = []
      if (doc.catchments) {
        const catchments = (doc.catchments as CatchmentFromApi[]).map(
          (catchment) => {
            const files = CreateCatchmentFileModels(catchment)
            const { pu: puResults, catchment: catchmentResults } =
              CreateCatchmentResultModels(catchment)

            return CatchmentModel.create({
              id: catchment._id,
              createdAt: catchment.createdAt,
              updatedAt: catchment.updatedAt,
              name: catchment.catchment_name,
              description: catchment.catchment_description,
              spatial: files.spatial,
              pu: files.pu,
              stormWater: files.stormWater,
              agriculture: files.agriculture,
              industry: files.industry,
              stp: files.stp,
              risk: files.risk,
              interventionLimits: files.interventionLimits,
              interventionGroupLimits: files.interventionGroupLimits,
              onSite: files.onSite,
              rawThreat: files.rawThreat,
              puProperties: files.puProperties,
              recreation: files.recreation,
              puNumbers: catchment.pu_numbers,
              puResults,
              enabled: catchment.allow,
              catchmentResults,
            })
          }
        )

        catchmentIds = (doc.catchments as CatchmentFromApi[]).map(
          (catchment) => catchment._id
        )

        // get the catchments resource manager from self
        const CatchmentsResourceManager =
          self as unknown as CatchmentsResourceManagerInstance
        CatchmentsResourceManager.addCatchments(catchments)
      }

      // create the interventions from this data
      const interventions =
        doc.interventions?.map((int) =>
          InterventionModel.create({
            id: int.int_id,
            name: int.int_name,
            description: int.int_description,
            group: int.int_group,
            allow: int.allow,
            programs: int.int_programs,
            unit: int.int_unit,
            cost: Number(int.int_cost),
            fieldName: int.int_field_name,
          })
        ) ?? []

      // create the files from this data
      const files = CreateFileModels([
        ['attenuationProps', doc.attenuation_props],
        ['efficacy', doc.efficacy],
        ['interventionBudget', doc.intervention_budget],
        ['spatial', doc.spatial],
        ['beforePuAttenuatedThreats', doc.before_pu_attenuated_threats],
        ['afterPuAttenuatedThreats', doc.after_pu_attenuated_threats],
        ['costMatrix', doc.cost_matrix],
        ['interventionMatrix', doc.intervention_matrix],
      ] as [string, CIDSSFileDocument | null][])

      const attenuatedThreats = doc.attenuated_threats
        ? Object.entries(doc.attenuated_threats).reduce(
            (
              obj,
              [catchmentId, fileIds]: [
                string,
                { before: string; after: string }
              ]
            ) => ({ ...obj, [catchmentId]: { id: catchmentId, ...fileIds } }),
            {}
          )
        : undefined

      const scenarioResults = !doc.scenario_results
        ? null
        : ResultsModel.create({
            id: doc.scenario_results._id,
            createdAt: doc.scenario_results.createdAt,
            beforeOverallRisk: doc.scenario_results.before_overall_risk,
            afterOverallRisk: doc.scenario_results.after_overall_risk,
            maxRisk: doc.scenario_results.max_risk,
            minRisk: doc.scenario_results.min_risk,
            beforeThreatRisks: CreateResultsThreatRisks(
              doc.scenario_results?.before_threat_risks ?? []
            ),
            afterThreatRisks: CreateResultsThreatRisks(
              doc.scenario_results?.after_threat_risks ?? []
            ),
            interventions: CreateResultsInterventions(
              doc.scenario_results?.interventions ?? []
            ),
          })

      self.scenarios.push(
        ScenarioModel.create({
          id: doc._id,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          isDeleted: doc.deleted,
          name: doc.name,
          description: doc.description,
          budget: doc.budget,
          catchments: catchmentIds,
          interventions,
          attenuationProps: files.attenuationProps,
          efficacy: files.efficacy,
          interventionBudget: files.interventionBudget,
          progress: doc.progress ?? 0,
          progressIssues: null,
          spatial: files.spatial,
          attempts: doc.attempts,
          beforePuAttenuatedThreats: files.beforePuAttenuatedThreats,
          afterPuAttenuatedThreats: files.afterPuAttenuatedThreats,
          scenarioResults,
          status: doc.status,
          job: undefined,
          isFeatured: doc.featured,
          maxSteps: doc.max_steps,
          sMaxSteps: doc.s_max_steps,
          tMax: doc.tmax,
          tMin: doc.tmin,
          costMatrix: files.costMatrix,
          interventionMatrix: files.interventionMatrix,
          cost: doc.cost,
          timeLeft: doc.time_left,
          allowedCatchments: doc.allowed_catchments,
          interventionChanges: doc.intervention_changes,
          isEdited: doc.edited,
          visibility: doc.visibility,
          runMode: doc.run_mode,
          riskWeights: doc.risk_weights,
          riskWeightsRaw: doc.risk_weights_raw,
          riskWeightsResult: doc.risk_weights_result,
          attenuatedThreats,
          annotations: doc.annotations,
          annotationReason: doc.annotation_reason,
        })
      )
    },
    ingestNewPartialScenarioFromApi: (doc: PartialScenarioFromApi) => {
      const scenarioResults = !doc.scenario_results
        ? null
        : ResultsModel.create({
            id: doc.scenario_results._id,
            createdAt: doc.scenario_results.createdAt,
            beforeOverallRisk: doc.scenario_results.before_overall_risk,
            afterOverallRisk: doc.scenario_results.after_overall_risk,
            maxRisk: null,
            minRisk: null,
            beforeThreatRisks: [],
            afterThreatRisks: [],
            interventions: [],
          })

      self.scenarios.push(
        ScenarioModel.create({
          id: doc._id,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          isDeleted: false,
          name: doc.name,
          description: doc.description,
          budget: doc.budget,
          catchments: [],
          interventions: [],
          progress: 0,
          scenarioResults,
          status: doc.status,
          isFeatured: doc.featured,
          allowedCatchments: doc.allowed_catchments,
          visibility: doc.visibility,
        })
      )
    },
  }))
  .actions((self) => ({
    loadScenarios: flow(function* () {
      if (
        [LoadState.init, LoadState.error].includes(
          self.scenarioLoadStateVolatile
        )
      ) {
        self.scenarioLoadStateVolatile = LoadState.inProgress

        // get the token for our authenticated user
        const UserManagerInstance =
          self as unknown as UsersResourceManagerInstance
        const headers: { authorization?: string } = {}
        if (UserManagerInstance.authenticatedUserIdToken) {
          headers.authorization = UserManagerInstance.authenticatedUserIdToken
        }

        try {
          const docs: PartialScenarioFromApi[] = yield get<
            PartialScenarioFromApi[]
          >(endpoints.scenarios(), headers)

          // convert each document to mst fields
          docs.forEach((doc) => {
            const scenario = self.scenarios.find(({ id }) => id === doc._id)
            if (scenario) {
              scenario.reconcilePartialSelf(doc)
              return
            }

            self.ingestNewPartialScenarioFromApi(doc)
          })

          self.scenarioLoadStateVolatile = LoadState.done
        } catch (err) {
          // console.error('err=', err)
          Sentry.captureException(err, {
            user: UserManagerInstance.meAsSentryUserContext,
          })
          self.scenarioLoadStateVolatile = LoadState.error
        }
      }
    }),
    copyScenario: flow(function* (scenarioId: string) {
      // get the token for our authenticated user
      const UserManagerInstance =
        self as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      const endpoint = endpoints.scenarioOneCopy(scenarioId)
      const docs: ScenarioFromApi[] = yield post<ScenarioFromApi[]>(
        endpoint,
        null,
        headers
      )

      try {
        const scenario = docs?.[0]
        scenario.catchments = [] // clear the catchments to prevent duping
        self.ingestNewScenarioFromApi(scenario)
      } catch (err) {
        console.error('error adding newly copied scenario into state ', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })

        throw err
      }
    }),
    create: flow(function* (): Generator<
      Promise<ScenarioID | ScenarioFromApi[]>,
      ScenarioID,
      any
    > {
      // get the token for our authenticated user
      const UserManagerInstance =
        self as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      const endpoint = endpoints.scenarios()
      const docs: ScenarioFromApi[] = yield post<ScenarioFromApi[]>(
        endpoint,
        null,
        headers
      )

      try {
        const doc = docs?.[0]
        self.ingestNewScenarioFromApi(doc)
        self.setSelectedScenarioById(doc._id)
        return doc._id
      } catch (err) {
        console.error('error adding newly created scenario into state ', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })

        throw err
      }
    }),
  }))
  .actions((self) => ({
    refreshScenarios: flow(function* () {
      // get the token for our authenticated user
      const UserManagerInstance =
        self as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        const docs: PartialScenarioFromApi[] = yield get<
          PartialScenarioFromApi[]
        >(endpoints.scenarios(), headers)

        // loop over each document and try to reconcile it
        // if it doesn't exist, we can add it to our store
        docs.forEach((doc) => {
          const scenario = self.scenarios.find(({ id }) => id === doc._id)
          if (scenario) {
            scenario.reconcilePartialSelf(doc)
            return
          }

          // scenario does not exist in store
          // add it...
          self.ingestNewPartialScenarioFromApi(doc)
        })

        // find scenarios that are not in the api response
        // and remove them from the store
        const scenariosFromApi = new Set(docs.map(({ _id }) => _id))
        const scenariosToRemove = self.scenarios.filter(
          ({ id }) => !scenariosFromApi.has(id)
        )
        scenariosToRemove.forEach((scenario) => scenario.setDeleted(true))
      } catch (err) {
        console.error('got error trying to refresh scenarios', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })
      }
    }),
  }))

interface ScenariosResourceManagerInstance
  extends Instance<typeof ScenariosResourceManager> {}

export { ScenariosResourceManager }
export type { ScenariosResourceManagerInstance }
