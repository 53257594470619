import { FileModel, FileModelInstance } from 'store/models/file'
import {
  InterventionModel,
  InterventionModelInstance,
} from 'store/models/intervention'
import { ResultsModel, ResultsModelInstance } from 'store/models/results'
import {
  ThreatRisksModel,
  ThreatRisksModelInstance,
  ThreatRiskValuesModel,
} from 'store/models/threat-risks'
import { CatchmentFromApi } from 'types/db/catchment'
import { CIDSSFileDocument } from 'types/db/file'
import {
  CIDSSResultsInterventionsDocument,
  CIDSSThreatRisksDocument,
} from 'types/db/results'
import { AttemptResultsDataFilesResultsIntervention } from 'types/results'

function CreateResultsThreatRisks(
  threatRisks: CIDSSThreatRisksDocument[]
): ThreatRisksModelInstance[] {
  return threatRisks.map((risk) => {
    const values = risk.values.map((value) =>
      ThreatRiskValuesModel.create({
        type: value.type,
        value: value.value,
        threat: value.threat,
        overallRisk: value.overall_risk,
      })
    )

    return ThreatRisksModel.create({
      id: risk._id,
      name: risk.name,
      description: risk.description,
      type: risk.type,
      values,
    })
  })
}

function CreateResultsInterventions(
  interventions: Array<
    | CIDSSResultsInterventionsDocument
    | AttemptResultsDataFilesResultsIntervention
  >
): InterventionModelInstance[] {
  return interventions.map((int) => {
    const id =
      (int as CIDSSResultsInterventionsDocument).id ??
      (int as AttemptResultsDataFilesResultsIntervention)._id

    return InterventionModel.create({
      id,
      name: int.name,
      description: int.description,
      allow: true,
      unit: int.unit,
      cost: int.cost,
      number: int.number,
      key: int.key,
    })
  })
}

const CreateFileModels = (
  files: [string, CIDSSFileDocument | null][]
): Record<string, FileModelInstance | null> => {
  return files.reduce(
    (obj, [key, fileDoc]) => ({
      ...obj,
      [key]:
        fileDoc === null || fileDoc === undefined
          ? null
          : FileModel.create({
              truiiFileId: fileDoc.truii_file_id,
              fileName: fileDoc.file_name,
              fileType: fileDoc.file_type,
              // attributeFileId: fileDoc.attribute_file_id,
              type: fileDoc.type,
              isValid: fileDoc.valid,
              isProcessing: fileDoc.processing,
              issue: fileDoc.issue,
              createdAt: fileDoc.created_at,
            }),
    }),
    {}
  )
}

const CreateCatchmentFileModels = (
  catchment: CatchmentFromApi
): Record<string, FileModelInstance | null> => {
  return CreateFileModels([
    ['spatial', catchment.spatial],
    ['pu', catchment.pu],
    ['stormWater', catchment.storm_water],
    ['agriculture', catchment.agriculture],
    ['industry', catchment.industry],
    ['stp', catchment.stp],
    ['risk', catchment.risk],
    ['interventionLimits', catchment.intervention_limits],
    ['interventionGroupLimits', catchment.intervention_group_limits],
    ['onSite', catchment.onsite],
    // NOTE: the db has this field stored as "raw_theat"
    // i don't know why, it just is...
    // so we fallback to it, but all future saving is done
    // using the correct spelling
    // the issue will slowly migrate itself away
    ['rawThreat', catchment.raw_threat ?? catchment.raw_theat],
    ['puProperties', catchment.pu_properties],
    ['recreation', catchment.recreation],
  ] as [string, CIDSSFileDocument | null][])
}

const CreateCatchmentResultModels = ({
  pu_results,
  catchment_results,
}: CatchmentFromApi): {
  pu: ResultsModelInstance[]
  catchment: ResultsModelInstance | null
} => {
  const pu = pu_results.map((results) =>
    ResultsModel.create({
      id: results._id,
      createdAt: results.createdAt,
      beforeOverallRisk: results.before_overall_risk,
      afterOverallRisk: results.after_overall_risk,
      maxRisk: results.max_risk,
      minRisk: results.min_risk,
      beforeThreatRisks: CreateResultsThreatRisks(results.before_threat_risks),
      afterThreatRisks: CreateResultsThreatRisks(results.after_threat_risks),
      interventions: CreateResultsInterventions(results.interventions),
    })
  )

  let catchment: ResultsModelInstance | null = null
  if (catchment_results !== null) {
    catchment = ResultsModel.create({
      id: catchment_results._id,
      createdAt: catchment_results.createdAt,
      beforeOverallRisk: catchment_results.before_overall_risk,
      afterOverallRisk: catchment_results.after_overall_risk,
      maxRisk: catchment_results.max_risk,
      minRisk: catchment_results.min_risk,
      beforeThreatRisks: CreateResultsThreatRisks(
        catchment_results.before_threat_risks
      ),
      afterThreatRisks: CreateResultsThreatRisks(
        catchment_results.after_threat_risks
      ),
      interventions: CreateResultsInterventions(
        catchment_results.interventions
      ),
    })
  }

  return { pu, catchment }
}

export {
  CreateResultsThreatRisks,
  CreateResultsInterventions,
  CreateCatchmentFileModels,
  CreateCatchmentResultModels,
  CreateFileModels,
}
