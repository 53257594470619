import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faDiamondExclamation,
  faPenToSquare,
  faEllipsisV,
  faCaretUp,
  faCaretDown,
  faPlus,
  faCog,
  faCaretRight,
  faRotateRight,
  faEquals,
  faXmark,
  faBarsSort,
  faUsers,
  faRightToBracket,
  faAwardSimple,
  faCheckCircle,
  faCircleXmark as fasCircleXmark,
  faRotateLeft,
  faCaretLeft,
  faUser,
  faCheck,
  faUserMagnifyingGlass,
  faCauldron,
  faUpload,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons'
import { faDownload, faTable } from '@fortawesome/pro-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons'
import {
  faEarthOceania,
  faVectorPolygon,
  faKey,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faDiamondExclamation,
  faDownload,
  faPenToSquare,
  faEllipsisV,
  faCaretUp,
  faCaretDown,
  faPlus,
  faCog,
  faCaretRight,
  faRotateRight,
  faEquals,
  faXmark,
  faCircleXmark,
  faBarsSort,
  faTable,
  faUsers,
  faRightToBracket,
  faAwardSimple,
  faCheckCircle,
  fasCircleXmark,
  faRotateLeft,
  faCaretLeft,
  faUser,
  faCheck,
  faEarthOceania,
  faVectorPolygon,
  faKey,
  faUserMagnifyingGlass,
  faCauldron,
  faUpload,
  faUserPlus
)
