import { Roles } from '../types/permissions'
import { object, string, bool, number, array, lazy } from 'yup'

const mapRules = (map: Object | null | undefined, rule: any) =>
  Object.keys(map ?? {}).reduce(
    (newMap, key) => ({ ...newMap, [key]: rule }),
    {}
  )

const auth0PasswordPolicy = string()
  .min(8)
  .matches(/[a-zA-Z0-9]/)
  .matches(/[!@#$%^&*]/)

const NewUserPayloadSchema = object({
  email: string().email().required(),
  name: string().required(),
  role: string().oneOf(Roles),
})

const UserPasswordChangeSchema = object({
  password: auth0PasswordPolicy.required().label('Password'),
  confirmPassword: auth0PasswordPolicy.required().label('Confirm password'),
}).test(
  'equals',
  'passwords must match',
  ({ password, confirmPassword }) => password === confirmPassword
)

const CIDSSFileSchema = object({
  truii_file_id: string().required(),
  file_name: string().required(),
  file_type: string().required(),
  attribute_file_id: string(),
  type: string().required(),
  valid: bool().required(),
  processing: bool().required(),
  issue: string(),
  progress: number().required(),
  created_at: string().required(),
  updated_at: string().required(),
})

const CIDSSThreatRisksSchema = object({
  _id: string(),
  name: string().required(),
  type: string().required(),
  description: string().required(),
  values: array(
    object({
      type: string().required(),
      value: number().required(),
      threat: number().required(),
      overall_risk: number().required(),
    })
  ),
})

const ScenarioRiskWeightsSchema = object({
  tss: number().min(0).max(100).required().integer(),
  bacteria: number().min(0).max(100).required().integer(),
  virus: number().min(0).max(100).required().integer(),
  protozoa: number().min(0).max(100).required().integer(),
}).test('equals', 'total should add to 100', (value) => {
  const { tss, bacteria, virus, protozoa } = value
  return (tss ?? 0) + (bacteria ?? 0) + (virus ?? 0) + (protozoa ?? 0) === 100
})

const ScenarioEditSchema = object({
  name: string(),
  description: string(),
  budget: number().required().min(0),
})

const ScenarioSettingsSchema = object({
  tmax: number().required(),
  tmin: number().required(),
  s_max_steps: number().required(),
  max_steps: number().required(),
  run_mode: number().min(0).max(1).required(),
})

const ResultsSchema = object({
  _id: string(),
  createdAt: string().required(),
  updatedAt: string().required(),
  deleted: bool(),
  before_overall_risk: number().required(),
  after_overall_risk: number().required(),
  max_risk: number().required(),
  min_risk: number().required(),
  before_threat_risks: array(CIDSSThreatRisksSchema).required(),
  after_threat_risks: array(CIDSSThreatRisksSchema).required(),
  interventions: array(
    object({
      id: string(),
      name: string().required(),
      description: string().required(),
      unit: string().required(),
      number: number().required(),
      cost: number().required(),
      key: bool().required(),
      allow: bool().required(),
    })
  ),
})

const InterventionSchema = object({
  int_id: string().required(),
  int_group: string().required(),
  int_description: string().required(),
  int_name: string().required(),
  allow: string().required(),
  int_programs: array(string()).required(),
  int_unit: string().required(),
  int_cost: string().required(),
  int_field_name: string().required(),
})

const ScenarioSchema = object({
  _id: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  deleted: bool().required(),
  users: array(),
  catchments: array(string()).required(),
  interventions: array(InterventionSchema).required(),
  attenuation_props: CIDSSFileSchema.required(),
  efficacy: CIDSSFileSchema.required(),
  intervention_budget: CIDSSFileSchema.required(),
  progress: number().required(),
  progress_issues: object({
    createdAt: string(),
    updatedAt: string(),
    deleted: bool(),
    code: string(),
    message: string().required(),
  }).nullable(),
  spatial: CIDSSFileSchema.nullable(),
  attempts: array().required(),
  before_pu_attenuated_threats: CIDSSFileSchema.nullable(),
  after_pu_attenuated_threats: CIDSSFileSchema.nullable(),
  scenario_results: ResultsSchema.nullable(),
  status: string().required(),
  featured: bool().required(),
  cost_matrix: CIDSSFileSchema.nullable(),
  intervention_matrix: CIDSSFileSchema.nullable(),
  // cost: number().required().positive(),
  time_left: number().required(),
  files_valid: bool().required(),
  allowed_catchments: number().required().min(0).integer(),
  intervention_changes: array().required(),
  edited: bool().required(),
  visibility: string().required(),
  risk_weights: string().required(),
  risk_weights_raw: ScenarioRiskWeightsSchema,
  risk_weights_result: ScenarioRiskWeightsSchema,
  attenuated_threats: lazy((obj) =>
    object(
      mapRules(
        obj,
        object({
          before: string().required(),
          after: string().required(),
        })
      )
    ).nullable()
  ),
  annotations: string().notRequired(),
  annotation_reasion: string().notRequired(),
})
  .concat(ScenarioEditSchema)
  .concat(ScenarioSettingsSchema)

const CatchmentSchema = object({
  _id: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  deleted: bool(),
  catchment_name: string(),
  catchment_description: string(),
  catchment_budget: number(),
  spatial: CIDSSFileSchema.nullable().optional(),
  pu: CIDSSFileSchema.nullable().optional(),
  storm_water: CIDSSFileSchema.nullable().optional(),
  agriculture: CIDSSFileSchema.nullable().optional(),
  industry: CIDSSFileSchema.nullable().optional(),
  stp: CIDSSFileSchema.nullable().optional(),
  risk: CIDSSFileSchema.nullable().optional(),
  intervention_limits: CIDSSFileSchema.nullable().optional(),
  intervention_group_limits: CIDSSFileSchema.nullable().optional(),
  onsite: CIDSSFileSchema.nullable().optional(),
  raw_threat: CIDSSFileSchema.nullable().optional(),
  pu_properties: CIDSSFileSchema.nullable().optional(),
  // recreation is an optional file
  recreation: CIDSSFileSchema.nullable().optional(),
  pu_numbers: number(),
  pu_results: array(ResultsSchema).required(),
  allow: bool().required(),
  catchment_results: ResultsSchema.nullable(),
  files_valid: bool().required(),
  // parent_id: string()
})

// const DefaultsCatchmentSchema = object({
//   spatial: CIDSSFileSchema.nullable(),
//   pu: CIDSSFileSchema.nullable(),
//   storm_water: CIDSSFileSchema.nullable(),
//   agriculture: CIDSSFileSchema.nullable(),
//   industry: CIDSSFileSchema.nullable(),
//   stp: CIDSSFileSchema.nullable(),
//   risk: CIDSSFileSchema.nullable(),
//   intervention_limits: CIDSSFileSchema.nullable(),
//   intervention_group_limits: CIDSSFileSchema.nullable(),
//   onsite: CIDSSFileSchema.nullable(),
//   raw_threat: CIDSSFileSchema.nullable(),
//   pu_properties: CIDSSFileSchema.nullable(),
// }).concat(CatchmentSchema)

const DefaultsSchema = object({
  _id: string().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  deleted: bool().required(),
  interventions: array(InterventionSchema).required(),
  catchments: array(string()).required(),
  attenuation_props: CIDSSFileSchema.nullable(),
  efficacy: CIDSSFileSchema.nullable(),
  intervention_budget: CIDSSFileSchema.nullable(),
  show: bool().required(),
  files_valid: bool().required(),
})

const JobSchema = object({
  tasks: array(
    object({
      id: string().required(),
      script: string().required().equals(['scenario_run']),
      params: object({
        webHook: object({
          url: string().required(),
          method: string().required(),
        }),
        catchments: array(
          object({
            _id: string().required(),
            spatial: string().required(),
            pu: string().required(),
            storm_water: string().required(),
            agriculture: string().required(),
            industry: string().required(),
            risk: string().required(),
            intervention_limits: string().required(),
            intervention_group_limits: string().required(),
            onsite: string().required(),
            raw_threat: string().required(),
            pu_properties: string().required(),
            recreation: string().optional(),
          })
        ).min(1),
        attenuation_props: string().required(),
        intervention_budget: string().required(),
        efficacy: string().required(),
        pu_intervention_results: string().optional(),
        pu_intervention_costs: string().optional(),
        before_pu_attenuated_threats: string().optional(),
        after_pu_attenuated_threats: string().optional(),
        before_pu_threat_risks: string().optional(),
        after_pu_threat_risks: string().optional(),
        interventions: array(
          object({
            int_id: string().required(),
            int_group: string().required(),
            int_description: string().required(),
            int_name: string().required(),
            allow: bool().required(),
            int_programs: array(string()),
            int_unit: string().required(),
            int_cost: string().required(),
            int_field_name: string().required(),
          })
        ).min(1),
        intervention_matrix: string().optional(),
        spatial: string().optional(),
        data: array().optional(),
        intervention_changes: array().optional(),
        _id: string().required(),
        budget: number().min(0).required(),
        max_steps: number().required(),
        s_max_steps: number().required(),
        tmax: number().required(),
        tmin: number().required(),
        run_mode: number().min(0).max(1).required(),
        risk_weights: string().required(),
      }).required(),
      reply: bool().required(),
      fileParams: object(),
    })
  ).min(1),
  deps: array(
    object({
      source: string().required().equals(['root']),
      target: string().required(),
    })
  ),
})

export {
  ScenarioSchema,
  ScenarioEditSchema,
  ScenarioRiskWeightsSchema,
  ScenarioSettingsSchema,
  CatchmentSchema,
  UserPasswordChangeSchema,
  DefaultsSchema,
  // DefaultsCatchmentSchema,
  NewUserPayloadSchema,
  JobSchema,
}
