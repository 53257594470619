import { BlobServiceClient } from '@azure/storage-blob'
import { TransferProgressEvent } from '@azure/core-http'
import { env } from '../env'
import { CatchmentID, ScenarioID } from 'types/db/common'
import { Job } from 'types/api/computation/job'

const root = env.REACT_APP_API_HOST + env.REACT_APP_API_ENDPOINT_PREFIX
const pyRoot = env.REACT_APP_PYTHON_HOST

const endpoints = {
  scenarios: () => '/scenarios',
  scenarioOne: (id: ScenarioID) => `/scenarios/${id}`,
  scenarioOneReset: (id: ScenarioID) => `/scenarios/${id}/reset`,
  scenarioOneCopy: (id: ScenarioID) => `/scenarios/${id}/copy`,
  defaults: () => '/defaults',
  catchmentOne: (id: CatchmentID) => `/catchments/${id}`,
  auth: () => '/auth',
  users: () => '/users',
  userOne: (id: string) => `/users/${id}`,
  userOneReset: (id: string) => `/users/${id}/reset`,
  jobs: () => '/jobs',
  stopJobOne: (jobId: string, taskId: string) =>
    `/jobs/${jobId}/${taskId}/stop`,
  // this is only used for the computation service to callback the api
  // for progress events and finishing the run
  scenarioOneWebhook: (id: ScenarioID) => `/webhooks/scenario/${id}`,
}

const tileServerHost = env.REACT_APP_TILES_HOST
const tileServerPath = env.REACT_APP_TILES_ENDPOINT_PREFIX // '/data/{layer}/{z}/{x}/{y}.pbf'
const tileServerURI = tileServerHost + tileServerPath

const blobStorageHost = env.REACT_APP_BLOB_STORAGE_HOST
const blobStorageContainer = env.REACT_APP_BLOB_STORAGE_CONTAINER
const blobStorageConnectionUriWithAuth = env.REACT_APP_BLOB_CONNECTION_URI

async function get<T>(
  endpoint: string,
  headers?: Record<string, string>
): Promise<T> {
  try {
    const resp = await fetch(root + endpoint, {
      headers,
    })
    return (await resp.json()).data as T
  } catch (err) {
    console.error('fetch get error', err)
    throw err
  }
}

async function getRaw(endpoint: string, headers?: Record<string, string>) {
  return await fetch(root + endpoint, { headers })
}

async function patch(
  endpoint: string,
  body: any,
  headers?: Record<string, string>
): Promise<void> {
  try {
    const resp = await fetch(root + endpoint, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    })
    if (resp.status !== 200) {
      throw new Error('patch got status code ' + resp.status)
    }
  } catch (err) {
    console.error('fetch patch error', err)
    throw err
  }
}

async function post<T>(
  endpoint: string,
  body?: any,
  headers?: Record<string, string>
): Promise<T> {
  try {
    const resp = await fetch(root + endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    })
    if (resp.status !== 200) {
      throw new Error('post got status code ' + resp.status)
    }

    return (await resp.json()).data as T
  } catch (err) {
    console.error('fetch post error', err)
    throw err
  }
}

const api = {
  get,
  patch,
  post,
  delete: async function (
    endpoint: string,
    headers?: Record<string, string>
  ): Promise<void> {
    try {
      const resp = await fetch(root + endpoint, {
        method: 'DELETE',
        headers,
      })
      if (resp.status !== 200) {
        throw new Error('delete got status code ' + resp.status)
      }
    } catch (err) {
      console.error('fetch delete error', err)
      throw err
    }
  },
}

async function download<T>(fileName: string): Promise<T> {
  const host = `https://${blobStorageHost}/`
  const container = `${blobStorageContainer}/`

  try {
    const resp = await fetch(host + container + fileName)
    return (await resp.blob()) as T
  } catch (err) {
    console.error('fetch download error', err)
    throw err
  }
}

async function upload(
  fileName: string,
  blob: Blob,
  onProgress?: (arg1: TransferProgressEvent) => void
): Promise<void> {
  if (blobStorageConnectionUriWithAuth === undefined) {
    throw new Error('missing blob connection uri env variable')
  }

  // create the client
  const svcClient = BlobServiceClient.fromConnectionString(
    blobStorageConnectionUriWithAuth
  )
  const containerClient = svcClient.getContainerClient(
    blobStorageContainer ?? 'cidss'
  )
  const blockClient = containerClient.getBlockBlobClient(fileName)

  await blockClient.uploadData(blob, {
    onProgress,
  })
}

async function copy(
  sourceFileName: string,
  destFileName: string
): Promise<{ copyStatus?: string; _response: { status: number } }> {
  if (blobStorageConnectionUriWithAuth === undefined) {
    throw new Error('missing blob connection uri env variable')
  }

  // create the client

  const svcClient = BlobServiceClient.fromConnectionString(
    blobStorageConnectionUriWithAuth
  )
  const containerClient = svcClient.getContainerClient(
    blobStorageContainer ?? 'cidss'
  )

  // source blob client
  const sourceBlockClient = containerClient.getBlockBlobClient(sourceFileName)

  // destination blob client
  const destBlockClient = containerClient.getBlockBlobClient(destFileName)
  const resp = await destBlockClient.beginCopyFromURL(sourceBlockClient.url)
  return await resp.pollUntilDone()
}

async function newJob<T>(payload: Job): Promise<T> {
  try {
    const resp = await fetch(pyRoot + endpoints.jobs(), {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    if (resp.status !== 200) {
      throw new Error('post got status code ' + resp.status)
    }

    return (await resp.json()).data as T
  } catch (err) {
    console.error('fetch job post error', err)
    throw err
  }
}

async function stopJob(jobId: string, taskId: string): Promise<any> {
  try {
    const resp = await fetch(pyRoot + endpoints.stopJobOne(jobId, taskId), {
      method: 'POST',
    })
    if (resp.status !== 200) {
      throw new Error('post got status code ' + resp.status)
    }

    return (await resp.json()).data
  } catch (err) {
    console.error('fetch job post error', err)
    throw err
  }
}

export {
  endpoints,
  tileServerURI,
  get,
  getRaw,
  patch,
  post,
  download,
  upload,
  copy,
  newJob,
  stopJob,
  api,
}
