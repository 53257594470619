enum ScenarioRunMode {
  Threat = 0,
  Risk = 1,
}

const Hazards = ['TSS', 'Bacteria', 'Virus', 'Protozoa']

const AnnotationReasons = [
  'Hazard does not exist or is a different size',
  'Hazard has already been treated',
  'Interventions are not appropriate',
  'Intervention cost is different',
]

const DefaultRiskWeights = {
  tss: 25,
  bacteria: 25,
  virus: 25,
  protozoa: 25,
}

const DefaultTMax = 250000000
const DefaultTMin = 2.5
const DefaultSMaxSteps = 500
const DefaultMaxSteps = 1000
const DefaultRunMode = 1

export {
  Hazards,
  AnnotationReasons,
  ScenarioRunMode,
  DefaultRiskWeights,
  DefaultTMax,
  DefaultTMin,
  DefaultSMaxSteps,
  DefaultMaxSteps,
  DefaultRunMode,
}
