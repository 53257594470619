interface NewLineBreakStringToJSXProps {
  text: string
}

const NewLineBreakStringToJSX: React.FC<NewLineBreakStringToJSXProps> = ({
  text,
}) => {
  return (
    <>
      {text.split('\n').map((str, index) => (
        <p key={index}>{str}</p>
      ))}
    </>
  )
}

export { NewLineBreakStringToJSX }
