import { Instance, flow, getParent, types } from 'mobx-state-tree'
import { UserRoleModel } from './user-role'
import { UserFlatTableProps } from 'types/table'
import { api, endpoints } from 'utils/api'
import { Role } from 'types/permissions'
import { UsersResourceManagerInstance } from 'store/resource-managers/users'
import * as Sentry from '@sentry/react'

const UserModel = types
  .model('UserModel', {
    id: types.identifier,
    name: types.maybe(types.string),
    email: types.maybe(types.string),
    role: UserRoleModel,
    lastLogin: types.maybe(types.string),
    loginCount: types.optional(types.number, () => 0),
    ipAddress: types.maybe(types.string),
  })
  .views((self) => ({
    get serialisedFlatForTable(): UserFlatTableProps {
      return {
        id: self.id,
        email: self.email,
        name: self.name,
        lastLogin: self.lastLogin,
        role: self.role.name,
        loginCount: self.loginCount,
      }
    },
  }))
  .actions((self) => ({
    can: (permission: number) => {
      return (self.role.permissions & permission) === permission
    },
    reconcileSelf: (user: UserModelInstance) => {
      if (self.id !== user.id) {
        const err = new Error('user.reconcileSelf called with mismatching ids')
        Sentry.captureException(err)
        throw err
      }

      self.name = user.name
      self.email = user.email
      self.lastLogin = user.lastLogin
      self.loginCount = user.loginCount
      self.role.reconcileSelf(user.role)
    },
    reset: flow(function* () {
      const endpoint = endpoints.userOneReset(self.id)

      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        2
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        yield api.post(endpoint, { email: self.email }, headers)
      } catch (err) {
        console.error("error resetting user's password in auth0's api ", err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })

        throw err
      }
    }),
  }))
  .actions((self) => ({
    edit: flow(function* (role: Role) {
      const endpoint = endpoints.userOne(self.id)

      // get the parent
      const parent = getParent(self, 2) as unknown
      const UserManagerInstance = parent as UsersResourceManagerInstance

      // get the token for our authenticated user
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        yield api.patch(endpoint, { role }, headers)
        self.role.reconcileSelf(
          UserRoleModel.create({
            name: role,
            permissions: UserManagerInstance.getPermissionsFromRoleName(role),
          })
        )
      } catch (err) {
        console.error("error resetting user's password in auth0's api ", err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })

        throw err
      }
    }),
  }))

interface UserModelInstance extends Instance<typeof UserModel> {}

export { UserModel }
export type { UserModelInstance }
