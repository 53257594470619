import { Instance, types } from 'mobx-state-tree'

const UserRoleModel = types
  .model('UserRoleModel', {
    name: types.string,
    permissions: types.number,
  })
  .actions((self) => ({
    reconcileSelf: (role: UserRoleModelInstance) => {
      self.name = role.name
      self.permissions = role.permissions
    },
  }))

interface UserRoleModelInstance extends Instance<typeof UserRoleModel> {}

export { UserRoleModel }
export type { UserRoleModelInstance }
