import { useAuth0 } from '@auth0/auth0-react'
import { SpinLoader } from 'components/loader'
import { observer } from 'mobx-react-lite'
import React, { Suspense, useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { createRouterBasedOnUserPerms, routes } from 'scenes/routes'
import { useStore } from 'store/store'
import 'utils/py' // required to load the webworker and python script for refine
import './app.css'
import { LoadState } from 'types/loadstate'

const App: React.FC = observer(function App() {
  const {
    whoAmI,
    loadScenarios,
    loadDefaults,
    checkAuthorisation,
    getAndSaveIdToken,
    authenticatedUserIdTokenLoadStateVolatile,
    authorisationLoadStateVolatile,
    userLoadStateVolatile,
    isLocalAuthorised,
    me,
    refreshScenarios,
  } = useStore()
  const { loginWithPopup, isAuthenticated, user, getIdTokenClaims, isLoading } =
    useAuth0()

  useEffect(() => {
    checkAuthorisation()
  }, [checkAuthorisation])

  useEffect(() => {
    if (authorisationLoadStateVolatile !== LoadState.done || isLoading) {
      return
    }

    if (!isAuthenticated && !isLocalAuthorised) {
      // force login redirect
      loginWithPopup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorisationLoadStateVolatile, isAuthenticated, isLocalAuthorised])

  useEffect(() => {
    // check if we're authenticated first
    // we can't ask the api for resources without being authenticated
    // and authorised
    if (authorisationLoadStateVolatile !== LoadState.done || isLoading) {
      return
    }

    if (isLocalAuthorised && !isAuthenticated) {
      Promise.allSettled([loadScenarios(), loadDefaults()])
      return
    }

    // check if we're authorised
    if (!isAuthenticated) {
      return
    }

    if (authenticatedUserIdTokenLoadStateVolatile !== LoadState.done) {
      return
    }

    Promise.allSettled([loadScenarios(), loadDefaults()])
  }, [authorisationLoadStateVolatile, isLoading, isLocalAuthorised, isAuthenticated, authenticatedUserIdTokenLoadStateVolatile, loadScenarios, loadDefaults])

  useEffect(() => {
    if (!isAuthenticated || user === undefined || isLoading) {
      return
    }

    getAndSaveIdToken(getIdTokenClaims).then(() => whoAmI(user))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user])

  useEffect(() => {
    const timer = setInterval(refreshScenarios, 10_000)

    return () => {
      clearInterval(timer)
    }
  })

  // don't load the routes until the store knows who the user is
  if (
    isLoading ||
    (userLoadStateVolatile !== LoadState.done &&
      (!isLocalAuthorised || isAuthenticated))
  ) {
    return <SpinLoader />
  }

  const router =
    isAuthenticated && me ? createRouterBasedOnUserPerms(me) : [routes.home]

  return (
    <Suspense fallback={<SpinLoader />}>
      <RouterProvider router={createBrowserRouter(router)} />
    </Suspense>
  )
})

export { App }
