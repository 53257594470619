import { CIDSSCatchmentFileType, CIDSSScenarioFileType } from './files'

type CIDSSScenarioFileTypeWithRiskWeights =
  | CIDSSScenarioFileType
  | 'RiskWeights'

const CommonCsvHeaders = {
  FieldName: 'field name',
  PlanningUnitId: 'puid',
  SiteId: 'site_id',
  SiteId2: 'siteid',
  ProtozoaScore: 'pr_con_sc',
  EcoliScore: 'ec_con_sc',
  VirusScore: 'vi_con_sc',
  PathogenScore: 'path_like',
  LikeScore: 'like_score',
  LikeScore2: 'likelihood',
  TssSum: 'TSS_Sum',
  TssSumLog: 'TSS_Sum_log',
}

const ScenarioFileCsvHeaders: Record<
  CIDSSScenarioFileTypeWithRiskWeights,
  string[]
> = {
  InterventionBudget: [
    CommonCsvHeaders.FieldName,
    'name',
    'group',
    'programs',
    'description',
    'unit',
    'capital cost per total package $',
    'step',
  ],
  AttenuationProps: [
    'threat',
    'threat name',
    'type',
    'subtype',
    'connected half distance (m)',
    'disconnected half distance (m)',
    'loq base length (m)',
    'hiq base length (m)',
  ],
  Efficacy: [CommonCsvHeaders.FieldName],
  RiskWeights: ['RiskComponent', 'Weight'],
}

type CatchmentFiles = CIDSSCatchmentFileType | 'RiskWeights'

const CatchmentFileCsvHeaders: Record<CatchmentFiles, string[]> = {
  Spatial: [],
  Pu: [CommonCsvHeaders.PlanningUnitId],
  Risk: ['descriptor', 'consequence', 'TSS', 'Bacteria', 'Protozoa', 'Virus'],
  StormWater: [
    CommonCsvHeaders.SiteId,
    CommonCsvHeaders.PlanningUnitId,
    CommonCsvHeaders.ProtozoaScore,
    CommonCsvHeaders.EcoliScore,
    CommonCsvHeaders.LikeScore,
  ],
  Agriculture: [
    CommonCsvHeaders.SiteId,
    CommonCsvHeaders.PlanningUnitId,
    'area_ha',
    CommonCsvHeaders.ProtozoaScore,
    CommonCsvHeaders.EcoliScore,
    CommonCsvHeaders.LikeScore2,
  ],
  Industry: [
    'target_fid',
    CommonCsvHeaders.PlanningUnitId,
    CommonCsvHeaders.ProtozoaScore,
    CommonCsvHeaders.EcoliScore,
    CommonCsvHeaders.LikeScore2,
  ],
  Stp: [
    CommonCsvHeaders.SiteId,
    CommonCsvHeaders.PlanningUnitId,
    CommonCsvHeaders.ProtozoaScore,
    CommonCsvHeaders.EcoliScore,
    CommonCsvHeaders.PathogenScore,
  ],
  OnSite: [
    CommonCsvHeaders.SiteId2,
    CommonCsvHeaders.PlanningUnitId,
    CommonCsvHeaders.ProtozoaScore,
    CommonCsvHeaders.EcoliScore,
    CommonCsvHeaders.LikeScore,
    CommonCsvHeaders.VirusScore,
  ],
  Recreation: [
    CommonCsvHeaders.SiteId2,
    CommonCsvHeaders.PlanningUnitId,
    CommonCsvHeaders.ProtozoaScore,
    CommonCsvHeaders.EcoliScore,
    CommonCsvHeaders.LikeScore,
    CommonCsvHeaders.VirusScore,
  ],
  RawThreat: [CommonCsvHeaders.PlanningUnitId],
  InterventionLimits: [CommonCsvHeaders.PlanningUnitId],
  InterventionGroupLimits: [CommonCsvHeaders.PlanningUnitId],
  PuProperties: [
    CommonCsvHeaders.PlanningUnitId,
    'pu_area_ha',
    'chnl_lengt',
    'connect_d',
    'disconn_d',
    'trap_scale',
    'dist_hiq',
    'dist_loq',
    'dams',
  ],
  RiskWeights: ['RiskComponent', 'RiskWeight'],
}

const CIDSSFileCsvHeaders = {
  ...ScenarioFileCsvHeaders,
  ...CatchmentFileCsvHeaders,
}

export {
  CommonCsvHeaders,
  ScenarioFileCsvHeaders,
  CatchmentFileCsvHeaders,
  CIDSSFileCsvHeaders,
}
