import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const SpinLoader: React.FC = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
    }}
  >
    <CircularProgress
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-20px',
        marginTop: '-20px',
      }}
    />
  </Box>
)

export { SpinLoader }
