import { flow, Instance, types } from 'mobx-state-tree'
import { download, upload } from 'utils/api'
import { parse, ParseResult } from 'papaparse'
import { CIDSSFileType, FileExts } from 'values/files'
import { CIDSSFileCsvHeaders } from 'values/headers'
import { GeoJSON } from 'types/file'
import { CIDSSFileDocument } from 'types/db/file'
import { v4 } from 'uuid'
import { FileStatus } from 'utils/status'

const FileModel = types
  .model('FileModel', {
    truiiFileId: types.optional(types.identifier, () => v4()),
    fileName: types.string,
    fileType: types.string,
    type: types.string,
    isValid: types.boolean,
    isProcessing: types.boolean,
    issue: types.string,
    createdAt: types.optional(types.string, () => new Date().toISOString()),
    numPlanningUnits: types.maybe(types.number),
    contents: types.maybe(types.string),
  })
  .volatile(() => ({
    downloadStatus: FileStatus.INIT,
  }))
  .views((self) => ({
    get hasIssue() {
      return self.issue !== ''
    },
    get serialisedFlat(): CIDSSFileDocument {
      return {
        truii_file_id: self.truiiFileId,
        file_name: self.fileName,
        file_type: self.fileType,
        type: self.type,
        valid: self.isValid,
        processing: self.isProcessing,
        issue: self.issue,
        created_at: self.createdAt,
        updated_at: self.createdAt,
        progress: 100,
      }
    },
  }))
  .actions(() => ({
    readCSV: flow(function* <T>(contents: File | string) {
      return yield new Promise<ParseResult<T>>((resolve, reject) => {
        parse(contents, {
          header: true,
          complete: resolve,
          error: reject,
          dynamicTyping: true, // parse numbers and bools as actual types
          worker: true, // use worker thread, keep main thread open for ui rendering
        })
      })
    }),
  }))
  .actions((self) => ({
    validateGeoJSON: flow(function* (file: File) {
      // convert the file to text
      const text = yield file.text()
      let geoJSON = null
      try {
        // TODO: replace validation type with GeoJSON package type
        geoJSON = JSON.parse(text) as GeoJSON
      } catch (err) {
        // invalid json
        throw new Error('This file is not a valid GeoJSON file')
      }

      self.isValid = true
      return geoJSON
    }),
    validateCSV: flow(function* (file: File) {
      const results: ParseResult<any> = yield self.readCSV(file)

      const { meta } = results
      if (meta.aborted) {
        throw new Error('Failed to parse the file, is the file a CSV?')
      }

      // check if our fields for this file type (self.type)
      // are in our known headers array for the same file type
      const headers = CIDSSFileCsvHeaders[self.type as CIDSSFileType]
      const headerPresentResults = headers.map(
        (header) => [meta.fields?.includes(header), header] as [boolean, string]
      )
      const areAllHeadersPresentInFile =
        headerPresentResults.filter(([result]) => !result).length === 0

      if (areAllHeadersPresentInFile) {
        self.isValid = true
        return
      }

      // check which field is wrong and create an error to show the user
      const badHeaderResults = headerPresentResults
        .filter(([result]) => !result)
        .map(([, header]) => header)

      throw Error(
        `The following headers are missing from the file: ${badHeaderResults.join(
          ', '
        )}`
      )
    }),
  }))
  .actions((self) => ({
    validate: flow(function* (file: File) {
      const extension = `.${
        file.name.split('.')[file.name.split('.').length - 1]
      }`
      switch (extension) {
        case FileExts.GEOJSON:
          const geoJSON = (yield self.validateGeoJSON(file)) as GeoJSON
          if (self.type === 'Pu') {
            self.numPlanningUnits = geoJSON.features.length
          }
          break

        default:
          yield self.validateCSV(file)
          break
      }
    }),
  }))
  .actions((self) => {
    let promise = Promise.resolve<Blob | string | undefined>(undefined)

    return {
      download: flow(function* (): Generator<
        Promise<Blob | string | undefined>,
        string | undefined,
        any
      > {
        if ([FileStatus.INIT, FileStatus.ERROR].includes(self.downloadStatus)) {
          self.downloadStatus = FileStatus.DOWNLOADING
          try {
            promise = download<Blob>(self.truiiFileId)
            const blob: Blob = yield promise

            promise = blob.text()
            const contents: string = yield promise
            self.contents = contents
            self.downloadStatus = FileStatus.DOWNLOADED

            return contents
          } catch (err) {
            self.downloadStatus = FileStatus.ERROR

            throw err
          }
        }

        if (self.downloadStatus === FileStatus.DOWNLOADED) {
          return self.contents as string
        }

        return yield promise
      }),
    }
  })
  .actions((self) => ({
    upload: flow(function* (
      rawFile: File,
      onUploadProgressCb?: ({ loadedBytes }: { loadedBytes: number }) => void
    ) {
      self.isValid = false
      self.issue = ''

      try {
        // validate the file first
        self.isProcessing = true
        yield self.validate(rawFile) // this will change processing to false
        yield upload(self.truiiFileId, rawFile, onUploadProgressCb)
        self.isProcessing = false
      } catch (err) {
        const issue = (err as Error).message
        self.issue = issue
        self.isProcessing = false

        throw err
      }
    }),
    reconcileSelf(file: CIDSSFileDocument) {
      // self.truiiFileId = file.truii_file_id // can't reconcile an identifier
      self.fileName = file.file_name
      self.fileType = file.file_type
      self.type = file.type
      self.isValid = file.valid
      self.isProcessing = file.processing
      self.issue = file.issue
      self.createdAt = file.created_at
    },
  }))

interface FileModelInstance extends Instance<typeof FileModel> {}

export { FileModel }
export type { FileModelInstance }
