import { Instance, types } from 'mobx-state-tree'
import { CIDSSResultsDocument } from 'types/db/results'
import { InterventionModel } from './intervention'
import { ThreatRisksModel } from './threat-risks'
import { v4 } from 'uuid'

const ResultsModel = types
  .model('ResultsModel', {
    id: types.optional(types.identifier, () => v4()),
    createdAt: types.optional(types.string, () => new Date().toISOString()),
    beforeOverallRisk: types.number,
    afterOverallRisk: types.number,
    maxRisk: types.maybeNull(types.number),
    minRisk: types.maybeNull(types.number),
    beforeThreatRisks: types.array(ThreatRisksModel),
    afterThreatRisks: types.array(ThreatRisksModel),
    interventions: types.array(InterventionModel),
  })
  .views((self) => ({
    get serialisedFlat(): CIDSSResultsDocument {
      return {
        _id: self.id,
        createdAt: self.createdAt,
        updatedAt: self.createdAt,
        deleted: false,
        before_overall_risk: self.beforeOverallRisk,
        after_overall_risk: self.afterOverallRisk,
        max_risk: self.maxRisk ?? 0,
        min_risk: self.minRisk ?? 0,
        before_threat_risks: self.beforeThreatRisks.map(
          (risks) => risks.serialisedFlat
        ),
        after_threat_risks: self.afterThreatRisks.map(
          (risks) => risks.serialisedFlat
        ),
        interventions: self.interventions.map(
          (int) => int.serialisedFlatForResults
        ),
      }
    },
    get cost() {
      return self.interventions.reduce((total, { cost }) => total + cost, 0)
    },
  }))
  .actions((self) => ({
    reconcileSelf: (results: CIDSSResultsDocument) => {
      self.createdAt = results.createdAt
      self.beforeOverallRisk = results.before_overall_risk
      self.afterOverallRisk = results.after_overall_risk
    },
  }))

interface ResultsModelInstance extends Instance<typeof ResultsModel> {}

export { ResultsModel }
export type { ResultsModelInstance }
