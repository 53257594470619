import { flow, Instance, types } from 'mobx-state-tree'
import { unparse } from 'papaparse'
import { ScenarioRiskWeightsDocument } from 'types/db/scenario'
import { ScenarioRiskWeightsSchema } from 'utils/schema'

const ScenarioRiskWeightsModel = types
  .model('ScenarioRiskWeightsModel', {
    tss: types.number,
    bacteria: types.number,
    virus: types.number,
    protozoa: types.number,
  })
  .views((self) => ({
    get serialisedFlat(): ScenarioRiskWeightsDocument {
      return {
        tss: self.tss,
        bacteria: self.bacteria,
        virus: self.virus,
        protozoa: self.protozoa,
      }
    },
  }))
  .actions(() => ({
    validate(weights: ScenarioRiskWeightsDocument) {
      return ScenarioRiskWeightsSchema.validate(weights)
    },
  }))
  .actions((self) => ({
    compare(weights: ScenarioRiskWeightsDocument): boolean {
      return (
        self.tss === weights.tss &&
        self.bacteria === weights.bacteria &&
        self.virus === weights.virus &&
        self.protozoa === weights.protozoa
      )
    },
    setNewWeights: flow(function* (weights: ScenarioRiskWeightsDocument) {
      // check if the weights are valid before we set them
      yield self.validate(weights)

      self.tss = weights.tss
      self.bacteria = weights.bacteria
      self.virus = weights.virus
      self.protozoa = weights.protozoa
    }),
    createFileFromWeights(): string {
      // format the data to the format of the csv
      const data = [
        {
          RiskComponent: 'TSS',
          Weight: self.tss,
        },
        {
          RiskComponent: 'Bacteria',
          Weight: self.bacteria,
        },
        {
          RiskComponent: 'Virus',
          Weight: self.virus,
        },
        {
          RiskComponent: 'Protozoa',
          Weight: self.protozoa,
        },
      ]

      return unparse(data, { delimiter: ',', header: true })
    },
  }))

interface ScenarioRiskWeightsModelInstance
  extends Instance<typeof ScenarioRiskWeightsModel> {}

export { ScenarioRiskWeightsModel }
export type { ScenarioRiskWeightsModelInstance }
