import { Instance, types } from 'mobx-state-tree'
import { CIDSSThreatRisksDocument } from 'types/db/results'

const ThreatRiskValuesModel = types
  .model('ThreatRiskValuesModel', {
    type: types.string,
    value: types.number,
    threat: types.number,
    overallRisk: types.number,
  })
  .views((self) => ({
    get serialisedFlat() {
      return {
        type: self.type,
        value: self.value,
        threat: self.threat,
        overall_risk: self.overallRisk,
      }
    },
  }))

interface ThreatRiskValuesModelInstance
  extends Instance<typeof ThreatRiskValuesModel> {}

const ThreatRisksModel = types
  .model('ThreatRisksModel', {
    id: types.identifier,
    name: types.string,
    description: types.string,
    type: types.string,
    values: types.array(ThreatRiskValuesModel),
  })
  .views((self) => ({
    get serialisedFlat(): CIDSSThreatRisksDocument {
      return {
        _id: self.id,
        name: self.name,
        description: self.description,
        type: self.type,
        values: self.values.map((value) => value.serialisedFlat),
      }
    },
  }))
  .actions((self) => ({
    filterValuesForOneType(type: 'tss' | 'protozoa' | 'bacteria' | 'virus') {
      return self.values.filter(
        ({ type: _type }) => _type.toLowerCase() === type
      )
    },
    getOverallRiskForType(type: 'tss' | 'protozoa' | 'bacteria' | 'virus') {
      const matchingValue = self.values.find(
        ({ type: _type }) => _type.toLowerCase() === type
      )
      return matchingValue?.overallRisk ?? 0
    },
  }))

interface ThreatRisksModelInstance extends Instance<typeof ThreatRisksModel> {}

export { ThreatRisksModel, ThreatRiskValuesModel }
export type { ThreatRiskValuesModelInstance, ThreatRisksModelInstance }
