import { flow, getParent, Instance, types } from 'mobx-state-tree'
import { DefaultsFromApi } from 'types/db/defaults'
import { endpoints, patch, post } from 'utils/api'
import { CatchmentModel } from './catchment'
import { FileModel, FileModelInstance } from './file'
import { InterventionModel } from './intervention'
import { UsersResourceManagerInstance } from 'store/resource-managers/users'
import { CatchmentsResourceManagerInstance } from 'store/resource-managers/catchments'
import { CIDSSFileDocument } from 'types/db/file'
import { CatchmentFromApi } from 'types/db/catchment'
import * as Sentry from '@sentry/react'

const DefaultsModel = types
  .model('DefaultsModel', {
    id: types.identifier,
    createdAt: types.string,
    updatedAt: types.string,
    interventions: types.array(InterventionModel),
    catchments: types.array(types.reference(CatchmentModel)),
    attenuationProps: types.maybeNull(FileModel),
    efficacy: types.maybeNull(FileModel),
    interventionBudget: types.maybeNull(FileModel),
  })
  .views((self) => ({
    get isFilesValid(): boolean {
      // check all the files we have on the scenario
      const files = [
        self.attenuationProps,
        self.efficacy,
        self.interventionBudget,
      ]
      const areDefaultFilesInValid = files
        .map((file) => file?.isValid ?? false)
        .some((isValid) => isValid === false) // looking for invalid files

      // and the catchments
      // if the catchments aren't available, then we default to false
      // because we cannot infer the scenario is ready to run with this
      // property to ensure files are infact valid
      // we also want to exclude catchments that aren't enabled either
      const catchmentFilesValidity = self.catchments
        .filter((catchment) => catchment.enabled ?? false)
        .map((catchment) => catchment.isFilesValid)
        .some((isValid) => isValid === false) // looking for invalid files

      const areCatchmentFilesInvalid =
        self.catchments.length === 0 ? true : catchmentFilesValidity
      return !areDefaultFilesInValid || !areCatchmentFilesInvalid
    },
    get serialisedFlat(): DefaultsFromApi {
      return {
        _id: self.id,
        createdAt: self.createdAt,
        updatedAt: self.updatedAt,
        deleted: false,
        interventions: self.interventions.map(
          (int) => int.serialisedFlatForScenario
        ),
        catchments: self.catchments.map(
          (catchment) => catchment.serialisedFlat._id
        ),
        spatial: null,
        attenuation_props: self.attenuationProps?.serialisedFlat ?? null,
        issues: null,
        efficacy: self.efficacy?.serialisedFlat ?? null,
        intervention_budget: self.interventionBudget?.serialisedFlat ?? null,
        show: false,
        files_valid: true,
      }
    },
    get catchmentsFlatForTable() {
      return self.catchments.map(
        ({ serialisedFlatForTable }) => serialisedFlatForTable
      )
    },
  }))
  .actions((self) => ({
    draftNewDefaults(payload: DefaultsFromApi): DefaultsFromApi {
      return {
        ...self.serialisedFlat,
        updatedAt: new Date().toISOString(),
        catchments: payload.catchments ?? self.serialisedFlat.catchments,
        interventions: payload.interventions,
        attenuation_props: payload.attenuation_props,
        efficacy: payload.efficacy,
        intervention_budget: payload.intervention_budget,
      }
    },
    reconcileSelf: (defaults: DefaultsFromApi) => {
      // check if the defaults passed matches this model
      if (defaults._id !== self.id) {
        // no match, exit
        const err = new Error(
          'tried to reconcile defaults but ids did not match'
        )
        Sentry.captureException(err)

        console.error(err)
        return
      }

      // TODO: reconcile interventions

      // reconcile catchment(s)
      defaults.catchments.forEach((catchment) => {
        if (typeof catchment === 'string') {
          return
        }

        // find the matching catchment by id
        const sourceCatchment = self.catchments.find(
          ({ id }) => id === catchment._id
        )
        if (sourceCatchment === undefined) {
          // create the model for this new catchment
          const files: Record<string, FileModelInstance> = (
            [
              ['spatial', catchment.spatial],
              ['pu', catchment.pu],
              ['stormWater', catchment.storm_water],
              ['agriculture', catchment.agriculture],
              ['industry', catchment.industry],
              ['stp', catchment.stp],
              ['risk', catchment.risk],
              ['interventionLimits', catchment.intervention_limits],
              ['interventionGroupLimits', catchment.intervention_group_limits],
              ['onSite', catchment.onsite],
              // NOTE: the db has this field stored as "raw_theat"
              // i don't know why, it just is...
              ['rawThreat', catchment.raw_threat ?? catchment.raw_theat],
              ['puProperties', catchment.pu_properties],
              ['recreation', catchment.recreation],
            ] as [string, CIDSSFileDocument | null][]
          ).reduce(
            (obj, [key, fileDoc]) => ({
              ...obj,
              [key]:
                fileDoc === null || fileDoc === undefined
                  ? null
                  : FileModel.create({
                      truiiFileId: fileDoc.truii_file_id,
                      fileName: fileDoc.file_name,
                      fileType: fileDoc.file_type,
                      type: fileDoc.type,
                      isValid: fileDoc.valid,
                      isProcessing: fileDoc.processing,
                      issue: fileDoc.issue,
                      createdAt: fileDoc.created_at,
                    }),
            }),
            {}
          )

          const model = CatchmentModel.create({
            id: catchment._id,
            createdAt: catchment.createdAt,
            updatedAt: catchment.updatedAt,
            name: catchment.catchment_name,
            description: catchment.catchment_description,
            spatial: files.spatial,
            pu: files.pu,
            stormWater: files.stormWater,
            agriculture: files.agriculture,
            industry: files.industry,
            stp: files.stp,
            risk: files.risk,
            interventionLimits: files.interventionLimits,
            interventionGroupLimits: files.interventionGroupLimits,
            onSite: files.onSite,
            rawThreat: files.rawThreat,
            puProperties: files.puProperties,
            recreation: files.recreation,
            puNumbers: catchment.pu_numbers,
            puResults: [],
            enabled: catchment.allow,
            catchmentResults: null,
          })

          // get the catchments resource manager from self
          const CatchmentsResourceManager = getParent(
            self,
            1
          ) as unknown as CatchmentsResourceManagerInstance
          CatchmentsResourceManager.addCatchments([model])
          return
        }

        sourceCatchment.reconcileSelf(catchment)
      })

      // clear the catchments array and reset it with the list of ids
      self.catchments.clear()
      if (typeof defaults.catchments?.[0] === 'string') {
        self.catchments.push(...(defaults.catchments as string[]))
      } else {
        self.catchments.push(
          ...(defaults.catchments as CatchmentFromApi[]).map(({ _id }) => _id)
        )
      }

      self.updatedAt = defaults.updatedAt

      // reconcile the files
      // we can't just directly reconcile the object
      // because each file has a unique id
      // and new files have a different id
      // and we can't directly change identifiers...
      if (
        defaults.intervention_budget !== null &&
        defaults.intervention_budget.truii_file_id !==
          self.interventionBudget?.truiiFileId
      ) {
        self.interventionBudget = FileModel.create({
          truiiFileId: defaults.intervention_budget.truii_file_id,
          fileName: defaults.intervention_budget.file_name,
          fileType: defaults.intervention_budget.file_type,
          type: defaults.intervention_budget.type,
          isValid: defaults.intervention_budget.valid,
          isProcessing: defaults.intervention_budget.processing,
          issue: defaults.intervention_budget.issue,
          createdAt: defaults.intervention_budget.created_at,
        })
      }

      if (
        defaults.efficacy !== null &&
        defaults.efficacy.truii_file_id !== self.efficacy?.truiiFileId
      ) {
        self.efficacy = FileModel.create({
          truiiFileId: defaults.efficacy.truii_file_id,
          fileName: defaults.efficacy.file_name,
          fileType: defaults.efficacy.file_type,
          type: defaults.efficacy.type,
          isValid: defaults.efficacy.valid,
          isProcessing: defaults.efficacy.processing,
          issue: defaults.efficacy.issue,
          createdAt: defaults.efficacy.created_at,
        })
      }

      if (
        defaults.attenuation_props !== null &&
        defaults.attenuation_props.truii_file_id !==
          self.attenuationProps?.truiiFileId
      ) {
        self.efficacy = FileModel.create({
          truiiFileId: defaults.attenuation_props.truii_file_id,
          fileName: defaults.attenuation_props.file_name,
          fileType: defaults.attenuation_props.file_type,
          type: defaults.attenuation_props.type,
          isValid: defaults.attenuation_props.valid,
          isProcessing: defaults.attenuation_props.processing,
          issue: defaults.attenuation_props.issue,
          createdAt: defaults.attenuation_props.created_at,
        })
      }
    },
  }))
  .actions((self) => ({
    save: flow(function* (payload: DefaultsFromApi) {
      const requestBody = self.draftNewDefaults(payload)

      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        1
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        yield patch(endpoints.defaults(), requestBody, headers)

        // try reconciling self with our payload if nothing went wrong
        self.reconcileSelf(payload)
      } catch (err) {
        console.error('error saving defaults', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })
      }
    }),
    createCatchment: flow(function* () {
      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        1
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        const docs: DefaultsFromApi[] = yield post(
          endpoints.defaults(),
          null,
          headers
        )
        const defaults = docs?.[0]
        self.reconcileSelf(defaults)
      } catch (err) {
        console.error('error creating a new defaults catchment', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })

        throw err
      }
    }),
  }))

interface DefaultsModelInstance extends Instance<typeof DefaultsModel> {}

export { DefaultsModel }
export type { DefaultsModelInstance }
