import * as Sentry from '@sentry/react'
import { flow, Instance, types } from 'mobx-state-tree'
import { UserModel, UserModelInstance } from 'store/models/user'
import { UserRoleModel } from 'store/models/user-role'
import { UserApiResponse } from 'types/api/user'
import { UserDocument } from 'types/db/user'
import { LoadState } from 'types/loadstate'
import {
  PermissionAction,
  PermissionResource,
  Permissions,
  Role,
} from 'types/permissions'
import { UserFlatTableProps } from 'types/table'
import { api, endpoints, get, getRaw, post } from 'utils/api'

interface AuthorisedApiResponse {
  authorised: boolean
}

const UsersResourceManager = types
  .model('UsersResourceManager', {
    users: types.array(UserModel),
    isLocalAuthorised: types.boolean,
    meId: types.maybeNull(types.string),
    authenticatedUserIdToken: types.maybeNull(types.string),
  })
  .volatile(() => ({
    userLoadStateVolatile: LoadState.init,
    authorisationLoadStateVolatile: LoadState.init,
    fullUsersLoadStateVolatile: LoadState.init,
    authenticatedUserIdTokenLoadStateVolatile: LoadState.init,
  }))
  .views((self) => ({
    get auth0Domain() {
      return 'cidss2.au.auth0.com'
    },
    get auth0ClientId() {
      return 'lb6PuxjSjxUSnmGN0bAtKmcTZvf9puWD'
    },
    get me() {
      return self.users.find(({ id }) => id === self.meId)
    },
    get usersFlatForTable(): UserFlatTableProps[] {
      return self.users.map(
        ({ serialisedFlatForTable }) => serialisedFlatForTable
      )
    },
    get usersAsMap(): Record<string, UserModelInstance> {
      return self.users.reduce((obj, user) => ({ ...obj, [user.id]: user }), {})
    },
  }))
  .views((self) => ({
    get meAsSentryUserContext() {
      return {
        id: self.me?.id,
        ip_address: self.me?.ipAddress,
        email: self.me?.email,
        username: self.me?.name,
      }
    },
  }))
  .actions((self) => ({
    checkAuthorisation: flow(function* () {
      if (
        [LoadState.init, LoadState.error].includes(
          self.authorisationLoadStateVolatile
        )
      ) {
        self.authorisationLoadStateVolatile = LoadState.inProgress

        const resp: AuthorisedApiResponse = yield get<AuthorisedApiResponse>(
          endpoints.auth()
        )

        self.isLocalAuthorised = resp.authorised
        self.authorisationLoadStateVolatile = LoadState.done
      }
    }),
    addUsers: (users: UserModelInstance[]) => {
      // loop over each user, check if they already exist
      // if so, we want to reconcile them
      users.forEach((user) => {
        const exists = self.users.find(({ id }) => id === user.id)
        if (exists) {
          exists.reconcileSelf(user)
          return
        }

        self.users.push(user)
      })
    },
    getPermissionsFromRoleName: (role: Role): number => {
      switch (role) {
        case Role.Admin:
          return (
            Permissions[PermissionResource.Featured][PermissionAction.Read] |
            Permissions[PermissionResource.ScenarioManager][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.ScenarioManager][
              PermissionAction.Write
            ] |
            Permissions[PermissionResource.ScenarioEdit][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.ScenarioEdit][
              PermissionAction.Write
            ] |
            Permissions[PermissionResource.ScenarioRefine][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.ScenarioRefine][
              PermissionAction.Write
            ] |
            Permissions[PermissionResource.Defaults][PermissionAction.Read] |
            Permissions[PermissionResource.Defaults][PermissionAction.Write] |
            Permissions[PermissionResource.UserManagement][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.UserManagement][
              PermissionAction.Write
            ]
          )

        case Role.Operator:
          return (
            Permissions[PermissionResource.Featured][PermissionAction.Read] |
            Permissions[PermissionResource.ScenarioManager][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.ScenarioEdit][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.ScenarioRefine][
              PermissionAction.Read
            ] |
            Permissions[PermissionResource.Defaults][PermissionAction.Read] |
            Permissions[PermissionResource.UserManagement][
              PermissionAction.Read
            ]
          )

        default:
          return (
            Permissions[PermissionResource.Featured][PermissionAction.Read] |
            Permissions[PermissionResource.UserManagement][
              PermissionAction.Read
            ]
          )
      }
    },
  }))
  .actions((self) => ({
    whoAmI: flow(function* (user: {
      sub?: string
      name?: string
      email?: string
    }) {
      if (
        [LoadState.init, LoadState.error].includes(self.userLoadStateVolatile)
      ) {
        self.userLoadStateVolatile = LoadState.inProgress

        // get the token for our authenticated user
        const headers: { authorization?: string } = {}
        if (self.authenticatedUserIdToken) {
          headers.authorization = self.authenticatedUserIdToken
        }

        try {
          const endpoint = endpoints.userOne(user.sub ?? '')
          const resp: Response = yield getRaw(endpoint, headers)
          const ipAddress = resp.headers.get('X-Client-Ip') ?? undefined
          const { data } = yield resp.json()

          const users: UserDocument[] = data
          const _user = users?.[0]

          if (_user === undefined) {
            const err = new Error(
              `no user found in db with auth0 id of ${
                user.sub ?? '<missing id>'
              }`
            )
            Sentry.captureException(err, {
              user: self.meAsSentryUserContext,
            })

            throw err
          }

          self.addUsers([
            UserModel.create({
              id: _user.auth0Id,
              name: user.name ?? '<missing name>',
              email: user.email ?? '<missing email>',
              role: UserRoleModel.create({
                name: _user.role,
                permissions: self.getPermissionsFromRoleName(
                  _user.role as Role
                ),
              }),
              ipAddress,
            }),
          ])
          self.meId = _user.auth0Id

          self.userLoadStateVolatile = LoadState.done
        } catch (err) {
          console.error(
            'encountered error trying to find out who the user is',
            err
          )
          Sentry.captureException(err, {
            user: self.meAsSentryUserContext,
          })

          self.userLoadStateVolatile = LoadState.error
        }
      }
    }),
    loadUsers: flow(function* () {
      if (
        [LoadState.init, LoadState.error].includes(
          self.fullUsersLoadStateVolatile
        )
      ) {
        self.fullUsersLoadStateVolatile = LoadState.inProgress

        // get the token for our authenticated user
        const headers: { authorization?: string } = {}
        if (self.authenticatedUserIdToken) {
          headers.authorization = self.authenticatedUserIdToken
        }

        try {
          const users: UserApiResponse[] = yield get<UserApiResponse[]>(
            endpoints.users(),
            headers
          )

          if (users === undefined) {
            const err = new Error(
              'no users were found from the api/db, something is wrong'
            )
            Sentry.captureException(err, {
              user: self.meAsSentryUserContext,
            })

            throw err
          }

          const models = users.map((user) =>
            UserModel.create({
              id: user.auth0UserId,
              email: user.email,
              name: user.name,
              lastLogin: user.lastLogin,
              role: UserRoleModel.create({
                name: user.role,
                permissions: self.getPermissionsFromRoleName(user.role as Role),
              }),
              loginCount: user.loginCount,
            })
          )
          self.addUsers(models)

          self.fullUsersLoadStateVolatile = LoadState.done
        } catch (err) {
          console.error('encountered error loading users from the db', err)
          Sentry.captureException(err, {
            user: self.meAsSentryUserContext,
          })

          self.fullUsersLoadStateVolatile = LoadState.error
        }
      }
    }),
    getAndSaveIdToken: flow(function* (
      fetchToken: () => Promise<{ __raw: string } | undefined>
    ) {
      if (
        [LoadState.init, LoadState.error].includes(
          self.authenticatedUserIdTokenLoadStateVolatile
        )
      ) {
        self.authenticatedUserIdTokenLoadStateVolatile = LoadState.inProgress

        const token: { __raw: string } | undefined = yield fetchToken()
        if (!token) {
          self.authenticatedUserIdTokenLoadStateVolatile = LoadState.error

          const err = new Error(
            'failed retrieving auth0 id token of currently authenticated user'
          )
          Sentry.captureException(err, {
            user: self.meAsSentryUserContext,
          })
          console.error(err)
          return
        }

        const idToken = token.__raw
        self.authenticatedUserIdToken = idToken

        self.authenticatedUserIdTokenLoadStateVolatile = LoadState.done
      }
    }),
  }))
  .actions((self) => ({
    deleteUser: flow(function* (auth0UserId: string) {
      const endpoint = endpoints.userOne(auth0UserId)

      // get the token for our authenticated user
      const headers: { authorization?: string } = {}
      if (self.authenticatedUserIdToken) {
        headers.authorization = self.authenticatedUserIdToken
      }

      try {
        yield api.delete(endpoint, headers)

        // remove the user from the store if they exist...
        const user = self.usersAsMap[auth0UserId]
        if (user) {
          self.users.remove(user)
        }
      } catch (err) {
        console.error("error deleting user from auth0's api ", err)
        Sentry.captureException(err, {
          user: self.meAsSentryUserContext,
        })

        throw err
      }
    }),
    createUser: flow(function* (email: string, name: string, role: Role) {
      const endpoint = endpoints.users()

      // get the token for our authenticated user
      const headers: { authorization?: string } = {}
      if (self.authenticatedUserIdToken) {
        headers.authorization = self.authenticatedUserIdToken
      }

      try {
        const users: UserApiResponse[] = yield post<UserApiResponse[]>(
          endpoint,
          {
            email,
            name,
            role,
          },
          headers
        )

        const models = users.map((user) =>
          UserModel.create({
            id: user.auth0UserId,
            email,
            name,
            role: UserRoleModel.create({
              name: role,
              permissions: self.getPermissionsFromRoleName(role),
            }),
          })
        )
        self.addUsers(models)
      } catch (err) {
        console.error("error creating user in auth0's api ", err)
        Sentry.captureException(err, {
          user: self.meAsSentryUserContext,
        })

        throw err
      }
    }),
  }))

interface UsersResourceManagerInstance
  extends Instance<typeof UsersResourceManager> {}

export { UsersResourceManager }
export type { UsersResourceManagerInstance }
