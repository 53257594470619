import { ScenarioStatus } from 'utils/status'

const RiskGood = '#77BC1F'

const RiskExtreme = '#D2272E'

const RiskHigh = '#F16724'

const RiskHigher = '#E24999'

const AquaPrimary = '#08BFDD'

const SecondaryGrey = '#ACACAE'

const LightSecondaryGrey = '#E3E4E5'

const VeryLightSecondaryGrey = '#F7F7F8'

const DarkSecondaryGrey = '#77777A'

const RiskColours: `#${string}`[] = [
  '#8f929a',
  AquaPrimary,
  RiskGood,
  '#FFA400',
  RiskHigher,
  RiskExtreme,
]

const RiskColoursFaded: `#${string}`[] = [
  '#C7C8CC',
  '#83DFEE',
  '#BBDD8F',
  '#FFD17F',
  '#F0A4CC',
  '#E89396',
]

const colourStatus = (status: ScenarioStatus): `#${string}` => {
  switch (status) {
    case ScenarioStatus.REFINED:
    case ScenarioStatus.COMPLETED:
      return RiskGood

    case ScenarioStatus.READY:
    case ScenarioStatus.STOPPED:
    case ScenarioStatus.ERROR:
      return RiskHigh

    default:
      return RiskColours[1]
  }
}

export {
  RiskColours,
  RiskColoursFaded,
  AquaPrimary,
  SecondaryGrey,
  RiskGood,
  RiskExtreme,
  RiskHigh,
  RiskHigher,
  LightSecondaryGrey,
  DarkSecondaryGrey,
  VeryLightSecondaryGrey,
  colourStatus,
}
