declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_API_HOST: string
  REACT_APP_API_ENDPOINT_PREFIX: string
  REACT_APP_TILES_HOST: string
  REACT_APP_TILES_ENDPOINT_PREFIX: string
  REACT_APP_BLOB_STORAGE_HOST: string
  REACT_APP_BLOB_STORAGE_CONTAINER: string
  REACT_APP_BLOB_CONNECTION_URI: string
  REACT_APP_DEFAULT_RECREATION_FILE: string
  REACT_APP_PYTHON_HOST: string
  REACT_APP_API_HOST_OVERRIDE_FOR_DNS?: string
}

export const env: EnvType = { ...process.env, ...window.env }
