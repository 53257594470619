import { Instance, types } from 'mobx-state-tree'

const InterventionModel = types
  .model('InterventionModel', {
    id: types.identifier,
    name: types.string,
    description: types.string,
    group: types.maybe(types.string),
    allow: types.boolean,
    programs: types.maybe(types.array(types.string)),
    unit: types.string,
    cost: types.number,
    fieldName: types.maybe(types.string),
    number: types.maybe(types.number),
    key: types.maybe(types.boolean),
    limit: types.maybe(types.number),
  })
  .views((self) => ({
    get serialisedFlatForResults() {
      return {
        id: self.id,
        name: self.name,
        description: self.description,
        unit: self.unit,
        number: self.number ?? 0,
        cost: self.cost,
        key: self.key ?? false,
        allow: self.allow,
      }
    },
    get serialisedFlatForScenario() {
      return {
        int_id: self.id,
        int_group: self.group ?? '',
        int_description: self.description,
        int_name: self.name,
        allow: self.allow,
        int_programs: self.programs?.map((program) => program) ?? [],
        int_unit: self.unit,
        int_cost: self.cost.toString(),
        int_field_name: self.fieldName ?? '',
      }
    },
  }))
  .actions((self) => ({
    toggle(newState?: boolean) {
      if (newState === undefined) {
        self.allow = !self.allow
        return
      }

      self.allow = newState
    },
  }))
  .actions((self) => ({
    setLimit(limit: number) {
      self.limit = limit
    },
  }))

interface InterventionModelInstance
  extends Instance<typeof InterventionModel> {}

export { InterventionModel }
export type { InterventionModelInstance }
