import { Instance, types } from 'mobx-state-tree'
import { AttemptModel, AttemptModelInstance } from 'store/models/attempt'

const AttemptsResourceManager = types
  .model('AttemptsResourceManager', {
    attempts: types.array(AttemptModel),
    selectedAttemptId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get selectedAttempt() {
      if (self.selectedAttemptId === null) {
        return null
      }

      return self.attempts.find(({ id }) => id === self.selectedAttemptId)
    },
    get attemptsAsMap(): Record<string, AttemptModelInstance> {
      return self.attempts.reduce(
        (obj, attempt) => ({ ...obj, [attempt.id]: attempt }),
        {}
      )
    },
  }))
  .actions((self) => ({
    setSelectedAttemptById(attemptId: string) {
      // check if the id exists in our list
      if (!Object.keys(self.attemptsAsMap).includes(attemptId)) {
        throw new Error(
          'specified attempt id not found in list of attempts in store'
        )
      }

      self.selectedAttemptId = attemptId
    },
    clearSelectedAttemptId() {
      self.selectedAttemptId = null
    },
  }))
  .actions((self) => ({
    addAttempt: (attempt: AttemptModelInstance) => {
      self.attempts.push(attempt)
    },
  }))
  .actions((self) => ({
    upsertAttempts: (attempts: AttemptModelInstance[]) => {
      attempts.forEach((attempt) => self.addAttempt(attempt))
    },
  }))

interface AttemptsResourceManagerInstance
  extends Instance<typeof AttemptsResourceManager> {}

export default AttemptsResourceManager
export type { AttemptsResourceManagerInstance }
