import { CatchmentModelInstance } from 'store/models/catchment'
import { DefaultsModelInstance } from 'store/models/defaults'
import { FileModelInstance } from 'store/models/file'
import { ScenarioModelInstance } from 'store/models/scenario'
import { PascalToCamelCase } from 'types/common'

interface CIDSSFile<
  T extends
    | ScenarioModelInstance
    | CatchmentModelInstance
    | DefaultsModelInstance
> {
  label: string
  allowedExtensions: `.${string}`[]
  fileGetter: (model: T) => FileModelInstance | null
  tooltip?: string
}

type CIDSSFileGetterScenario = (
  model: ScenarioModelInstance
) => FileModelInstance | null

enum FileExts {
  CSV = '.csv',
  GEOJSON = '.geojson',
}

type CIDSSScenarioFileType =
  | 'InterventionBudget'
  | 'AttenuationProps'
  | 'Efficacy'

const ScenarioFiles: Record<
  CIDSSScenarioFileType,
  CIDSSFile<ScenarioModelInstance>
> = {
  InterventionBudget: {
    label: 'Intervention',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (scenario: ScenarioModelInstance) =>
      scenario.interventionBudget,
    tooltip:
      'Summary of intervention activities. Includes intervention codes, descriptions and intervention group membership. Includes Intervention units, cost per unit of intervention and marginal cost increases.',
  },
  AttenuationProps: {
    label: 'Attenuation scaling',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (scenario: ScenarioModelInstance) => scenario.attenuationProps,
    tooltip: 'Parameters to scale the spatial attenuation of threats',
  },
  Efficacy: {
    label: 'Efficacy',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (scenario: ScenarioModelInstance) => scenario.efficacy,
    tooltip: 'Summary of how effective each intervention is for each threat',
  },
}

const DefaultsFiles: Record<
  CIDSSScenarioFileType,
  CIDSSFile<DefaultsModelInstance>
> = {
  InterventionBudget: {
    label: 'Intervention',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (scenario: DefaultsModelInstance) =>
      scenario.interventionBudget,
    tooltip:
      'Summary of intervention activities. Includes intervention codes, descriptions and intervention group membership. Includes Intervention units, cost per unit of intervention and marginal cost increases.',
  },
  AttenuationProps: {
    label: 'Attenuation scaling',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (scenario: DefaultsModelInstance) => scenario.attenuationProps,
    tooltip: 'Parameters to scale the spatial attenuation of threats',
  },
  Efficacy: {
    label: 'Efficacy',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (scenario: DefaultsModelInstance) => scenario.efficacy,
    tooltip: 'Summary of how effective each intervention is for each threat',
  },
}

type CIDSSCatchmentFileType =
  | 'Agriculture'
  | 'Industry'
  | 'InterventionGroupLimits'
  | 'InterventionLimits'
  | 'OnSite'
  | 'Pu'
  | 'PuProperties'
  | 'RawThreat'
  | 'Recreation'
  | 'Risk'
  | 'Spatial'
  | 'StormWater'
  | 'Stp'

const CatchmentFiles: Record<
  CIDSSCatchmentFileType,
  CIDSSFile<CatchmentModelInstance>
> = {
  Spatial: {
    label: 'Spatial',
    allowedExtensions: [FileExts.GEOJSON],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.spatial,
    tooltip:
      'Geojson file with a single polygon showing the boundary of the contributing catchment',
  },
  Pu: {
    label: 'Planning unit spatial',
    allowedExtensions: [FileExts.GEOJSON],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.pu,
    tooltip:
      'Geojson file with polygons for each planning unit of the contributing catchment',
  },
  PuProperties: {
    label: 'Attenuation properties',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.puProperties,
    tooltip:
      'General proprieties of each planning unit including the connected distance from the planning unit to the Water Treatment Plant',
  },
  RawThreat: {
    label: 'TSS threat',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.rawThreat,
    tooltip:
      'The total suspended sediment load generated at each planning unit (long term average tonnes per year)',
  },
  StormWater: {
    label: 'Stormwater',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.stormWater,
    tooltip: 'Used to calculate the raw microbial threat from stormwater',
  },
  Stp: {
    label: 'STP',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.stp,
    tooltip:
      'Used to calculate the raw microbial threat from Sewage treatment plant effluent',
  },
  OnSite: {
    label: 'Onsite',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.onSite,
    tooltip:
      'Used to calculate the raw microbial threat from onsite waste water treatment systems',
  },
  Agriculture: {
    label: 'Diffuse Agriculture',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.agriculture,
    tooltip:
      'Used to calculate the raw microbial threat from diffuse agriculture',
  },
  Industry: {
    label: 'Intensive agriculture',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.industry,
    tooltip:
      'Used to calculate the raw microbial threat from intensive agriculture or industry',
  },
  Risk: {
    label: 'Risk thresholds',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.risk,
    tooltip:
      'Used to convert the threat at the water treatment plant to a risk value. Includes TSS threats and microbial threats',
  },
  InterventionLimits: {
    label: 'Intervention limits',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) =>
      catchment.interventionLimits,
    tooltip:
      'The upper limit to how much of each intervention can be applied for each planning unit',
  },
  InterventionGroupLimits: {
    label: 'Intervention group limits',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) =>
      catchment.interventionGroupLimits,
    tooltip:
      'The upper limit of intervention across a group of similar interventions',
  },
  Recreation: {
    label: 'Recreation',
    allowedExtensions: [FileExts.CSV],
    fileGetter: (catchment: CatchmentModelInstance) => catchment.recreation,
    tooltip:
      'Used to calculate the raw microbial threat from recreation activities',
  },
}

type CIDSSFileType = CIDSSScenarioFileType | CIDSSCatchmentFileType

type CIDSSScenarioFileTypeExtended =
  | CIDSSScenarioFileType
  | 'BeforePuAttenuatedThreats'
  | 'AfterPuAttenuatedThreats'
  | 'CostMatrix'
  | 'InterventionMatrix'
  | 'Spatial'

const DefaultEmptyRecreationFile = 'blank_recreation'

const CIDSSResultFileTypes: Record<
  PascalToCamelCase<CIDSSScenarioFileTypeExtended>,
  CIDSSScenarioFileTypeExtended
> = {
  spatial: 'Spatial',
  beforePuAttenuatedThreats: 'BeforePuAttenuatedThreats',
  afterPuAttenuatedThreats: 'AfterPuAttenuatedThreats',
  costMatrix: 'CostMatrix',
  interventionMatrix: 'InterventionMatrix',
  efficacy: 'Efficacy',
  attenuationProps: 'AttenuationProps',
  interventionBudget: 'InterventionBudget',
}

export {
  ScenarioFiles,
  DefaultsFiles,
  CatchmentFiles,
  FileExts,
  DefaultEmptyRecreationFile,
  CIDSSResultFileTypes,
}
export type {
  CIDSSFile,
  CIDSSFileGetterScenario,
  CIDSSCatchmentFileType,
  CIDSSFileType,
  CIDSSScenarioFileType,
  CIDSSScenarioFileTypeExtended,
}
