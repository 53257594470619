interface Response {
  id: number
  results: any
}

// @ts-ignore
const pyWorker = new Worker(new URL('../webworker.js', import.meta.url))

const callbacks: Record<number, (arg1: any) => void> = {}

pyWorker.onmessage = ($event: MessageEvent<Response>) => {
  const { id, ...data } = $event.data
  const onSuccess = callbacks[id]
  delete callbacks[id]

  onSuccess(data)
}

let id = 0 // identify a promise
async function asyncRun(script: string, context: any) {
  id = (id + 1) & Number.MAX_SAFE_INTEGER

  return new Promise<Response>((resolve) => {
    callbacks[id] = resolve
    pyWorker.postMessage({
      ...context,
      python: script,
      id,
    })
  })
}

export { asyncRun }
