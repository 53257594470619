import { lazy } from 'react'
import {
  DEFAULTS_ROUTE,
  FEATURED_ROUTE,
  REFINE_ROUTE,
  SCENARIO_EDIT_ROUTE,
  SCENARIO_MANAGER_ROUTE,
  USER_MANAGER_ROUTE,
} from 'routes'
import UserManager from './user/manager'
import { UserModelInstance } from 'store/models/user'
import {
  Permissions,
  PermissionAction,
  PermissionResource,
} from 'types/permissions'

const DefaultsManager = lazy(() => import('./defaults'))
const Featured = lazy(() => import('./home'))
const ScenarioEdit = lazy(() => import('./scenario/edit'))
const ScenarioManager = lazy(() => import('./scenario/manager'))
const ViewAndRefine = lazy(() => import('./scenario/refine'))

const routes = {
  home: {
    path: FEATURED_ROUTE,
    element: <Featured />,
    permission: Permissions[PermissionResource.Featured][PermissionAction.Read],
  },
  scenariosManager: {
    path: SCENARIO_MANAGER_ROUTE(),
    element: <ScenarioManager />,
    permission:
      Permissions[PermissionResource.ScenarioManager][PermissionAction.Read],
  },
  scenarioEdit: {
    path: SCENARIO_EDIT_ROUTE(':id'),
    element: <ScenarioEdit />,
    permission:
      Permissions[PermissionResource.ScenarioEdit][PermissionAction.Read],
  },
  defaultsManager: {
    path: DEFAULTS_ROUTE(),
    element: <DefaultsManager />,
    permission: Permissions[PermissionResource.Defaults][PermissionAction.Read],
  },
  scenarioRefine: {
    path: REFINE_ROUTE(':id'),
    element: <ViewAndRefine />,
    permission:
      Permissions[PermissionResource.ScenarioRefine][PermissionAction.Read],
  },
  userManager: {
    path: USER_MANAGER_ROUTE(),
    element: <UserManager />,
    permission:
      Permissions[PermissionResource.UserManagement][PermissionAction.Read],
  },
}

const createRouterBasedOnUserPerms = (user: UserModelInstance) => {
  return Object.values(routes).filter(({ permission }) => user.can(permission))
}

export { routes, createRouterBasedOnUserPerms }
