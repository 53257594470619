type Route = (...args: any[]) => string

const HOME_ROUTE: Route = () => '/'

const FEATURED_ROUTE = HOME_ROUTE()

const SCENARIO_MANAGER_ROUTE: Route = () => '/scenarios'

const SCENARIO_EDIT_ROUTE: Route = (scenarioId: string) =>
  `${SCENARIO_MANAGER_ROUTE()}/${scenarioId}/edit`

const DEFAULTS_ROUTE: Route = () => '/defaults'

const REFINE_ROUTE: Route = (scenarioId: string) =>
  `${SCENARIO_MANAGER_ROUTE()}/${scenarioId}/refine`

const USER_MANAGER_ROUTE: Route = () => `/account`

export {
  HOME_ROUTE,
  FEATURED_ROUTE,
  SCENARIO_MANAGER_ROUTE,
  SCENARIO_EDIT_ROUTE,
  DEFAULTS_ROUTE,
  REFINE_ROUTE,
  USER_MANAGER_ROUTE,
}
