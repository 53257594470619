enum ScenarioStatus {
  INIT = 'Init',
  READY = 'Ready',
  RUNNING = 'Running',
  FINALISING = 'Finalising',
  COMPLETED = 'Completed',
  REFINED = 'Refined',
  STOPPED = 'Stopped',
  STOPPING = 'Stopping',
  // QUEUED = 'Queued',
  ERROR = 'Error',
}

enum AttemptStatus {
  INIT = 'init',
  DOWNLOADING = 'downloading',
  RUNNING = 'running',
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error',
}

enum FileStatus {
  INIT = 'init',
  DOWNLOADING = 'downloading',
  DOWNLOADED = 'downloaded',
  ERROR = 'error',
}

export { ScenarioStatus, AttemptStatus, FileStatus }
