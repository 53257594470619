import { Instance, types } from 'mobx-state-tree'
import { SpatialFeatureGeometryModel } from './spatial-feature-geometry'

const SpatialFeatureModel = types
  .model('SpatialFeatureModel', {
    id: types.string,
    properties: types.map(types.frozen()),
    type: types.string,
    geometry: SpatialFeatureGeometryModel,
  })
  .views((self) => ({
    get asGeoJSON() {
      return {
        id: self.id,
        properties: self.properties.toJSON() as Record<
          string,
          string | number | boolean
        >,
        type: self.type,
        geometry: {
          // convert dimensional array to number[][][]
          coordinates: JSON.parse(self.geometry.coordinates),
          type: self.geometry.type,
        } as unknown as GeoJSON.Geometry,
      } as GeoJSON.Feature<GeoJSON.MultiPolygon>
    },
  }))

interface SpatialFeatureModelInstance
  extends Instance<typeof SpatialFeatureModel> {}

export { SpatialFeatureModel }
export type { SpatialFeatureModelInstance }
