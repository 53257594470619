enum PermissionResource {
  Featured,
  ScenarioManager,
  ScenarioEdit,
  ScenarioRefine,
  Defaults,
  UserManagement,
}

enum PermissionAction {
  Read = 'read',
  Write = 'write',
  // Admin,
}

enum Role {
  User = 'user',
  Operator = 'operator',
  Admin = 'admin',
}

const Roles = Object.values(Role)

const RoleDescriptions = {
  [Role.User]:
    'A user with this role can only access the Featured page (also known as the home page) and be able to change their password.\nThey do not have access to any Scenarios or other parts of the application.',
  [Role.Operator]:
    'A user with this role has read only access to every part of the application. Scenario manager, Defaults, Scenario refine, etc.\nThe user is unable to make any edits/changes to any part though.',
  [Role.Admin]:
    'A user with this role has unrestricted access to the entire application. Read and write access to all resources, and the ability to invite other users of any role to the application.',
}

const Permissions: Record<
  PermissionResource,
  Record<PermissionAction, number>
> = {
  [PermissionResource.Featured]: {
    [PermissionAction.Read]: 0x1,
    [PermissionAction.Write]: 0x0,
  },
  [PermissionResource.ScenarioManager]: {
    [PermissionAction.Read]: 0x2,
    [PermissionAction.Write]: 0x4,
  },
  [PermissionResource.ScenarioEdit]: {
    [PermissionAction.Read]: 0x8,
    [PermissionAction.Write]: 0x10,
  },
  [PermissionResource.ScenarioRefine]: {
    [PermissionAction.Read]: 0x20,
    [PermissionAction.Write]: 0x40,
  },
  [PermissionResource.Defaults]: {
    [PermissionAction.Read]: 0x80,
    [PermissionAction.Write]: 0x100,
  },
  [PermissionResource.UserManagement]: {
    [PermissionAction.Read]: 0x200,
    [PermissionAction.Write]: 0x400,
  },
}

export {
  PermissionResource,
  PermissionAction,
  Role,
  Permissions,
  Roles,
  RoleDescriptions,
}
