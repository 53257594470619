import { getSnapshot, Instance, types } from 'mobx-state-tree'
import { createContext, useContext } from 'react'
import AttemptsResourceManager from './resource-managers/attempts'
import { CatchmentsResourceManager } from './resource-managers/catchments'
import { DefaultsResourceManager } from './resource-managers/defaults'
import { ScenariosResourceManager } from './resource-managers/scenarios'
import { UsersResourceManager } from './resource-managers/users'

const Store = types.compose(
  ScenariosResourceManager,
  CatchmentsResourceManager,
  DefaultsResourceManager,
  UsersResourceManager,
  AttemptsResourceManager
)

interface StoreInstance extends Instance<typeof Store> {}

const store = Store.create({
  isLocalAuthorised: false,
})

const globalAsAny = global as any
globalAsAny.store = store
globalAsAny.getSnapshot = getSnapshot

const StoreContext = createContext<null | StoreInstance>(store)
const { Provider } = StoreContext

function useStore(): StoreInstance {
  const store = useContext(StoreContext)
  if (store === null) {
    throw new Error('Store cannot be null')
  }
  return store
}

export { store, Provider, useStore, Store }
export type { StoreInstance }
