import * as Sentry from '@sentry/react'
import { FeatureCollection, Polygon } from 'geojson'
import { Instance, flow, getParent, types } from 'mobx-state-tree'
import { ParseResult } from 'papaparse'
import { UsersResourceManagerInstance } from 'store/resource-managers/users'
import { InterventionLimitsCSVData } from 'types/csv'
import { CatchmentFromApi } from 'types/db/catchment'
import { LoadState } from 'types/loadstate'
import { endpoints, patch } from 'utils/api'
import { ConvertObjectPropertiesToCamelCase } from 'utils/format'
import { CreateResultsThreatRisks } from 'utils/funcs'
import { v4 } from 'uuid'
import { CIDSSFileType } from 'values/files'
import { FileModel, FileModelInstance } from './file'
import { InterventionModel } from './intervention'
import { ResultsModel, ResultsModelInstance } from './results'
import { SpatialModel } from './spatial'
import { SpatialFeatureModel } from './spatial-feature'
import { SpatialFeatureGeometryModel } from './spatial-feature-geometry'

const CatchmentModel = types
  .model('CatchmentModel', {
    id: types.identifier,
    createdAt: types.optional(types.string, () => new Date().toISOString()),
    updatedAt: types.optional(types.string, () => new Date().toISOString()),
    name: types.string,
    description: types.string,
    spatial: types.maybeNull(FileModel),
    pu: types.maybeNull(FileModel),
    stormWater: types.maybeNull(FileModel),
    agriculture: types.maybeNull(FileModel),
    industry: types.maybeNull(FileModel),
    stp: types.maybeNull(FileModel),
    risk: types.maybeNull(FileModel),
    interventionLimits: types.maybeNull(FileModel),
    interventionGroupLimits: types.maybeNull(FileModel),
    onSite: types.maybeNull(FileModel),
    rawThreat: types.maybeNull(FileModel),
    puProperties: types.maybeNull(FileModel),
    recreation: types.maybeNull(FileModel),
    puNumbers: types.maybeNull(types.number),
    puResults: types.array(ResultsModel),
    catchmentResults: types.maybeNull(ResultsModel),
    enabled: types.maybeNull(types.boolean),
    // spatial data
    puSpatial: types.maybe(SpatialModel),
    // attempt data
    attemptResults: types.maybeNull(ResultsModel),
    attemptPuResults: types.array(ResultsModel),
  })
  .volatile(() => ({
    spatialLoadStateVolatile: LoadState.init,
  }))
  .views((self) => ({
    get isFilesValid(): boolean {
      let files = [
        self.spatial,
        self.pu,
        self.stormWater,
        self.agriculture,
        self.industry,
        self.stp,
        self.risk,
        self.interventionLimits,
        self.interventionGroupLimits,
        self.onSite,
        self.rawThreat,
        self.puProperties,
      ]
      // add recreation if it's available, otherwise it is an optional file
      if (self.recreation !== null) {
        files = files.concat(self.recreation)
      }

      const areCatchmentFilesInValid = files
        .map((file) => file?.isValid ?? false)
        .some((isValid) => isValid === false) // looking for invalid files

      return !areCatchmentFilesInValid
    },
  }))
  .views((self) => ({
    get serialisedFlat(): CatchmentFromApi {
      return {
        _id: self.id,
        createdAt: self.createdAt,
        updatedAt: self.updatedAt,
        deleted: false,
        catchment_name: self.name,
        catchment_description: self.description,
        catchment_budget: 0,
        spatial: self.spatial?.serialisedFlat,
        pu: self.pu?.serialisedFlat,
        storm_water: self.stormWater?.serialisedFlat,
        agriculture: self.agriculture?.serialisedFlat,
        industry: self.industry?.serialisedFlat,
        stp: self.stp?.serialisedFlat,
        risk: self.risk?.serialisedFlat,
        intervention_limits: self.interventionLimits?.serialisedFlat,
        intervention_group_limits: self.interventionGroupLimits?.serialisedFlat,
        onsite: self.onSite?.serialisedFlat,
        raw_threat: self.rawThreat?.serialisedFlat,
        pu_properties: self.puProperties?.serialisedFlat,
        recreation: self.recreation?.serialisedFlat,
        pu_numbers: self.puNumbers ?? 0,
        pu_results: self.puResults.map((results) => results.serialisedFlat),
        allow: self.enabled ?? false,
        catchment_results: self.catchmentResults?.serialisedFlat ?? null,
        files_valid: self.isFilesValid,
        parent_id: '',
      }
    },
    get serialisedFlatForTable() {
      return {
        id: self.id,
        name: self.name,
        puNumbers: self.puNumbers,
        valid: self.isFilesValid,
        enabled: self.enabled,
      }
    },
  }))
  .actions((self) => ({
    addAttemptResults: (results: ResultsModelInstance) => {
      self.attemptResults = results
    },
    addAttemptPuResults: (results: ResultsModelInstance[]) => {
      self.attemptPuResults.clear()
      self.attemptPuResults.push(...results)
    },
  }))
  .actions((self) => ({
    toggleEnabled() {
      self.enabled = !self.enabled
    },
  }))
  .actions((self) => ({
    reconcileSelf(catchment: CatchmentFromApi) {
      // check if the catchment passed matches this model
      if (catchment._id !== self.id) {
        // no match, exit
        const err = new Error(
          'tried to reconcile catchment but ids did not match'
        )
        Sentry.captureException(err)

        console.error(err)
        return
      }

      // update catchment results if applicable
      if (
        catchment.catchment_results !== null &&
        catchment.catchment_results.createdAt !==
          self.catchmentResults?.createdAt
      ) {
        // go and reconcile the results object if we have one
        if (self.catchmentResults !== null) {
          self.catchmentResults.reconcileSelf(catchment.catchment_results)
        } else {
          // otherwise create a new one
          const results = ResultsModel.create({
            id: catchment.catchment_results._id,
            createdAt: catchment.catchment_results.createdAt,
            beforeOverallRisk: catchment.catchment_results.before_overall_risk,
            afterOverallRisk: catchment.catchment_results.after_overall_risk,
            maxRisk: catchment.catchment_results.max_risk,
            minRisk: catchment.catchment_results.min_risk,
            beforeThreatRisks: CreateResultsThreatRisks(
              catchment.catchment_results.before_threat_risks
            ),
            afterThreatRisks: CreateResultsThreatRisks(
              catchment.catchment_results.after_threat_risks
            ),
            interventions: catchment.catchment_results.interventions.map(
              (int) =>
                InterventionModel.create({
                  id: int.id,
                  name: int.name,
                  description: int.description,
                  allow: int.allow,
                  unit: int.unit,
                  cost: int.cost,
                  number: int.number,
                  key: int.key,
                })
            ),
          })
          self.catchmentResults = results
        }
      }

      // update pu results if applicable
      if (catchment.pu_results !== null && catchment.pu_results.length > 0) {
        // loop over all the pu's and reconcile each one
        catchment.pu_results.forEach((results) => {
          // find the matching pu result
          const puResult = self.puResults.find(({ id }) => id === results._id)
          if (puResult === undefined) {
            // results for this pu don't exist
            // we should create one here and add it
            self.puResults.push(
              ResultsModel.create({
                id: results._id,
                createdAt: results.createdAt,
                beforeOverallRisk: results.before_overall_risk,
                afterOverallRisk: results.after_overall_risk,
                maxRisk: results.max_risk,
                minRisk: results.min_risk,
                beforeThreatRisks: CreateResultsThreatRisks(
                  results.before_threat_risks
                ),
                afterThreatRisks: CreateResultsThreatRisks(
                  results.after_threat_risks
                ),
                interventions: results.interventions.map((int) =>
                  InterventionModel.create({
                    id: int.id,
                    name: int.name,
                    description: int.description,
                    allow: int.allow,
                    unit: int.unit,
                    cost: int.cost,
                    number: int.number,
                    key: int.key,
                  })
                ),
              })
            )
            return
          }

          puResult.reconcileSelf(results)
        })
      }

      self.updatedAt = catchment.updatedAt
      self.name = catchment.catchment_name
      self.description = catchment.catchment_description

      if (catchment.pu_numbers !== undefined) {
        self.puNumbers = catchment.pu_numbers
      }

      // reconcile the files like we do for scenarios
      // individually checking if the ids are the same
      // if not, then use the new file

      if (
        catchment.spatial &&
        catchment.spatial.truii_file_id !== self.spatial?.truiiFileId
      ) {
        self.spatial = FileModel.create({
          truiiFileId: catchment.spatial.truii_file_id,
          fileName: catchment.spatial.file_name,
          fileType: catchment.spatial.file_type,
          type: catchment.spatial.type,
          isValid: catchment.spatial.valid,
          isProcessing: catchment.spatial.processing,
          issue: catchment.spatial.issue,
          createdAt: catchment.spatial.created_at,
        })
      }

      if (catchment.pu && catchment.pu.truii_file_id !== self.pu?.truiiFileId) {
        self.pu = FileModel.create({
          truiiFileId: catchment.pu.truii_file_id,
          fileName: catchment.pu.file_name,
          fileType: catchment.pu.file_type,
          type: catchment.pu.type,
          isValid: catchment.pu.valid,
          isProcessing: catchment.pu.processing,
          issue: catchment.pu.issue,
          createdAt: catchment.pu.created_at,
        })
      }

      if (
        catchment.storm_water &&
        catchment.storm_water.truii_file_id !== self.stormWater?.truiiFileId
      ) {
        self.stormWater = FileModel.create({
          truiiFileId: catchment.storm_water.truii_file_id,
          fileName: catchment.storm_water.file_name,
          fileType: catchment.storm_water.file_type,
          type: catchment.storm_water.type,
          isValid: catchment.storm_water.valid,
          isProcessing: catchment.storm_water.processing,
          issue: catchment.storm_water.issue,
          createdAt: catchment.storm_water.created_at,
        })
      }

      if (
        catchment.agriculture &&
        catchment.agriculture.truii_file_id !== self.agriculture?.truiiFileId
      ) {
        self.agriculture = FileModel.create({
          truiiFileId: catchment.agriculture.truii_file_id,
          fileName: catchment.agriculture.file_name,
          fileType: catchment.agriculture.file_type,
          type: catchment.agriculture.type,
          isValid: catchment.agriculture.valid,
          isProcessing: catchment.agriculture.processing,
          issue: catchment.agriculture.issue,
          createdAt: catchment.agriculture.created_at,
        })
      }

      if (
        catchment.industry &&
        catchment.industry.truii_file_id !== self.industry?.truiiFileId
      ) {
        self.industry = FileModel.create({
          truiiFileId: catchment.industry.truii_file_id,
          fileName: catchment.industry.file_name,
          fileType: catchment.industry.file_type,
          type: catchment.industry.type,
          isValid: catchment.industry.valid,
          isProcessing: catchment.industry.processing,
          issue: catchment.industry.issue,
          createdAt: catchment.industry.created_at,
        })
      }

      if (
        catchment.stp &&
        catchment.stp.truii_file_id !== self.stp?.truiiFileId
      ) {
        self.stp = FileModel.create({
          truiiFileId: catchment.stp.truii_file_id,
          fileName: catchment.stp.file_name,
          fileType: catchment.stp.file_type,
          type: catchment.stp.type,
          isValid: catchment.stp.valid,
          isProcessing: catchment.stp.processing,
          issue: catchment.stp.issue,
          createdAt: catchment.stp.created_at,
        })
      }

      if (
        catchment.risk &&
        catchment.risk.truii_file_id !== self.risk?.truiiFileId
      ) {
        self.risk = FileModel.create({
          truiiFileId: catchment.risk.truii_file_id,
          fileName: catchment.risk.file_name,
          fileType: catchment.risk.file_type,
          type: catchment.risk.type,
          isValid: catchment.risk.valid,
          isProcessing: catchment.risk.processing,
          issue: catchment.risk.issue,
          createdAt: catchment.risk.created_at,
        })
      }

      if (
        catchment.intervention_limits &&
        catchment.intervention_limits.truii_file_id !==
          self.interventionLimits?.truiiFileId
      ) {
        self.interventionLimits = FileModel.create({
          truiiFileId: catchment.intervention_limits.truii_file_id,
          fileName: catchment.intervention_limits.file_name,
          fileType: catchment.intervention_limits.file_type,
          type: catchment.intervention_limits.type,
          isValid: catchment.intervention_limits.valid,
          isProcessing: catchment.intervention_limits.processing,
          issue: catchment.intervention_limits.issue,
          createdAt: catchment.intervention_limits.created_at,
        })
      }

      if (
        catchment.intervention_group_limits &&
        catchment.intervention_group_limits.truii_file_id !==
          self.interventionGroupLimits?.truiiFileId
      ) {
        self.interventionGroupLimits = FileModel.create({
          truiiFileId: catchment.intervention_group_limits.truii_file_id,
          fileName: catchment.intervention_group_limits.file_name,
          fileType: catchment.intervention_group_limits.file_type,
          type: catchment.intervention_group_limits.type,
          isValid: catchment.intervention_group_limits.valid,
          isProcessing: catchment.intervention_group_limits.processing,
          issue: catchment.intervention_group_limits.issue,
          createdAt: catchment.intervention_group_limits.created_at,
        })
      }

      if (
        catchment.onsite &&
        catchment.onsite.truii_file_id !== self.onSite?.truiiFileId
      ) {
        self.onSite = FileModel.create({
          truiiFileId: catchment.onsite.truii_file_id,
          fileName: catchment.onsite.file_name,
          fileType: catchment.onsite.file_type,
          type: catchment.onsite.type,
          isValid: catchment.onsite.valid,
          isProcessing: catchment.onsite.processing,
          issue: catchment.onsite.issue,
          createdAt: catchment.onsite.created_at,
        })
      }

      if (
        catchment.raw_theat &&
        catchment.raw_theat.truii_file_id !== self.rawThreat?.truiiFileId
      ) {
        self.rawThreat = FileModel.create({
          truiiFileId: catchment.raw_theat.truii_file_id,
          fileName: catchment.raw_theat.file_name,
          fileType: catchment.raw_theat.file_type,
          type: catchment.raw_theat.type,
          isValid: catchment.raw_theat.valid,
          isProcessing: catchment.raw_theat.processing,
          issue: catchment.raw_theat.issue,
          createdAt: catchment.raw_theat.created_at,
        })
      }

      if (
        catchment.raw_threat &&
        catchment.raw_threat.truii_file_id !== self.rawThreat?.truiiFileId
      ) {
        self.rawThreat = FileModel.create({
          truiiFileId: catchment.raw_threat.truii_file_id,
          fileName: catchment.raw_threat.file_name,
          fileType: catchment.raw_threat.file_type,
          type: catchment.raw_threat.type,
          isValid: catchment.raw_threat.valid,
          isProcessing: catchment.raw_threat.processing,
          issue: catchment.raw_threat.issue,
          createdAt: catchment.raw_threat.created_at,
        })
      }

      if (
        catchment.pu_properties &&
        catchment.pu_properties.truii_file_id !== self.puProperties?.truiiFileId
      ) {
        self.puProperties = FileModel.create({
          truiiFileId: catchment.pu_properties.truii_file_id,
          fileName: catchment.pu_properties.file_name,
          fileType: catchment.pu_properties.file_type,
          type: catchment.pu_properties.type,
          isValid: catchment.pu_properties.valid,
          isProcessing: catchment.pu_properties.processing,
          issue: catchment.pu_properties.issue,
          createdAt: catchment.pu_properties.created_at,
        })
      }

      if (
        catchment.recreation &&
        catchment.recreation.truii_file_id !== self.recreation?.truiiFileId
      ) {
        self.recreation = FileModel.create({
          truiiFileId: catchment.recreation.truii_file_id,
          fileName: catchment.recreation.file_name,
          fileType: catchment.recreation.file_type,
          type: catchment.recreation.type,
          isValid: catchment.recreation.valid,
          isProcessing: catchment.recreation.processing,
          issue: catchment.recreation.issue,
          createdAt: catchment.recreation.created_at,
        })
      }
    },
    draftNewCatchment(payload: CatchmentFromApi): CatchmentFromApi {
      return {
        ...self.serialisedFlat,
        updatedAt: new Date().toISOString(),
        catchment_name: payload.catchment_name,
        catchment_description: payload.catchment_description,
        spatial: payload.spatial ?? null,
        pu: payload.pu ?? null,
        storm_water: payload.storm_water ?? null,
        agriculture: payload.agriculture ?? null,
        industry: payload.industry ?? null,
        stp: payload.stp ?? null,
        risk: payload.risk ?? null,
        intervention_limits: payload.intervention_limits ?? null,
        intervention_group_limits: payload.intervention_group_limits ?? null,
        onsite: payload.onsite ?? null,
        raw_threat: payload.raw_threat ?? null,
        pu_properties: payload.pu_properties ?? null,
        recreation: payload.recreation ?? null,
        pu_numbers: payload.pu_numbers,
        pu_results: payload.pu_results,
        allow: payload.allow,
        catchment_results: payload.catchment_results,
      }
    },
  }))
  .actions((self) => ({
    compare(catchment: CatchmentFromApi): boolean {
      const selfAsPayload = self.serialisedFlat
      return JSON.stringify(selfAsPayload) === JSON.stringify(catchment)
    },
  }))
  .actions((self) => ({
    setFile(file: FileModelInstance) {
      switch (file.type as CIDSSFileType) {
        case 'Agriculture':
          self.agriculture = file
          break

        case 'Industry':
          self.industry = file
          break

        case 'InterventionGroupLimits':
          self.interventionGroupLimits = file
          break

        case 'InterventionLimits':
          self.interventionLimits = file
          break

        case 'OnSite':
          self.onSite = file
          break

        case 'Pu':
          self.pu = file
          if (typeof file.numPlanningUnits === 'number') {
            self.puNumbers = file.numPlanningUnits
          }
          break

        case 'PuProperties':
          self.puProperties = file
          break

        case 'RawThreat':
          self.rawThreat = file
          break

        case 'Recreation':
          self.recreation = file
          break

        case 'Risk':
          self.risk = file
          break

        case 'Spatial':
          self.spatial = file
          break

        case 'StormWater':
          self.stormWater = file
          break

        case 'Stp':
          self.stp = file
          break

        default:
          throw new Error('unrecognised file type')
      }
    },
  }))
  .actions((self) => {
    let loadPromise: Promise<string | undefined>

    return {
      loadPuSpatial: flow(function* () {
        if (self.spatialLoadStateVolatile === LoadState.inProgress) {
          yield loadPromise
        }

        if (
          [LoadState.init, LoadState.error].includes(
            self.spatialLoadStateVolatile
          )
        ) {
          self.spatialLoadStateVolatile = LoadState.inProgress

          try {
            // check if this catchment has results
            // if not then set the status to done and exit
            if (self.catchmentResults === null) {
              self.spatialLoadStateVolatile = LoadState.done
              return
            }

            if (self.pu === null) {
              const err = new Error(
                'catchment has results but no pu spatial file, ' + self.id
              )
              Sentry.captureException(err)

              throw err
            }

            loadPromise = self.pu.download()
            const text = yield loadPromise
            const geojson = JSON.parse(text) as FeatureCollection

            const features = geojson.features.map((feature) => {
              const id = (feature.id as string) ?? v4()

              // find the matching pu result for this feature
              // which should be defined by the id
              const puIdFieldName = 'puid'
              const puResult = self.puResults.find(
                ({ id }) => id === feature.properties?.[puIdFieldName]
              )

              return SpatialFeatureModel.create({
                id,
                properties: {
                  ...feature.properties,
                  id: self.id,
                  beforeOverallRisk: Math.floor(
                    puResult?.beforeOverallRisk ?? 0
                  ),
                  afterOverallRisk: Math.floor(puResult?.afterOverallRisk ?? 0),
                },
                type: feature.type,
                geometry: SpatialFeatureGeometryModel.create({
                  type: feature.geometry.type,
                  coordinates: JSON.stringify(
                    (feature.geometry as Polygon).coordinates
                  ),
                }),
              })
            })

            self.puSpatial = SpatialModel.create({
              id: self.id,
              type: geojson.type,
              features,
            })

            self.spatialLoadStateVolatile = LoadState.done
          } catch (err) {
            console.error('error loading pu spatial', err)
            Sentry.captureException(err)

            self.spatialLoadStateVolatile = LoadState.error
          }
        }
      }),
    }
  })
  .actions((self) => ({
    loadAndIngestInterventionLimits: flow(function* () {
      if (!self.interventionLimits) {
        return
      }

      const contents: string | undefined =
        yield self.interventionLimits.download()
      if (!contents) {
        return
      }

      const csv: ParseResult<InterventionLimitsCSVData> =
        yield self.interventionLimits.readCSV(contents)
      const parsed = ConvertObjectPropertiesToCamelCase<
        InterventionLimitsCSVData[]
      >(csv.data)

      // go through the interventions on the pu results
      // and find matching pu ids
      // on matches, we set our limits for each intervention defined
      self.puResults.forEach(({ id: puId, interventions }) => {
        // find our matching pu
        const puIntLimits = parsed.find(({ puid }) => puid === puId)
        if (puIntLimits === undefined) {
          // ignore this pu because it cannot be found in the csv
          return
        }

        interventions.forEach((int) => {
          // find the matching int in our pu limits
          const max: number | undefined | null = puIntLimits[`${int.id}_max`]
          if (max) {
            int.setLimit(max)
          }
        })
      })
    }),
  }))
  .actions((self) => ({
    save: flow(function* (payload: CatchmentFromApi) {
      const requestBody = self.draftNewCatchment(payload)

      // get the token for our authenticated user
      const UserManagerInstance = getParent(
        self,
        2
      ) as unknown as UsersResourceManagerInstance
      const headers: { authorization?: string } = {}
      if (UserManagerInstance.authenticatedUserIdToken) {
        headers.authorization = UserManagerInstance.authenticatedUserIdToken
      }

      try {
        const endpoint = endpoints.catchmentOne(self.id)
        yield patch(endpoint, requestBody, headers)

        // try reconciling self with our payload if nothing went wrong
        self.reconcileSelf(payload)
      } catch (err) {
        console.error('error saving catchment', err)
        Sentry.captureException(err, {
          user: UserManagerInstance.meAsSentryUserContext,
        })
      }
    }),
  }))

interface CatchmentModelInstance extends Instance<typeof CatchmentModel> {}

export { CatchmentModel }
export type { CatchmentModelInstance }
