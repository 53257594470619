import { Instance, types } from 'mobx-state-tree'
import { SpatialFeatureModel } from './spatial-feature'

const SpatialModel = types
  .model('SpatialModel', {
    id: types.identifier,
    type: types.string,
    features: types.array(SpatialFeatureModel),
  })
  .views((self) => ({
    get asGeoJSON() {
      return {
        type: self.type,
        features: self.features.map((feature) => feature.asGeoJSON),
      } as GeoJSON.FeatureCollection
    },
  }))

interface SpatialModelInstance extends Instance<typeof SpatialModel> {}

export { SpatialModel }
export type { SpatialModelInstance }
