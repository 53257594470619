import { Instance, types } from 'mobx-state-tree'
import { CatchmentModel, CatchmentModelInstance } from 'store/models/catchment'

const CatchmentsResourceManager = types
  .model('CatchmentResourceManager', {
    catchments: types.array(CatchmentModel),
  })
  .views((self) => ({
    get catchmentsAsMap(): Record<string, CatchmentModelInstance> {
      return self.catchments.reduce(
        (obj, catchment) => ({ ...obj, [catchment.id]: catchment }),
        {} as Record<string, CatchmentModelInstance>
      )
    },
  }))
  .actions((self) => ({
    addCatchments(catchments: CatchmentModelInstance[]) {
      self.catchments.push(...catchments)
    },
  }))

interface CatchmentsResourceManagerInstance
  extends Instance<typeof CatchmentsResourceManager> {}

export { CatchmentsResourceManager }
export type { CatchmentsResourceManagerInstance }
