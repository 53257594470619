import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { differenceInDays } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useStore } from 'store/store'
import styled from 'styled-components'
import {
  PermissionAction,
  PermissionResource,
  Permissions,
  Roles,
} from 'types/permissions'
import { UserFlatTableProps } from 'types/table'
import { upperCaseFirstLetter } from 'utils/format'
import { AquaPrimary, DarkSecondaryGrey, RiskHigh } from 'values/colours'
import BootstrapTooltip from 'components/tooltip/bootstrap'

interface UsersTableProps {
  data: UserFlatTableProps[]
  resetUserPassword: (arg1: string) => Promise<void>
  deleteUser: (arg1: string) => Promise<void>
  editUser: (arg1: string, role: string) => Promise<void>
  shouldDisableInputs?: boolean
}

const TableSortingIcon = styled(FontAwesomeIcon)`
  marginleft: 0.5rem;
`

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`

const TableRow = styled.tr`
  border-bottom: 1px solid ${AquaPrimary};
`

const TableCell = styled.td`
  padding: 1rem 0;

  &:first-child {
    padding-left: 1rem;
  }
`
const HighRiskButton = styled(Button)`
  background-color: ${RiskHigh} !important;
`

const DarkDialogContentText = styled(DialogContentText)`
  color: ${DarkSecondaryGrey} !important;
`

const columnHelper = createColumnHelper<UserFlatTableProps>()

const UsersTable: React.FC<UsersTableProps> = observer(function UsersTable({
  data: _data,
  resetUserPassword,
  deleteUser,
  editUser,
  shouldDisableInputs,
}) {
  const { me, usersAsMap } = useStore()

  const [confirmUserDeletion, setConfirmUserDeletion] = useState<string | null>(
    null
  )
  const shouldShowUserDeleteConfirmation = confirmUserDeletion !== null

  const [confirmUserReset, setConfirmUserReset] = useState<string | null>(null)
  const shouldShowUserResetConfirmation = confirmUserReset !== null

  // do our action, then close the menu behind it
  const doAction =
    (action: (...args: any[]) => void, ...args: any[]) =>
    () => {
      action(...args)
    }

  const promptUserToDeleteOtherUser = (userId: string) => {
    setConfirmUserDeletion(userId)
  }

  const promptUserToResetOtherUser = (userId: string) => {
    setConfirmUserReset(userId)
  }

  const actuallyDeleteUser = async () => {
    if (confirmUserDeletion !== null) {
      await deleteUser(confirmUserDeletion)
      setConfirmUserDeletion(null)
    }
  }

  const actuallyResetUser = async () => {
    if (confirmUserReset !== null) {
      await resetUserPassword(confirmUserReset)
      setConfirmUserReset(null)
    }
  }

  const toggleDeleteDialogByArg = (state: null | string) => () => {
    setConfirmUserDeletion(state)
  }

  const toggleResetDialogByArg = (state: null | string) => () => {
    setConfirmUserReset(state)
  }

  const roleSelectOptions = Roles.map((role) => (
    <MenuItem key={role} value={role}>
      {upperCaseFirstLetter(role)}
    </MenuItem>
  ))

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        header: () => 'Email',
        cell: (props) => <Typography>{props.getValue()}</Typography>,
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (props) => (
          <Box textAlign="center">
            <Typography>{props.getValue()}</Typography>
          </Box>
        ),
      }),
      columnHelper.accessor('lastLogin', {
        header: () => 'Last login',
        cell: (props) => {
          const timestamp = props.getValue()
          if (!timestamp) {
            return (
              <Box textAlign="center">
                <Typography>Never</Typography>
              </Box>
            )
          }

          const loggedInDaysAgo = differenceInDays(
            new Date(),
            new Date(timestamp)
          )
          return (
            <Box textAlign="center">
              <Typography>{loggedInDaysAgo} days ago</Typography>
            </Box>
          )
        },
        sortingFn: 'datetime',
      }),
      columnHelper.accessor('role', {
        header: () => 'Role',
        size: 100,
        cell: (props) => (
          <Box textAlign="center">
            <FormControl fullWidth>
              <Select
                id={`select.role.${props.row.original.id}`}
                value={props.getValue()}
                size="small"
                onChange={($event) =>
                  editUser(props.row.original.id, $event.target.value)
                }
                disabled={
                  shouldDisableInputs ||
                  !me?.can(
                    Permissions[PermissionResource.UserManagement][
                      PermissionAction.Write
                    ]
                  )
                }
              >
                {roleSelectOptions}
              </Select>
            </FormControl>
          </Box>
        ),
      }),
      columnHelper.display({
        id: 'opts',
        cell: (props) => (
          <Grid
            container
            spacing={4}
            justifyContent="end"
            alignItems="stretch"
            paddingRight="2rem"
          >
            <Grid item xs="auto">
              <BootstrapTooltip title="Reset the user's password">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={doAction(
                    promptUserToResetOtherUser,
                    props.row.original.id
                  )}
                  sx={{ color: 'primary.main', height: '100%' }}
                  disabled={
                    shouldDisableInputs ||
                    !me?.can(
                      Permissions[PermissionResource.UserManagement][
                        PermissionAction.Write
                      ]
                    )
                  }
                >
                  <Typography>
                    <FontAwesomeIcon icon={['fad', 'key']} />
                  </Typography>
                </Button>
              </BootstrapTooltip>
            </Grid>
            <Grid item xs="auto">
              <BootstrapTooltip title="Delete the user">
                <HighRiskButton
                  variant="contained"
                  onClick={doAction(
                    promptUserToDeleteOtherUser,
                    props.row.original.id
                  )}
                  sx={{ color: 'text.secondary', height: '100%' }}
                  disabled={
                    shouldDisableInputs ||
                    !me?.can(
                      Permissions[PermissionResource.UserManagement][
                        PermissionAction.Write
                      ]
                    )
                  }
                >
                  <Typography>
                    <FontAwesomeIcon icon="xmark" />
                  </Typography>
                </HighRiskButton>
              </BootstrapTooltip>
            </Grid>
          </Grid>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetUserPassword, editUser]
  )

  const data = useMemo(() => _data, [_data])

  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    initialState: { sorting: [{ id: 'lastLogin', desc: true }] },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const { rows: tableRows } = getRowModel()

  const headerGroups = getHeaderGroups().map((headerGroup) => {
    const headers = headerGroup.headers.map((header) => (
      <th
        key={header.id}
        colSpan={header.colSpan}
        style={{ width: header.getSize() }}
      >
        {header.isPlaceholder ? null : (
          <div
            className={
              header.column.getCanSort() ? 'cursor-pointer select-none' : ''
            }
            onClick={header.column.getToggleSortingHandler()}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            &nbsp;
            {{
              asc: <TableSortingIcon icon="caret-up" />,
              desc: <TableSortingIcon icon="caret-down" />,
            }[header.column.getIsSorted() as string] ?? null}
          </div>
        )}
      </th>
    ))

    return <TableRow key={headerGroup.id}>{headers}</TableRow>
  })

  const rows = tableRows.map((row) => {
    const cells = row.getVisibleCells().map((cell) => (
      <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    ))

    return <TableRow key={row.id}>{cells}</TableRow>
  })

  return (
    <>
      <Dialog
        open={shouldShowUserDeleteConfirmation}
        onClose={toggleDeleteDialogByArg(null)}
      >
        <DialogTitle>
          Delete <i>{usersAsMap[confirmUserDeletion as string]?.name}</i>
          &nbsp;?
        </DialogTitle>
        <DialogContent>
          <DarkDialogContentText>
            Deleting this user will remove their access from the app.
            <br />
            <br />
            There is no undoing this process.
          </DarkDialogContentText>
        </DialogContent>
        <DialogActions>
          <HighRiskButton
            variant="contained"
            onClick={doAction(actuallyDeleteUser, [
              toggleDeleteDialogByArg(null),
            ])}
            disabled={shouldDisableInputs}
          >
            Continue
          </HighRiskButton>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleDeleteDialogByArg(null)}
            disabled={shouldDisableInputs}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={shouldShowUserResetConfirmation}
        onClose={toggleResetDialogByArg(null)}
      >
        <DialogTitle>
          Reset <i>{usersAsMap[confirmUserReset as string]?.name}</i>
          &nbsp;'s password?
        </DialogTitle>
        <DialogContent>
          <DarkDialogContentText>
            Resetting this users password will send them an email with a link to
            change their password
          </DarkDialogContentText>
        </DialogContent>
        <DialogActions>
          <HighRiskButton
            variant="contained"
            onClick={doAction(actuallyResetUser, [
              toggleResetDialogByArg(null),
            ])}
            disabled={shouldDisableInputs}
          >
            Continue
          </HighRiskButton>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleResetDialogByArg(null)}
            disabled={shouldDisableInputs}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Table>
        <thead>{headerGroups}</thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  )
})

export default UsersTable
export type { UsersTableProps }
