import numbro from 'numbro'

// const FormatCurrency = Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   maximumFractionDigits: 0,
// })

const parseNum = (number: any): number => {
  return isNaN(number)
    ? 0
    : (number as unknown as string) === ''
    ? 0
    : number ?? 0
}

const FormatCurrency = (number: number) => {
  const num = parseNum(number)
  return numbro(num).formatCurrency({
    average: true,
    // mantissa: 1,
  })
}

const FormatNumber = (number: number) => {
  const num = parseNum(number)
  return numbro(num).formatCurrency({
    average: false,
    thousandSeparated: true,
  })
}

const FormatBudget = (number: number) => {
  const num = parseNum(number)
  return numbro(num).formatCurrency({
    mantissa: 2,
    thousandSeparated: true,
  })
}

const UnformatNumber = (str: string) => numbro.unformat(str)

const ShortDateFormat = 'dd/MM/y'

const LongDateFormat = 'dd/MM/y hh:mm:ss O'

const lowerCaseFirstLetter = (str: string) =>
  str.charAt(0).toLowerCase() + str.slice(1)

const upperCaseFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

const ConvertObjectPropertiesToCamelCase = <T>(arg1: T): T => {
  const convert = (obj: any) => {
    return Object.entries(obj).reduce((_obj, [key, value]) => {
      // replace spaces with capitalised first letter of the next word
      const matches = Array.from(key.matchAll(/ ([\S]+)/g))
      const newKey =
        key.split(' ')[0] +
        matches.map((match) => upperCaseFirstLetter(match[1])).join('')

      return { ..._obj, [newKey]: value }
    }, {})
  }

  if (Array.isArray(arg1)) {
    return arg1.map((obj) => convert(obj)) as T
  }

  return convert(arg1) as T
}

export {
  FormatCurrency,
  FormatNumber,
  FormatBudget,
  UnformatNumber,
  ShortDateFormat,
  LongDateFormat,
  lowerCaseFirstLetter,
  ConvertObjectPropertiesToCamelCase,
  upperCaseFirstLetter,
}
