import { createTheme } from '@mui/material'
import { AquaPrimary, RiskGood, RiskHigh, SecondaryGrey } from 'values/colours'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1366,
      lg: 1727,
      xl: 2560,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  palette: {
    mode: 'light',
    text: {
      primary: '#000',
      secondary: '#fff',
    },
    primary: {
      main: AquaPrimary,
    },
    secondary: {
      main: SecondaryGrey,
    },
    success: {
      main: RiskGood,
    },
    error: {
      main: RiskHigh,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        textTransform: 'none',
        variantMapping: {
          body2: 'span',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        sx: { color: 'text.secondary', textTransform: 'none' },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: { color: 'secondary.main' },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: { color: 'secondary.main' },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     ...({
    //       root: {
    //         '& .MuiOutlinedInput-root': {
    //           '& fieldset.MuiOutlinedInput-notchedOutline': {
    //             border: `2px solid ${AquaPrimary}`,
    //           },
    //           '&:hover fieldset': {
    //             border: `2px solid ${AquaPrimary}`,
    //           },
    //           '&.Mui-focused fieldset': {
    //             border: `2px solid ${AquaPrimary}`,
    //           },
    //         },
    //       },
    //     } as any),
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            '&': {
              color: AquaPrimary,
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: `2px solid ${AquaPrimary}`,
            },
            '&:hover fieldset': {
              border: `2px solid ${AquaPrimary}`,
            },
            '&.Mui-focused fieldset': {
              border: `2px solid ${AquaPrimary}`,
            },
          },
        },
      },
    },
  },
})

export { theme }
