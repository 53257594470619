/// <reference lib="dom" />
/// <reference lib="dom.iterable" />

import { Auth0Provider } from '@auth0/auth0-react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider, store } from 'store/store'
import { App } from './components/app/'
import './icons' // required to load the icons into the library for use in the app
import './index.css'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'
import * as Sentry from '@sentry/react'
import { env } from 'env'

Sentry.init({
  dsn: 'https://fb94aa5ae47e853d540895fef4c87034@o289744.ingest.sentry.io/4505950551670784',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      tracePropagationTargets: [
        'localhost',
        new RegExp(
          `^${env.REACT_APP_API_HOST + env.REACT_APP_API_ENDPOINT_PREFIX}`
        ),
        new RegExp(`^${env.REACT_APP_PYTHON_HOST}`),
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider value={store}>
      <Auth0Provider
        domain={store.auth0Domain}
        clientId={store.auth0ClientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/user/auth`,
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
