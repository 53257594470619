import { Instance, types } from 'mobx-state-tree'

const ScenarioAttenuatedThreatsModel = types
  .model('ScenarioAttenuatedThreatsModel', {
    id: types.identifier,
    after: types.string,
    before: types.string,
  })
  .views((self) => ({
    get serialisedFlat() {
      return {
        [self.id]: {
          after: self.after,
          before: self.before,
        },
      }
    },
  }))

interface ScenarioAttenuatedThreatsModelInstance
  extends Instance<typeof ScenarioAttenuatedThreatsModel> {}

export { ScenarioAttenuatedThreatsModel }
export type { ScenarioAttenuatedThreatsModelInstance }
